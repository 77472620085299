import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IAllResponseData } from "../../app/entities";
import { IError } from "../../app/entities/IAppError";

import { LoadStatus } from "../../app/entities/IAppLoadStatus";
import { IPartnerItem, IPartner, EPartnerStatus } from "../../app/entities/IAppPartner";
import { IAppTable } from "../../app/entities/IAppTable";
import { RootState } from "../../app/store";
import { addEditParner, autosuggestParner, getAllPartners, getPartnersGet, removePartner, upDatePartnerStatus } from "./partnerAPI";

export interface PartnerState {
    data?: Array<IPartnerItem>;
    status: LoadStatus;
    pageStatus: LoadStatus;
    deleteStatus: LoadStatus;
    addEditStatus: LoadStatus;
    changeStatus: LoadStatus;
    totalCount: number;
    allPartners: Array<IAllResponseData>;
    _errors: {
        [key: string]: string;
    };
    totalPages: number;
}

const initialState: PartnerState = {
    status: LoadStatus.Idle,
    pageStatus: LoadStatus.Idle,
    deleteStatus: LoadStatus.Idle,
    addEditStatus: LoadStatus.Idle,
    changeStatus: LoadStatus.Idle,
    totalCount: 0,
    totalPages: 0,
    allPartners: [],
    _errors: {}
};

export interface IResponse {
    payload: {
      data: Array<IPartnerItem>;
        totalPages: number;
        totalElements: number;
        page?: number;
        perPage?: number;
        status?: number;
    }
}

export const getPartnersAsync = createAsyncThunk(
    'partner/get',
    async (requestData: IAppTable.IPaginationOptions) => {
        const response = await getPartnersGet(requestData);
        return response?.data;
    }
);

export const getAllPartnersAsync = createAsyncThunk(
    'partner/getAll',
    async () => {
        const response = await getAllPartners();
        return response?.data;
    }
);

export const removePartnerAsync = createAsyncThunk(
    'partner/delete',
    async (id: string) => {
        const response = await removePartner(id);
        return response.data;
    }
);

export const upDatePartnerStatusAsync = createAsyncThunk(
    'partner/upDate',
    async (id: string) => {
        await upDatePartnerStatus(id);
        return id;
    }
);

export const addEditParnerAsync = createAsyncThunk(
    'partner/addEdit',
    async (data: IPartner, { rejectWithValue }) => {
        const response = await addEditParner(data, rejectWithValue);
        return response;
    }
);

export const autosuggestParnerAsync = createAsyncThunk(
    'partner/searchAutosuggest',
    async (name: string) => {
        const response = await autosuggestParner(name);
        return response;
    }
);

export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        cleanUpPartners: () => {
            return initialState;
        },
        cleanUpStatuses: (state) => {
            state.addEditStatus = initialState.addEditStatus;
            state.changeStatus = initialState.changeStatus;
            state.deleteStatus = initialState.deleteStatus;
            state._errors = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPartnersAsync.rejected, (state: PartnerState) => {
                state.status = LoadStatus.Failed;
                state.pageStatus = LoadStatus.Failed;
              })
            .addCase(getPartnersAsync.pending, (state: PartnerState) => {
                if(state.data) {
                    state.status = LoadStatus.Loading;
                } else {
                    state.pageStatus = LoadStatus.Loading;
                }
            })
            .addCase(getPartnersAsync.fulfilled, (state: PartnerState, action: IResponse) => {
                const payload = action.payload;

                if(payload.totalElements === 0) {
                    state.pageStatus = LoadStatus.Success;
                } else {
                    state.status = LoadStatus.Success;
                    state.pageStatus = LoadStatus.Idle;
                }

                state.data = payload.data;
                state.totalPages = payload.totalPages;
                state.totalCount = payload.totalElements;
            })
            .addCase(removePartnerAsync.rejected, (state: PartnerState) => {
                state.deleteStatus = LoadStatus.Failed;
            })
            .addCase(removePartnerAsync.pending, (state: PartnerState) => {
                state.deleteStatus = LoadStatus.Loading;
            })
            .addCase(removePartnerAsync.fulfilled, (state: PartnerState) => {
                state.deleteStatus = LoadStatus.Success;
            })
            .addCase(upDatePartnerStatusAsync.rejected, (state: PartnerState) => {
                state.changeStatus = LoadStatus.Failed;
              })
            .addCase(upDatePartnerStatusAsync.pending, (state: PartnerState) => {
                state.changeStatus = LoadStatus.Loading;
            })
            .addCase(upDatePartnerStatusAsync.fulfilled, (state: PartnerState, action: { payload: string }) => {
                state.changeStatus = LoadStatus.Success;
                if(state.data) {
                    const id = action.payload;
                    state.data = state.data.map((item) => {
                        if(item.id === id) {
                            item.status = item.status === EPartnerStatus.ACTIVE ? EPartnerStatus.INACTIVE : EPartnerStatus.ACTIVE;
                        }
                        return item;
                    })
                }
            })
            .addCase(addEditParnerAsync.rejected, (state: PartnerState, { payload }) => {
                state.addEditStatus = LoadStatus.Failed;

                const newErrors = (payload as { errors: IError}).errors;
                if (newErrors != null) {
                    newErrors.errors.map((error: {[key: string]: string}) => {
                        return (
                            state._errors = {
                                [error.field]: error.message
                            }
                        )
                    });
                }
              })
            .addCase(addEditParnerAsync.pending, (state: PartnerState) => {
                state.addEditStatus = LoadStatus.Loading;
                state._errors = {};
            })
            .addCase(addEditParnerAsync.fulfilled, (state: PartnerState) => {
                state.addEditStatus = LoadStatus.Success;
                state.pageStatus = LoadStatus.Idle;
            })
            .addCase(getAllPartnersAsync.rejected, (state: PartnerState) => {
                state.allPartners = [];
              })
            .addCase(getAllPartnersAsync.pending, (state: PartnerState) => {
                state.allPartners = [];
            })
            .addCase(getAllPartnersAsync.fulfilled, (state: PartnerState, action: { payload: Array<IAllResponseData> }) => {
                state.allPartners = action.payload;
            });
        }
});

export const { cleanUpPartners, cleanUpStatuses } = partnerSlice.actions;

export const partnerData = (state: RootState) => state.partner.data;
export const partnerStatus = (state: RootState) => state.partner.status;
export const partnerPageStatus = (state: RootState) => state.partner.pageStatus;
export const partnerErrors = (state: RootState) => state.partner._errors;

export const deletePartnerStatus = (state: RootState) => state.partner.deleteStatus;
export const addEditPartnerStatus = (state: RootState) => state.partner.addEditStatus;
export const statusChangeStatus = (state: RootState) => state.partner.changeStatus;

export const allPartnersData = (state: RootState) => state.partner.allPartners;

export default partnerSlice.reducer;
