import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { LoadStatus } from '../../../app/entities/IAppLoadStatus';
import { RootState } from "../../../app/store";
import { IRequestMember, IMemberItem } from '../../../app/entities/IMembers';
import {
    addMember,
    memberGet,
    removeMember,
    resendMemberNotification,
    resendNotificatios,
    uploadMember
} from "./memberAPI";

export interface MembersState {
    data?: Array<IMemberItem>;
    status: LoadStatus;
    pageStatus: LoadStatus;
    deleteStatus: LoadStatus;
    addStatus: LoadStatus;
    uploadStatus: LoadStatus;
    resedNotificationsStatus: LoadStatus;
}

const initialState: MembersState = {
    status: LoadStatus.Idle,
    pageStatus: LoadStatus.Idle,
    deleteStatus: LoadStatus.Idle,
    addStatus: LoadStatus.Idle,
    uploadStatus: LoadStatus.Idle,
    resedNotificationsStatus: LoadStatus.Idle,
};

export interface MemberResponse {
    payload:  Array<IMemberItem>;
}

export const getMemberAsync = createAsyncThunk(
    'member/get',
    async (id: string) => {
        const response = await memberGet(id);
        return response?.data;
    }
);

export const resendMemberNotificationAsync = createAsyncThunk(
    'member/resendNotification/get',
    async ({memberIds, groupId} : {memberIds: number, groupId: string}) => {
        const response = await resendMemberNotification(memberIds, groupId);
        return response?.data;
    }
);

export const removeMemberAsync = createAsyncThunk(
    'member/delete',
    async (requestData: {[key: string]: string | undefined}) => {
        const response = await removeMember(requestData);
        return response.data;
    }
);

export const addMemberAsync = createAsyncThunk(
    'member/add',
    async (data: IRequestMember, { rejectWithValue }) => {
        const response = await addMember(data, rejectWithValue);
        return response;
    }
);

export const uploadMemberAsync = createAsyncThunk(
    'member/upload',
    async (data: IRequestMember, { rejectWithValue }) => {
        const response = await uploadMember(data, rejectWithValue);
        return response;
    }
);

export const resendNotificatiosAsync = createAsyncThunk(
    'member/resendNotificatios',
    async (groupId: string) => {
        const response = await resendNotificatios(groupId);
        return response;
    }
);

export const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        cleanUpMembers: () => {
            return initialState;
        },
        cleanUpStatuses: (state) => {
            state.addStatus = LoadStatus.Idle;
            state.deleteStatus = LoadStatus.Idle;
            state.uploadStatus = LoadStatus.Idle;
            state.resedNotificationsStatus = LoadStatus.Idle;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMemberAsync.rejected, (state: MembersState) => {
                state.status = LoadStatus.Failed;
                state.pageStatus = LoadStatus.Failed;
              })
            .addCase(getMemberAsync.pending, (state: MembersState) => {
                if(state.data) {
                    state.status = LoadStatus.Loading;
                } else {
                    state.pageStatus = LoadStatus.Loading;
                }
            })
            .addCase(getMemberAsync.fulfilled, (state: MembersState, action: MemberResponse) => {
                const payload = action.payload;

                if(payload.length === 0) {
                    state.status = LoadStatus.Idle;
                    state.pageStatus = LoadStatus.Success;
                } else {
                    state.status = LoadStatus.Success;
                    state.pageStatus = LoadStatus.Idle;
                    state.data = payload;
                }
            })
            .addCase(removeMemberAsync.rejected, (state: MembersState) => {
                state.deleteStatus = LoadStatus.Failed;
            })
            .addCase(removeMemberAsync.pending, (state: MembersState) => {
                state.deleteStatus = LoadStatus.Loading;
            })
            .addCase(removeMemberAsync.fulfilled, (state: MembersState) => {
                state.deleteStatus = LoadStatus.Success;
            })
            .addCase(addMemberAsync.rejected, (state: MembersState) => {
                state.addStatus = LoadStatus.Failed;
                // const newErrors = action.payload.errors as IError;
                // state.errors = {
                //   [newErrors.param]: newErrors.error
                // };
              })
            .addCase(addMemberAsync.pending, (state: MembersState) => {
                state.addStatus = LoadStatus.Loading;
                // state.errors = {};
            })
            .addCase(addMemberAsync.fulfilled, (state: MembersState) => {
                state.addStatus = LoadStatus.Success;
                state.pageStatus = LoadStatus.Idle;
            })
            .addCase(uploadMemberAsync.rejected, (state: MembersState) => {
                state.uploadStatus = LoadStatus.Failed;
                // const newErrors = action.payload.errors as IError;
                // state.errors = {
                //   [newErrors.param]: newErrors.error
                // };
              })
            .addCase(uploadMemberAsync.pending, (state: MembersState) => {
                state.uploadStatus = LoadStatus.Loading;
                // state.errors = {};
            })
            .addCase(uploadMemberAsync.fulfilled, (state: MembersState) => {
                state.uploadStatus = LoadStatus.Success;
                state.pageStatus = LoadStatus.Idle;
            })
            .addCase(resendNotificatiosAsync.rejected, (state: MembersState) => {
                state.resedNotificationsStatus = LoadStatus.Failed;

              })
            .addCase(resendNotificatiosAsync.pending, (state: MembersState) => {
                state.resedNotificationsStatus = LoadStatus.Loading;
            })
            .addCase(resendNotificatiosAsync.fulfilled, (state: MembersState) => {
                state.resedNotificationsStatus = LoadStatus.Success;
            })
            .addCase(resendMemberNotificationAsync.rejected, (state: MembersState, {payload}) => {
                state.resedNotificationsStatus = LoadStatus.Failed;
              })
            .addCase(resendMemberNotificationAsync.pending, (state: MembersState, action) => {
                state.resedNotificationsStatus = LoadStatus.Loading;
            })
            .addCase(resendMemberNotificationAsync.fulfilled, (state: MembersState, {payload}) => {
                if (payload && payload?.length && Array.isArray(payload)) {
                    state.resedNotificationsStatus = LoadStatus.Success;
                }else {
                    state.resedNotificationsStatus = LoadStatus.Failed;
                }

            });
    },
});

export const { cleanUpMembers, cleanUpStatuses } = memberSlice.actions;

export const memberData = (state: RootState) => state.member.data;
export const memberStatus = (state: RootState) => state.member.status;
export const memberPageStatus = (state: RootState) => state.member.pageStatus;

export const deleteMemberStatus = (state: RootState) => state.member.deleteStatus;
export const addMemberStatus = (state: RootState) => state.member.addStatus;
export const uploadMemberStatus = (state: RootState) => state.member.uploadStatus;
export const resedNotificationsStatus = (state: RootState) => state.member.resedNotificationsStatus;

export default memberSlice.reducer;
