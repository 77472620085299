import { IAllResponseData } from ".";

export enum EPartnerStatus {
    ACTIVE="ACTIVE",
    INACTIVE="INACTIVE"
}

export interface IPartnerItem {
    id: string;
    name: string;
    corporateId: number;
    allPartners: boolean;
    restaurants: Array<IAllResponseData>;
    status: EPartnerStatus;
    createdAt: string;
}


export interface IPartner {
    id?: string;
    name: string;
    allPartners: boolean;
    restaurants?: Array<IAllResponseData>;
}
export type IPartnerKeys = keyof Pick<IPartner, "name">;
export type IParnerErros = {
    name?: string;
    restaurants?: string;
};