import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AuthLayout from './app/layouts/AuthLayout';
import MainLayout from './app/layouts/MainLayout';
import AppRoutes from './app/routes';
import DesignComponents from './features/DesignComponents';
import Login from './features/Login';

const Addresses = React.lazy(() => import('./features/Address/Addresses'));
const Groups = React.lazy(() => import('./features/Group/Groups'));
const Members = React.lazy(() => import('./features/Group/GroupMembers/Members'));
const Partners = React.lazy(() => import('./features/Partner/Partners'));
const PaymentTypes = React.lazy(() => import('./features/PaymentType/PaymentTypes'));
const ReportOrders = React.lazy(() => import('./features/Report/ReportOrders/ReportOrders'));
const Reports = React.lazy(() => import('./features/Report/Reports'));
const Rules = React.lazy(() => import('./features/Rule/Rules'));

function App() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='/' element={<Login />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path={AppRoutes.OrderRule.path} element={<Rules />} />
        <Route path={AppRoutes.Group.path} >
          <Route path={AppRoutes.Group.path} element={<Groups/>} />
          <Route path={AppRoutes.GroupMembers.path} element={<Members />} />
        </Route>
        <Route path={AppRoutes.Address.path} element={<Addresses />} />
        <Route path={AppRoutes.PaymentType.path} element={<PaymentTypes />} />
        <Route path={AppRoutes.Partners.path} element={<Partners />} />
        <Route path={AppRoutes.Reporting.path} >
          <Route path={AppRoutes.Reporting.path} element={<Reports />} />
          <Route path={AppRoutes.ReportingOrders.path} element={<ReportOrders />} />
        </Route>
      </Route>
        <Route path='/_design'>
          <Route path='components' element={<DesignComponents />} />
        </Route>
    </Routes>
  );
}

export default App;
