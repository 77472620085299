import { useState } from "react";

import { Grid } from "@mui/material";

import AppDateRangePicker from "../../app/components/DateRangePIcker";

export default function RangeDatePickerExp() {
  const [value, setValue] = useState<[Date | null, Date  | null]>([null, null]);

  return (
    <Grid item xs={12}>
      RangeDatePicker
      <AppDateRangePicker value={value} onChange={setValue as () => Date}/>
    </Grid>
  );
}
