import { useTranslation } from "react-i18next";

import {
    Typography,
    MenuItem,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from "@mui/material";

import { rem } from '../../../muiTheme/helpers';
import '../index.scss';

import { IAppTable } from "../../../entities/IAppTable";

interface IProps {
    data: IAppTable.IColumnFilter;
    checkItem: Array<string>;
    loading?: boolean;
    handleAllCheck: (event:  React.ChangeEvent<HTMLInputElement>) => void;
    handleCheck: (event:  React.ChangeEvent<HTMLInputElement>) => void;
}

const AppCheckboxFilter = (props: IProps) => {
    const { t } = useTranslation();
    const { data, checkItem, handleAllCheck, handleCheck } = props;

    return (
        data.loading ? 
            <MenuItem>
                <CircularProgress size={20} />
            </MenuItem>
        :
            <MenuItem 
                className="menuItem hideHover" 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'flex-start',
                    height: rem(200),
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    width: rem(200)
                }}>
                <FormControlLabel
                    label={<Typography 
                        variant="body2" 
                        sx={{
                            marginLeft: rem(-8), 
                            zIndex: "1"
                        }}>
                    {t('reports.all')}
                    </Typography>} 
                    control={
                    <Checkbox
                        checked={checkItem.length === data.data?.length}
                        indeterminate={checkItem.length !== data.data?.length && checkItem.length !== 0}
                        onChange={handleAllCheck}
                    />
                    }
                />
                {data.data?.map((value: {[key: string]: string | null }, index: number) => {
                    return (
                        <FormControlLabel
                            key={index}
                            control={<Checkbox 
                            checked={checkItem.includes(value.id ? value.id.toString() : '')}
                            onChange={handleCheck}
                            name={value.id ? value.id.toString() : ''}
                            />} 
                            label={<Typography 
                                variant="body2"
                                title={value.name ? value.name : ''}
                                className="text-ellipsis"
                                sx={{
                                    marginLeft: rem(-8), 
                                    zIndex: "1",
                                    maxWidth: rem(130)
                                }}>
                                    {value.name ? value.name : ''}
                                </Typography>} 
                        />
                    )
                })}
            </MenuItem>
    )
}

export default AppCheckboxFilter;