import { IError } from './../../../app/entities/IAppError';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadStatus } from "../../../app/entities/IAppLoadStatus";

import { RootState } from "../../../app/store";
import { getData } from "./tableExampleAPI";

export interface IState {
    status: LoadStatus;
    data: Array<{[key:string]: string}>;
    totalCount: number;
    error?: IError;

    options: {[key:string]:string};
}

const initialState: IState = {
    status: LoadStatus.Idle,
    data: [],
    totalCount: 0,

    options: {}
};

export const getExampleDataAsync = createAsyncThunk(
    'tableExample/getData',
    async () => {
      const response = await getData();
      return response.data;
    }
  );

export const tableExampleSlice = createSlice({
    name: 'tableExample',
    initialState,
    reducers: {
        changeTableOptions: (state, action) => {
            state.options = action.payload;
          },
    },
    extraReducers: (builder) => 
        builder
            .addCase(getExampleDataAsync.rejected, (state) => { 
                state.status = LoadStatus.Failed;
            })
            .addCase(getExampleDataAsync.pending, (state) => {
                state.status = LoadStatus.Loading;
                state.data = [];
                state.totalCount = 0;
            })
            .addCase(getExampleDataAsync.fulfilled, (state, action) => {
                state.status = LoadStatus.Success;
                state.data = action.payload.data;
                state.totalCount = action.payload.totalCount;
            })
});

export const { changeTableOptions } = tableExampleSlice.actions;

export const selectStatus = (state: RootState) => state.tableExample.status;

export default tableExampleSlice.reducer;