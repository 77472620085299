import { useTranslation } from "react-i18next";

import { Typography, Box } from "@mui/material";

import { rem } from '../../app/muiTheme/helpers';
import vars from "../../styles/_variables.module.scss";

interface IProps {
    price: number | string;
    deliveryFee?: number | string;
    subtotal: number | string;
    castomerPaid?: number | string;
    total: number | string;
}

export default function OrderAmountInfo(props: IProps) {
    const { price, deliveryFee, subtotal, castomerPaid, total } = props;
    const { t } = useTranslation();

    const renderPrice = (priceName: string, amount: string | number) => {
        const isTotal = priceName === 'total';

        return(
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: rem(10)}}>
                <Typography 
                variant="subtitle2" 
                sx={{
                    color: vars['colors-secondary_3'], 
                    marginRight: rem(10),
                    marginTop: isTotal ? rem(3) : 0
                }}>
                    {t(`report.orderPrice.${ priceName }`)}
                </Typography>                                                    
                <Typography variant={isTotal ? 'h4' : 'body1'}>{ amount.toString().replace(/.{3}$/,' $&') } AMD</Typography>
            </Box>
        )
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {renderPrice('price',price)}
            {deliveryFee && renderPrice('deliveryFee',deliveryFee)}
            {renderPrice('subtotal',subtotal)}
            {castomerPaid && renderPrice('castomerPaid',castomerPaid)}
            {renderPrice('total',total)}
        </Box>
    );
}
