import React from 'react';
import { useState } from "react";

import {
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import AppSelect from "../../app/components/AppSelect";

export default function InputsExp() {
  const [selectValue, setSelectValue] = useState<string>('');

  return (
    <>
    <Grid item xs={12}>
      Inputs
      <Grid>
        <TextField label="Outlined" />
      </Grid>
      <Grid>
        <TextField
          label="Outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img alt='search' src="/img/search.svg" width="20" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {/* <Grid>
        <TextField 
          label="Outlined" 
          type="number" 
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img alt='search' src="/img/search.svg" width="20" />
              </InputAdornment>
            ),
          }} />
      </Grid> */}

      <Grid>
        <AppSelect
          label="Select"
          value={selectValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectValue(e.target.value);
          }}
        >
            <MenuItem value={1}>11111</MenuItem>
            <MenuItem value={2}>2222222</MenuItem>
        </AppSelect>
      </Grid>
    </Grid>
    </>
  );
}
