import { useState } from 'react';
import { Grid, Box } from "@mui/material";

import PhoneNumberInput from '../../app/components/PhoneNumberInput';

export default function PhoneNumberInputExp() {
    const [phoneNumber, setPhoneNumber] = useState("");
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    }

    return(
        <Grid item>
            <Box m={5}>
                <PhoneNumberInput options={phoneNumber} changeOptions={handleChange} />
            </Box>
        </Grid>
    );
}