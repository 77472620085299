import { Grid } from "@mui/material";

export default function SelectedFileListExp() {
    // const fileName = "IT Department List.CSV";
    
    // const removeFile = (e: React.MouseEvent<HTMLButtonElement>, file: string) => {
    // }

    return(
        <Grid item>
            {/* <SelectedFileList fileName={fileName} removeFile={removeFile}/> */}
        </Grid>
    );
}