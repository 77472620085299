import { ITimeSlote } from "../time_slot";

export enum ERulesStatus {
    ACTIVE="ACTIVE",
    INACTIVE="INACTIVE"
}

export interface IRuleItem {
    id: string;
    name: string;
    group: string;
    partner: string;
    addresses: string[] | null;
    timeSlots: ITimeSlote[];
    daysOfWeek: Array<string>;
    paymentTerm: string;
    status: ERulesStatus;
    createdAt: string;
    requireAddress: boolean,
    requireTimeSlot: boolean,
    allowAsap: boolean;
}

export interface IRule {
    id?: string;
    name: string | null;
    partner: string | null;
    group: string | null;
    addresses?: string[] | null;
    daysOfWeek: Array<string>;
    paymentType: string | null;
    timeSlots?: ITimeSlote[] | null | string[] ;
    requireAddress: boolean;
    requireTimeSlot: boolean;
    allowAsap: boolean;
}
export type IRuleKeys = keyof Pick<
  IRule,
  'name' | 'partner' | 'group' | 'addresses' | 'paymentType' | 'timeSlots'
>;

export type IRuleGeneralTabKeys = keyof Pick<
  IRule,
  'name' | 'partner' | 'group' | 'paymentType'
>;

export type IRuleAddressTimeKeys = keyof Pick<IRule, 'addresses' | 'timeSlots'>;

export interface IRuleErros {
    [key: string]: string;
}
