import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { IAuthErrors, IAuthProps } from "../../app/entities/IAppAuth";
import { IError, IResponseDataErrors } from "../../app/entities/IAppError";
import { LoadStatus } from "../../app/entities/IAppLoadStatus";
import { RootState } from "../../app/store";
import {doLogin, getMe} from "./authAPI";

export const getUserInfo = () => {
    const sessionUser = sessionStorage.getItem("user");
    const localUser = localStorage.getItem("user");

    if (sessionUser) {
        return JSON.parse(sessionUser);
    } else if (localUser) {
        return JSON.parse(localUser);
    } else {
        return null;
    }
};

export const getTokenInfo = () => {
    const sessionToken = sessionStorage.getItem("token");
    const localToken = localStorage.getItem("token");

    const sessionRefreshToken = sessionStorage.getItem("refreshToken");
    const localRefreshToken = localStorage.getItem("refreshToken");

    if(sessionToken || localToken) {
        return {
            token: sessionToken ? sessionToken : localToken,
            refreshToken: sessionRefreshToken ? sessionRefreshToken : localRefreshToken
        };
    } else {
        return {
            token: null,
            refreshToken: null
        };
    }
}

export interface AuthState {
    status: LoadStatus;
    token: string | null;
    refreshToken: string | null;
    rememberMe: boolean;
    user: null | {
        username: string;
        name: string | null;
        corporateId: number;
        //TODO: implement with change password functionality
        customized: boolean;
    };
    errors: IAuthErrors;
}

const initialState: AuthState = {
    status: LoadStatus.Idle,
    token: getTokenInfo().token,
    refreshToken: getTokenInfo().refreshToken,
    rememberMe: false,
    user: getUserInfo(),
    errors: {}
}

export const loginAsync = createAsyncThunk(
    'auth/login/do',
    async (data: IAuthProps, { rejectWithValue }) => {
      const response = await doLogin(data, rejectWithValue);
      return response;
    }
);

export const getLoggedInUser = createAsyncThunk(
    'auth/login/getMe',
    async () => {
      const response = await getMe();
      return response;
    }
);

export const authSlice = createSlice({
    name: 'auth/login',
    initialState,
    reducers: {
        logout: () => {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = '/';
            return initialState;
          },
        saveRefreshToken: (state, { payload }) => {
            const { data } = payload;
            state.status = LoadStatus.Success;
            state.token = data.token;
            state.refreshToken = data.refreshToken;
            state.user = { ...data.customer };

            if(state.rememberMe) {
                localStorage.setItem('token',data.token);
                localStorage.setItem('refreshToken',data.refreshToken);
                localStorage.setItem('user', JSON.stringify({ ...data.customer }));
            } else {
                sessionStorage.setItem('token', data.token);
                sessionStorage.setItem('refreshToken', data.refreshToken);
                sessionStorage.setItem('user', JSON.stringify({ ...data.customer }));
            }
        },
        cleanUpStatus: (state) => {
            state.status = LoadStatus.Idle;
            state.errors = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.rejected, (state: AuthState, { payload  }) => {
                state.status = LoadStatus.Failed;
                const newErrors = (payload as { errors: IError}).errors;
                if (newErrors != null) {
                    if (!newErrors.errors) {
                      const responceErrors = (
                        payload as { errors: IResponseDataErrors }
                      ).errors
                      const field = responceErrors.message.includes("password")
                        ? "password"
                        : "username";
                      newErrors.errors = [
                        {
                          field,
                          message: responceErrors.message.replace(/'/g, ""),
                        },
                      ]
                    }
                    newErrors.errors.map((error: {[key: string]: string}) => {
                        return (
                            state.errors = {
                                [error.field]: error.message
                            }
                        )
                    });
                }
            })
            .addCase(loginAsync.pending, (state: AuthState) => {
                state.status = LoadStatus.Loading;
                state.errors = {};
            })
            .addCase(loginAsync.fulfilled, (state: AuthState, { payload }) => {
                const { response: token } = payload;
                state.status = LoadStatus.Success;
                state.token = token;
                state.rememberMe = !!token;
                localStorage.setItem('token', token);
            })

            .addCase(getLoggedInUser.rejected, (state: AuthState, { payload  }) => {
                state.status = LoadStatus.Failed;
                const newErrors = (payload as { errors: IError}).errors;
                if (newErrors != null) {
                    newErrors.errors.map((error: {[key: string]: string}) => {
                        return (
                            state.errors = {
                                [error.field]: error.message
                            }
                        )
                    });
                }
            })
            .addCase(getLoggedInUser.pending, (state: AuthState) => {
                state.status = LoadStatus.Loading;
                state.errors = {};
            })
            .addCase(getLoggedInUser.fulfilled, (state: AuthState, { payload }) => {
                const { corporateClientId: corporateId, login: username, name, requiredChangePassword: customized } = payload;
                const user = {
                    username,
                    corporateId,
                    name,
                    customized
                }
                state.status = LoadStatus.Success;
                state.user = { ...user };
                localStorage.setItem('user', JSON.stringify({...user}));
            })
    },
});

export const { logout, cleanUpStatus, saveRefreshToken } = authSlice.actions;

export const loginStatus = (state: RootState) => state.auth.status;
export const token = (state: RootState) => state.auth.token;
export const loginUserInfo = (state: RootState) => state.auth.user;
export const loginErrors = (state: RootState) => state.auth.errors;

export default authSlice.reducer;
