import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import vars from "../../styles/_variables.module.scss";
import { rem } from "../muiTheme/helpers";
import "./index.scss";

interface IProps {
    name: string;
    content: string | React.ReactElement | Array<React.ReactElement>;
    show: boolean;
}

export default function EmptyPageView(props: IProps) {
    const { name, content, show } = props;

    const { t } = useTranslation();

    if (!show) return null;

    return (
        <Grid 
        sx={{
            marginTop: rem(194), 
        }}
        >
            <Typography 
            variant="h4"
            align="center"
            sx={{
                marginBottom: rem(28),
                position: 'relative'
            }}
            >
                {t('emptyTable.title', { name: `${t(name)}` })}
                <Grid
                className="arrow"
                sx={{
                    marginTop: rem(79),
                    position: 'absolute',
                    bottom: '100%',
                    left: '76%'
                }}> 
                    <img src="/img/longArrow.svg" alt="longArrow" width="126" height="111" />
                </Grid>
            </Typography>
            <Typography 
            variant="subtitle1" 
            align="center"  
            sx={{
                maxWidth: rem(713),
                margin: 'auto',
                color: vars['colors-secondary_2']
            }}>
                {content}
            </Typography>
        </Grid>
    );
}