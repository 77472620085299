import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import addressReducer, { AddressState } from '../features/Address/addressSlice';
import groupReducer, { GroupState } from '../features/Group/groupSlice';
import memberReducer, { MembersState } from '../features/Group/GroupMembers/memberSlice';
import partnerReducer, { PartnerState } from '../features/Partner/partnerSlice';
import paymentTypeReducer, { PaymentTypeState } from '../features/PaymentType/paymentTypeSlice';
import reportReducer, { ReportState } from '../features/Report/reportSlice';
import ruleReducer, { RuleState } from '../features/Rule/ruleSlice';
import authReducer, { AuthState } from '../features/Login/authSlice';
import cannotDeleteDialogReducer, { ICannotDeleteDialogState } from '../features/CannotDeleteDialog/cannotDeleteSlice';
import tableExampleReducer from '../features/DesignComponents/Table/tableExampleSlice';
import changePasswordReducer, { ChangePasswordState } from '../app/layouts/MainLayout/ChangePasswordSlice';
import menuAuthReducer, { MenuAuthState } from "../features/MenuAuth/menuAuthSlice";
import tablesFiltersReducer, { ITableFitersState } from "../features/TablesSaver/tableSaverSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menuAuth: menuAuthReducer,
    address: addressReducer,
    group: groupReducer,
    member: memberReducer,
    partner: partnerReducer,
    paymentType: paymentTypeReducer,
    report: reportReducer,
    rule: ruleReducer,
    tableExample: tableExampleReducer,
    changePassword: changePasswordReducer,
    cannotDeleteDialog: cannotDeleteDialogReducer,
    tablesFilters: tablesFiltersReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export interface AppState {
    auth: AuthState;
    menuAuth: MenuAuthState;
    address: AddressState;
    group: GroupState;
    member: MembersState;
    partner: PartnerState;
    paymentType: PaymentTypeState;
    report: ReportState;
    rule: RuleState;
    changePassword: ChangePasswordState;
    cannotDeleteDialog: ICannotDeleteDialogState;
    tablesFilters: ITableFitersState;
}

export type IAppGlobalState = AuthState & MenuAuthState & AddressState 
  & GroupState & MembersState & PartnerState & PaymentTypeState 
  & ReportState & RuleState & ChangePasswordState & ICannotDeleteDialogState;

export type StateKeys = keyof AppState;
