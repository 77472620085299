export const en = {
  translation: {
    more: "more",
    actions: {
      ok: "Ok",
      add: "Add",
      cancel: "Cancel",
      delete: "Delete",
      edit: "Save",
      addOrderRule: "Add Order Rule",
      change: 'Change',
      confirm: 'Confirm',
    },
    auth: {
      login: 'Login',
      username: 'Username',
      password: 'Password',
      remember: 'Remember me'
    },
    dialog: {
      content: "Are you sure you want to delete",
      orderRule: "Order rule"
    },
    pagination: {
      label: {
        short: "Per page:",
        long: "Rows per page:"
      },
      of: "of"
    },
    messages: {
      nothingFound: 'Nothing Found'
    },
    mainLayout: {
      logout: 'Logout',
      changePassword: 'Change password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password'
    },
    cannotDelete: {
      title: 'You Cannot Delete {{name}}',
      subTitle: 'This {{name}} is linked to meal allowances. Please unlink the {{name}}.'
    },
    emptyTable: {
      title: "You don't have any {{name}} yet :)",
      orderRules: "Order Rules",
      paymentType: "Budget Configuration",
      setAmount: 'Set Amount',
      setPercentage: 'Set Percentage',
      address: "Delivery Addresses",
      groups: "Employee Groups",
      partners: "Restaurants",
      rule: "Meal Allowances",
      members: "Members"
    },
    groupItem: {
      members: "Members"
    },
    daysOfWeek: {
      sun: "Sun",
      mon: "Mon",
      tue: "Tue",
      wed: "Wed",
      thu: "Thu",
      fri: "Fri",
      sat: "Sat"
    },
    report: {
      orderItems: "Order Items and amount",
      order: "Order",
      orderStart: "Order Start",
      orderEnd: "Order End",
      orderPrice: {
        price: "Price",
        deliveryFee: "Delivery Fee",
        subtotal: "Subtotal",
        castomerPaid: "Castomer Paid",
        total: "Total"
      }
    },
    paymentType: {
      title: 'Budget Configurations',
      add: 'Add Budget Configuration',
      edit: 'Edit Budget Configuration',
      setAmount: 'Set Amount',
      setPercentage: 'Set Percentage',
      emptyContent: 'Please click  button to add your first Budget Configuration',
      name: 'Name',
      tableColName: 'Name',
      amount: 'Amount',
      percentage: 'Percentage',
      amountLimit: 'Budget Limit',
      enterPaymentTypeName: 'Enter Budget Configurations Name',
      percentageAmount: 'Amount Limit',
      type: 'Percentage/Amount',
      limites: 'Limits',
      dontExceedAmount: 'Don\'t exceed amount',
      allowMultiple: 'Allow multiple orders',
      status: 'Status',
      actions: 'Actions',
      cannotDelete: {
        title: "This Budget Configuration",
        subTitle: "budget configuration"
      }
    },
    addresses: {
      title: 'Delivery Addresses',
      addressLabel: 'Delivery Address',
      name: 'Name',
      namePlaceholder: 'Enter Delivery Address Name',
      add: 'Add Delivery Address',
      edit: 'Edit Delivery Address',
      detail: 'Additional information',
      apartment: 'Apartment',
      entrance: 'Entrance',
      floor: 'Floor',
      courier: 'Courier instructions',
      intercome: 'Intercome',
      linked: 'Linked',
      enter: 'Enter Your Address',
      select: 'Select your address on the map',
      addAddressBtn: 'Add Delivery Address',
      actions: 'Actions',
      emptyContent: 'Please click  button to add your first Delivery Address',
      cannotDelete: {
        title: "this Address",
        subTitle: "delivery address"
      }
    },
    groups: {
      title: "Employee Groups",
      employeeGroup: "Employee Group",
      name: 'Employee Group Name',
      add: 'Add Employee Group',
      edit: 'Edit Employee Group',
      description: 'Description',
      typeName: 'Enter Employee Group Name',
      addDescr: 'Please add Employee Group Description ',
      symbol: 'Up to 50 symbol /',
      emptyContent: 'Please click  button to add your first Employee Group',
      cannotDelete: {
        title: "a Group",
        subTitle: "Group"
      }
    },
    members: {
      name: "Name",
      add: "Add Members",
      addMember: "Add Member",
      phone: "Phone Number",
      status: "Status",
      actions: "Actions",
      byPhone: "By Phone Number",
      uploadCSV: "Upload CSV file",
      mobile: "Mobile Number",
      attached: "Attached File",
      csv: "Upload CSV",
      format: "Format should be “full phone numbers separated by comma, i.e 37491222222,..“",
      upload: "Upload",
      number: "number",
      emptyContent: "Please click  button to add Members",
      accepted: "Accepted",
      pending: "Pending...",
      rejected: "Rejected",
      resend: {
        tooltip: 'Resend for pending invites',
        title: 'Resend'
      }
    },
    partner: {
      title: 'Delivery Partners',
      add: 'Add Delivery Partners',
      edit: 'Edit Delivery Partners',
      emptyContent: 'Please click  button to add your first Restaurant',
      name: 'Group Name',
      groupName: 'Group Name',
      groupNamePlaceholder: 'Type Group Name',
      restaurant: 'Partners',
      status: 'Status',
      actions: 'Actions',
      allRestaurants: 'All Delivery Partners',
      selectRestaurants: 'Select Delivery Partners',
      selectPlaceholder: 'Type Restaurant Name',
      cannotDelete: {
        title: "a Restaurant",
        subTitle: "Restaurant"
      }
    },
    reports: {
      title: "Reporting",
      download: "Download",
      downloadFull: "Download full info",
      company: "Company",
      allowance: "Allowance",
      date: "Date",
      coveredAmount: "Covered Amount",
      customerAmount: "Customer Amount",
      customers: "Customers",
      phone: "Phone Number",
      orderId: "Order ID",
      customerPaid: "Customer Paid",
      companyPaid: "Company Paid",
      fullAmount: "Order Full Amount",
      total: "Total:",
      sortingDate: "Sorting By Date",
      sortingTime: "Sorting By Time",
      sortASC: "Sort A - Z",
      sortDESC: "Sort Z - A",
      sortLatest: "Latest first",
      sortEarliest: "Earliest first",
      low: "Lowest - Highest",
      high: "Highest - Lowest",
      group: "Group",
      phoneNumber: "Phone number",
      restaurant: "Restaurant",
      orderNumber: "Order Number",
      orderPrice: "Order Price",
      deliveryPrice: "Delivery Fee",
      filtering: "Filtering",
      all: "All",
      customerName: "Customer Name"
    },
    rule: {
      title: 'Meal Allowances',
      mealAllowance: 'Meal Allowance',
      add: 'Add Meal Allowance',
      edit: 'Edit Meal Allowances',
      emptyContent: 'Please ensure that you have added at least one <1>Employee Group</1> with invited members, <1>Delivery Address</1> and <1>Budget Configuration</1> before adding the <strong>“Meal Allowance”</strong>',
      name: 'Meal Allowances name*',
      mealAllowancesName: 'Meal allowances Name',
      mealNamePlaceholder: 'Enter Your Allowances name',
      groupName: 'Group Name',
      restaurant: 'Delivery Partners',
      deliveryPartner: 'Delivery Partner',
      restaurantPlaceholder: 'Select Delivery Partners',
      status: 'Status',
      actions: 'Actions',
      address: 'Delivery Addresses',
      addressPlaceholder: 'Select Delivery Address',
      timeSlot: 'Timeslots',
      timeSlotPlaceholder: 'Select Delivery Slot',
      group: 'Employee Group',
      groupNamePlaceholder: 'Select Employee Group',
      payment: 'Payment',
      budgetType: 'Budget Type',
      paymentType: 'Budget Type',
      paymentTypePlaceholder: 'Select Budget Type',
      daysOfWeek: 'Week Day',
      requireAddress: 'All Addresses',
      requireTimeSlot: 'All Slots',
      allowAsap:"Allow ASAP orders"
    },
    leftMenu: {
      rules: 'Meal Allowances',
      groups: 'Employee Groups',
      address: 'Delivery Addresses',
      paymentTypes: 'Budget Configurations',
      restaurants: 'Delivery Partners',
      reports: 'Order History'
    },
    placeholder: {
      enterPassword: 'Enter your password',
      enterNewPassword: 'Enter new password',
      confirmPassword: 'Confirm new password',
      enterUsername: 'Enter your Username',
      setAmountLimit: 'Set Amount Limit',
      min: "Min",
      max: "Max"
    },
    snackbarMessages: {
      successChangePassword: 'Your password has been successfully changed',
      successResendNotifications: 'Notification were send successfully',
      failedResendNotifications: 'Something went wrong',
    },
    errorMessages: {
      common: 'Field is required',
      LESS_THAN_100: 'Field value must be from 1 to 100',
      MORE_THAN_0: 'Field value must be more 0',
      EQUAL_OR_MORE_THAN_0: 'Field value must be equal or more than 0',
      integer: 'Field value must be integer',
      usernameRequired: 'Username is required',
      validEmail: 'Enter a valid email',
      passowordRequried: 'Password is required',
      passwordLength: 'Password should be of minimum 6 characters length',
      oldPassRequried: 'Old password is required',
      newPassRequried: 'New password is required',
      confirmPassRequried: 'Confirm password is required',
      passwordMatch: 'Passwords must match',
      NOT_EXISTS: 'User does not exist',
      NOT_VALID: 'The password is incorrect',
      OLD_AND_NEW_PASSWORDS_MATCH: 'New password cannot be the same as your old password',
      OUT_OF_ZONE: 'You are out of the courier service range',
      notFullAddress: 'Selected not full address',
      acceptType: 'You can upload only CSV files',
      invalidPhone: 'Invalid phone number',
      ALREADY_EXISTS: 'already exist',
    },
  },
};
