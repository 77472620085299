import React from "react";

import Skeleton from "@mui/material/Skeleton";

interface IProps {
    isLoading: boolean;
    children?: React.ReactElement | null;
}

const AppTableRow = (props: IProps) => {
    const { isLoading = false, children } = props;
    if (isLoading) {
        return <Skeleton animation="wave" />;
    } else {
        return <>{children}</>;
    }
};

export default AppTableRow;
