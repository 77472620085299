export enum IPaymentTypeData {
    data = 'data',
}

export enum IPaymentType {
    AMOUNT="AMOUNT",
    PERCENTAGE="PERCENTAGE"
}

export enum EPaymentTermStatus {
    ACTIVE="ACTIVE",
    INACTIVE="INACTIVE"
}

export interface IPaymentItem {
    id: string;
    name: string;
    status: string;
    configs: {
        amount: number,
        percentage: string,
        dontExceedAmount: boolean,
        allowMultipleOrders: boolean
    };
    type: IPaymentType;
    createdAt: string;
}


export interface IRequestPaymentType {
    name?: string;
    amount?: string;
    amountLimit?: string;
    percentage?: string;
    id?: string;
    type: IPaymentType;
    dontExceedAmount?: boolean;
    allowMultipleOrders?: boolean;
}

export type IPaymentTypeErros = Partial<IRequestPaymentType>;