import React from 'react';
import { IMaskInput } from "react-imask";

import {
    InputAdornment,
    TextField,
    Typography,
    Divider,
    InputBaseComponentProps,
} from "@mui/material";

import vars from "../../styles/_variables.module.scss";

interface IProps {
    options: string;
    changeOptions: (e: React.ChangeEvent<HTMLInputElement>) => void;
    phoneError?: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement>((props, ref) => {
    const { ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00 00 00 00"
        definitions={{
          "#": /[1-9]/
        }}
        inputRef={ref as (el: HTMLInputElement) => void}
        overwrite
      />
    );
});

export default function PhoneNumberInput(props: IProps) {
    const { options, changeOptions, phoneError } = props;

    return (
        <TextField
            placeholder="55 12 34 56"
            value={options || ""}
            onChange={changeOptions}
            error={Boolean(phoneError)}
            helperText={phoneError}
            sx={{
                width: '100%'
            }}
            InputProps={{
                style: {paddingLeft: '10px'},
                inputComponent: TextMaskCustom as React.ElementType<InputBaseComponentProps>,
                startAdornment: (
                <>
                <InputAdornment position="start">
                    <img  src="/img/flag.svg" alt="flag" height="19" width="19"/>
                    <Typography
                        variant="caption"
                        sx={{ marginLeft: "6px", marginRight: "3px", color: vars['colors-secondary'] }}>+374
                    </Typography>
                </InputAdornment>
                <Divider sx={{ height: 24 }} orientation="vertical" />
                </>
                ),
            }}>
        </TextField>
    )
}
