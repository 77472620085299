import vars from "../../styles/_variables.module.scss";
import { rem } from "./helpers";

const MuiFormControl: { MuiFormControl: object } = {
  MuiFormControl: {
    styleOverrides: {
      root: {
        color: vars['colors-secondary_3'],
        "& label": {
          top: rem(-5),
        },
        "& label[data-shrink=true]": {
          top: 0,
          color: vars['colors-secondary'],
        },
      },
    },
  },
};

export default MuiFormControl;
