import { useTranslation } from "react-i18next";

import { Grid, Typography, FormControlLabel, Checkbox } from "@mui/material";

import { rem } from '../../../app/muiTheme/helpers';

interface IProps {
    handleCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
    daysOfWeek: Array<string>;
}

export default function DaysOfTheWeek(props: IProps) {
    const { handleCheck, daysOfWeek = []} = props;
    const { t } = useTranslation();

    const days = [ {day: t('daysOfWeek.mon'), name: 'mon'}, 
                   {day: t('daysOfWeek.tue'), name: 'tue'}, 
                   {day: t('daysOfWeek.wed'), name: 'wed'}, 
                   {day: t('daysOfWeek.thu'), name: 'thu'}, 
                   {day: t('daysOfWeek.fri'), name: 'fri'}, 
                   {day: t('daysOfWeek.sat'), name: 'sat'},
                   {day: t('daysOfWeek.sun'), name: 'sun'} ];

    return (
        <Grid container columnGap={rem(2)}>
            {days.map((value: { [key: string]: string }, index: number) => {
                return (
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                checked={daysOfWeek.includes(value.name)}
                                onChange={handleCheck}
                                name={value.name}
                            />}
                        label={<Typography
                            variant="body1"
                            sx={{
                                marginLeft: rem(-8),
                                zIndex: "1"
                            }}>
                            {value.day}
                        </Typography>}
                    />
                )
            })}
        </Grid>
    );
}