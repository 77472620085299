import { Grid, Icon } from "@mui/material";

export default function IconsExp() {
  return (
    <Grid item xs={12}>
      Icons
      <Grid>
        <Icon>
          <img alt="icon" src="/img/search.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/members.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/group.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/orderRule.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/address.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/timeSlots.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/payment.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/arrow.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/mobile.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/upload.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/delete.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/edit.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/select.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/percent.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/arrowDown.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/map.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/amount.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/arrowSmall.svg" />
        </Icon>
        <Icon>
          <img alt="icon" src="/img/calendar.svg" />
        </Icon>
      </Grid>
    </Grid>
  );
}
