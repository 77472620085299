import { Grid, Avatar } from "@mui/material";

export default function AvatarsExp() {
  return (
    <Grid item xs={12}>
      Avatar
      <Avatar alt="img" src="/_mock/avatar.png" />
      <Avatar alt="Alyona">A</Avatar>
    </Grid>
  );
}
