import { getMenuTokenInfo } from "./../../features/MenuAuth/menuAuthSlice"
import axios, { AxiosInstance } from "axios"
import { doOpenCannotDeleteDialog } from "../../features/CannotDeleteDialog/cannotDeleteSlice"
import {
  getTokenInfo,
  logout,
  saveRefreshToken,
} from "../../features/Login/authSlice"
import { IResponseErrors } from "../entities/IAppError"
import { store } from "../store"

const { REACT_APP_API_SETTINGS } = process.env

interface IParams {
  baseURL?: string | undefined
  headers?: { [key: string]: string }
  url?: string
  data?: string
}

export function createInstance(
  url = REACT_APP_API_SETTINGS,
  isPrivate = true,
  isUpload = false,
  fromMenuApi = false
): AxiosInstance {
  const params: IParams = {
    baseURL: url,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  }

  if (isUpload) delete params.headers
  if (isPrivate) {
    const accessToken = fromMenuApi ? getMenuTokenInfo() : getTokenInfo().token
    if (!params.headers) params.headers = {}
    ;(params.headers as { [key: string]: string | null })["authorization"] =
      accessToken //fromMenuApi ? accessToken : "Bearer " + accessToken;
  }

  return axios.create(params)
}

export function uploadInstance(
  url: string,
  username: string,
  password: string,
  file: string
): AxiosInstance {
  const params: IParams = {
    url: url,
    headers: {
      authorization: "Basic " + window.btoa(`${username}:${password}`),
      "Content-Type": "application/json",
    },
    data: file,
  }

  return axios.create(params)
}

export const handleUnauthorizedError = async (error: IResponseErrors) => {
  if (error.response.status === 401 || error.response.status === 403) {
    localStorage.removeItem("token")
    window.location.pathname = "/"
  }
}

export const doRefreshToken = async () => {
  const refreshToken = store.getState().auth.refreshToken

  try {
    const response = await createInstance(REACT_APP_API_SETTINGS, false).post(
      `/v1/customer/auth/refreshToken`,
      { refreshToken }
    )
    store.dispatch(saveRefreshToken(response.data))
    window.location.reload()
  } catch (err) {
    const error = err as IResponseErrors
    if (error.response.status === 401) {
      store.dispatch(logout())
    }
  }
}

export const checkCannotDeleteError = (
  error: IResponseErrors,
  message: string
) => {
  const response = error.response
  const cannotDelete =
    response?.data?.message === "FORBIDDEN_TO_DELETE"
      ? response.data
      : undefined

  if (response.status === 500 && cannotDelete) {
    store.dispatch(doOpenCannotDeleteDialog(message))
  }
}
