import { memo, useMemo } from "react";

function SelectImage(props: { className: string}) {
  const _props = useMemo(() => props, [props]);
  
  return (
        <svg
          {..._props}
          style={{
            right: '15px'
          }}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#E5E5E5" />
          <path
            d="M8.36029 10.6256C8.16359 10.83 7.83641 10.83 7.63971 10.6256L4.4846 7.34669C4.17894 7.02904 4.40406 6.5 4.84489 6.5L11.1551 6.5C11.5959 6.5 11.8211 7.02904 11.5154 7.34669L8.36029 10.6256Z"
            fill="#999999"
          />
        </svg>
  );
}

export default memo(SelectImage);
