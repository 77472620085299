import { createSlice } from "@reduxjs/toolkit";

import { IAppTable } from "../../app/entities/IAppTable";

export interface ITableFitersState {
    filters: {
        [key: string]: IAppTable.IOptions | Record<string, unknown>;
    }
}

interface IPayload {
    payload: {
        [key: string]: IAppTable.IOptions | Record<string, unknown>;
    };
}

const initialState: ITableFitersState = {
    filters: {}
};

export const tablesFiltersSlice = createSlice({
    name: 'tablesFilters',
    initialState,
    reducers: {
        cleanUpTablesFilters: () => {
            return initialState;
        },
        changeTablesFilters: (_, action: IPayload) => {
            const payload = action.payload;
            return { filters: payload };
        },
    },
    
});

export const { cleanUpTablesFilters, changeTablesFilters } = tablesFiltersSlice.actions;

export default tablesFiltersSlice.reducer;