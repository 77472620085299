import moment from "moment";

export default function TimeFormat(time: string) {
  const offset = moment().zone();
  return moment(time).utcOffset(offset, true).format('YYYY-MM-DD HH:mm');
}

export function requestTimeFormat(time: Date, endDate = false) {
  const offset = moment().zone();
  return moment(time)
    .utcOffset(offset, true)
    .format(endDate ? 'YYYY-MM-DDT23:59:59.0' : 'YYYY-MM-DDTHH:mm:ss.0');
}
