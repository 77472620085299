import {IResponseDataErrors, IResponseErrors} from '../../../app/entities/IAppError';
import {IRequestMember} from '../../../app/entities/IMembers';
import {createInstance, handleUnauthorizedError} from "../../../app/services";

const { REACT_APP_CORPORATE_BACK } =  process.env;

export async function memberGet(id: string) {
    try {
      return await createInstance(REACT_APP_CORPORATE_BACK, true).get(`/group/member/${id}/list`);
    } catch(err) {
      const error = err as IResponseErrors;
      if (error.response) {
        handleUnauthorizedError(error);
      } else {
        throw err;
      }
    }
}

export async function resendMemberNotification(memberIds: number, groupId: string) {
    try {
      return await createInstance(REACT_APP_CORPORATE_BACK, true).get(`/group/member/${groupId}/resend/notification`, {params: {memberIds}});
    } catch(err) {
      const error = err as IResponseErrors;
      if (error.response) {
        handleUnauthorizedError(error);
      } else {
        throw err;
      }
    }
}

export async function removeMember(requestData: {[key: string]: string | undefined}){
  try {
    const response = await createInstance(REACT_APP_CORPORATE_BACK, true).delete(`/group/member/${requestData.memberId}/delete/${requestData.groupId}`);
    return response.data;
  } catch(err) {
    const error = err as IResponseErrors;
    if (error.response) {
      handleUnauthorizedError(error);
    } else {
      throw err;
    }
  }
}

export async function addMember(data: IRequestMember, rejectWithValue: (value: { errors: IResponseDataErrors }) => void){
  const requestData = { ...data };
  try {
      const response = await createInstance(REACT_APP_CORPORATE_BACK, true).post(`/group/member/${requestData.groupId}/add`, requestData.phone);
      return response.data;
  } catch(err) {
    const error = err as IResponseErrors;
    if (error.response) {
      handleUnauthorizedError(error);
      return rejectWithValue({errors: error.response.data});
    } else {
      throw err;
    }
  }
}

export async function uploadMember(data: IRequestMember, rejectWithValue: (value: { errors: IResponseDataErrors }) => void) {
    const requestData = {...data};
    try {
        const response = await createInstance(REACT_APP_CORPORATE_BACK, true, true).post(`/group/member/${requestData.groupId}/add/bulk`, requestData.files);
        return response.data;
    } catch (err) {
        const error = err as IResponseErrors;
        if (error.response) {
            handleUnauthorizedError(error);
            return rejectWithValue({errors: error.response.data});
        } else {
            throw err;
        }
    }
}


export async function resendNotificatios(groupId: string) {
    try {
        const response = await createInstance(REACT_APP_CORPORATE_BACK, true).get(`/group/member/${groupId}/resend/notification`);
        return response.data;
    } catch (err) {
        const error = err as IResponseErrors;
        if (error.response) {
            handleUnauthorizedError(error);
        } else {
            throw err;
        }
    }
}
