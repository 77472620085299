import vars from "../../styles/_variables.module.scss";
import { rem } from "./helpers";

const MuiCheckbox: {MuiCheckbox : object} = {
  MuiCheckbox: {
    styleOverrides: {
      root: {
          padding: rem(11),
          color: vars['colors-secondary_3'],
          '&.Mui-checked': {
            color: vars['colors-secondary'],
          },
          '&.Mui-disabled': {
            color: vars['colors-secondary_4'],
          },
          '.MuiSvgIcon-root': {
            fontSize: rem(24)
          },
          '&.MuiCheckbox-indeterminate': {
            color: vars['colors-secondary'],
          },
          '&:hover': {
            backgroundColor: vars['colors-hover']
          },
          '.MuiTouchRipple-childLeaving': {
            color:  vars['colors-hover_1']
          },
          '.MuiTouchRipple-ripple': {
            color: vars['colors-secondary_5']
          },
          '.MuiTouchRipple-ripplePulsate': {
            color: vars['colors-secondary_5']
          },
          '.MuiTouchRipple-root': {
            color: vars['colors-secondary_5']
          },
          '.MuiTouchRipple-rippleVisible': {
            color: vars['colors-hover_1']
          },
          '.MuiTouchRipple-child': {
            color: vars['colors-secondary_5']
          },
          '.MuiTouchRipple-childPulsate': {
            color: vars['colors-secondary_5']
          },
      },
    }
  }
};

export default MuiCheckbox;
