import { Grid, Button } from "@mui/material";

export default function ButtonsExp() {
  return (
    <Grid item xs={12}>
      Buttons
      <Grid style={{ marginBottom: "10px" }}>
        <Button variant="text">Text</Button>
      </Grid>
      <Grid style={{ marginBottom: "10px" }}>
        <Button variant="contained">Contained</Button>
      </Grid>
      <Grid>
        <Button variant="outlined">Outlined</Button>
      </Grid>
      <Grid style={{ marginBottom: "10px" }}>
        <Button disabled variant="text">
          Text
        </Button>
      </Grid>
      <Grid style={{ marginBottom: "10px" }}>
        <Button disabled variant="contained">
          Contained
        </Button>
      </Grid>
      <Grid>
        <Button disabled variant="outlined">
          Outlined
        </Button>
      </Grid>
    </Grid>
  );
}
