import { Grid, Typography, IconButton } from "@mui/material";

import vars from "../../styles/_variables.module.scss";
import { rem } from "../muiTheme/helpers";

interface IProps {
    iconSrc: string;
    title: string;
    checkout: () => void;
}

export default function CheckoutItem(props: IProps) {
    const { iconSrc, title, checkout } = props;

    return (
        <Grid
            onClick={checkout}
            container
            wrap="nowrap"
            sx={{
                height: rem(60),
                width: '100%',
                border: `${rem(1)} solid ${vars['colors-secondary_5']}`,
                borderRadius: rem(100),
                padding: `${rem(19)} ${rem(23)} ${rem(19)} ${rem(30)}`,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer'
            }}
        >
            <Grid item container wrap="nowrap">
                <img src={iconSrc} alt="icon"/>
                <Typography variant="h5" style={{ marginLeft: rem(14) }}>{title}</Typography>
            </Grid>
            <Grid item>
                <IconButton 
                    aria-label="delete" 
                    edge="end"
                    onClick={checkout}
                >
                    <img src="/img/arrow.svg" alt='arrow'/>
                </IconButton>
            </Grid>
        </Grid>
    );
}