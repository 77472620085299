import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert, Snackbar, SnackbarCloseReason, Typography } from "@mui/material";

import vars from "../../styles/_variables.module.scss";
import { rem } from "../muiTheme/helpers";

interface IProps {
    open: boolean;
    hideDuration?: number;
    type?: 'success' | 'error';
    message: string;
    handleClose?: () => void;
}

export default function AppSnackbar(props: IProps) {
    const { t } = useTranslation();

    const { open, type = 'success', message, hideDuration = 3000 } = props;
    const [ openSnack, setOpenSnack ] = useState(open);
    const isSuccess = type === 'success';

    useEffect(() => {
        setOpenSnack(open);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleClose = (event: Event | SyntheticEvent<Element, Event>, reason: SnackbarCloseReason) => {
        if(props.handleClose) props.handleClose();
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const onClose = () => {
        if(props.handleClose) props.handleClose();
        setOpenSnack(false);
    }

    return (
        <Snackbar 
            open={openSnack}
            autoHideDuration={hideDuration} 
            onClose={handleClose}
            anchorOrigin={{ 
                vertical: 'bottom',
                horizontal: 'right', 
            }}
            className="snackbarStyle"
        >
            <Alert 
                severity={type} 
                icon={isSuccess ? <img src="/img/success.svg" alt="success" width="36" height="36" /> : null}
                className="snackbarAlert"
                sx={{
                    backgroundColor: isSuccess ? vars['colors-background_color'] : '',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    padding: `${rem(10)} ${rem(13.5)}`,
                    borderRadius: rem(5),
                    width: '100%'
                }}
                onClick={onClose}
            >
                <Typography variant="subtitle1" sx={{color: vars['colors-secondary']}}>
                    { t(`${message}`) }
                </Typography>
            </Alert>
        </Snackbar>
    );
}