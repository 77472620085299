import { IChangePasswordProps } from "../../entities/IAppChangePassword";
import { IResponseDataErrors, IResponseErrors } from "../../entities/IAppError";
import { createInstance, handleUnauthorizedError } from "../../services";

const { REACT_APP_CORPORATE_BACK } =  process.env;

export async function passwordChange(data: IChangePasswordProps, rejectWithValue: (value: { errors: IResponseDataErrors }) => void ){
    try {
      const response = await createInstance(REACT_APP_CORPORATE_BACK, true).put(`/customer/change/password`, data);
      return response.data;
    } catch(err) {
      const error = err as IResponseErrors;
      if (error.response) {
        handleUnauthorizedError(error);
        return rejectWithValue({errors: error.response.data});
      } else {
        throw err;
      }
    }
}
