import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Grid } from "@mui/material";
import DialogWrapper from "../../app/components/Dialog";
import { DialogType } from "../../app/entities/IAppComponents";
import { Typography } from "@mui/material";

export default function DialogExp() {
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const { t } = useTranslation();

  const onClose = () => {
    setOpen(false);
    setOpenDelete(false);
    setOpenAlert(false);
  };

  const deleteDialogContent = <>
      <Typography 
        variant="h6" 
        className="space" 
        sx={{display: 'inline-block'}}>“Lunch Time”
      </Typography>
      <Typography 
        variant="subtitle1" 
        sx={{display: 'inline-block'}}>{`${t('dialog.orderRule')}?`}
      </Typography>
    </>;

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        Dialogs
      </Grid>
      <Grid item>
        <Button variant='contained' onClick={() => setOpen(true)}>
          Open Alert Dialog
        </Button>
        <Button variant='contained' onClick={() => setOpenDelete(true)}>
          Open Delete Dialog
        </Button>
        <Button variant='contained' onClick={() => setOpenAlert(true)}>
          Alert Dialog
        </Button>
        
      <DialogWrapper 
        open={open}
        title="Dialog Title"
        content="Dialog Content"
        confirmName='actions.add'
        onConfirm={()=>{
          // do nothing.
        }}
        onCloseDialog={onClose}
      />

      <DialogWrapper 
        open={openDelete}
        variant={DialogType.delete}
        title={"“Lunch Time”"}
        content={deleteDialogContent}
        onConfirm={()=>{
          // do nothing.
        }}
        onCloseDialog={onClose}
      />

      <DialogWrapper 
        open={openAlert}
        variant={DialogType.intermediate}
        title="Add Group"
        content={"Some Content"}
        onCloseDialog={onClose}
      />

      </Grid>
    </Grid>
  );
}
