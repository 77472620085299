import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";

import { DialogType } from "../../entities/IAppComponents";

import { rem } from "../../muiTheme/helpers";
import vars from "../../../styles/_variables.module.scss";

export interface IProps {
  variant?: DialogType;
  confirmName?: string;
  loading?: boolean | React.ReactElement;
  disabled?: boolean;
  isSmall?: boolean;
  onConfirm?: () => void;
  onClose: () => void;
  hideCloseButton?: boolean;
}

export default function AppDialogActions(props: IProps) {
  const {
    variant = DialogType.standart,
    confirmName = "",
    loading,
    disabled,
    isSmall,
    onConfirm,
    onClose,
    hideCloseButton = false
  } = props;
  const { t } = useTranslation();

  if (variant === DialogType.intermediate) return null;

  const content = () => {
    switch (variant) {
      case DialogType.confirm:
        return (
          <Button variant="contained" onClick={onClose}>
            {t("actions.ok")}
          </Button>
        );
      case DialogType.standart:
        return (
          <>
            {!hideCloseButton && <Button disabled={Boolean(loading)} onClick={onClose}>{t("actions.cancel")}</Button>}
            <Button
              variant="contained"
              onClick={onConfirm}
              startIcon={Boolean(loading) && <CircularProgress sx={{color: vars['colors-light_color']}} size={15} />}
              disabled={Boolean(loading) || disabled}
            >
              {t(confirmName)}
            </Button>
          </>
        );
      case DialogType.delete:
        return (
          <>
            <Button disabled={Boolean(loading)} onClick={onClose}>{t("actions.cancel")}</Button>
            <Button
              disabled={Boolean(loading) || disabled}
              startIcon={Boolean(loading) && <CircularProgress sx={{color: vars['colors-light_color']}} size={15} />}
              variant="contained"
              onClick={onConfirm}>
              {t("actions.delete")}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return <DialogActions
            sx={{
              padding: isSmall ? `${rem(40)} ${rem(20)} ${rem(20)} ${rem(20)} !important` : ''
            }}
          >
            {content()}
          </DialogActions>;
}
