import vars from "../../styles/_variables.module.scss";
import { rem } from "./helpers";

const MuiChip: { MuiChip: object } = {
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: rem(40),
        fontSize: rem(14)
      },
      label: {
        padding: `${rem(6)} ${rem(24)}`,
      },
    },
    variants: [
      {
        props: { color: "default" },
        style: {
          backgroundColor: vars["colors-secondary_5"],
          color: vars["colors-secondary"],
        },
      },
      {
        props: { color: "primary" },
        style: {
          backgroundColor: vars["colors-primary_5"],
          color: vars["colors-primary"],
        },
      },
      {
        props: { color: "info" },
        style: {
          backgroundColor: vars["colors-other_color_2_1"],
          color: vars["colors-other_color_2"],
        },
      },
      {
        props: { color: "success" },
        style: {
          backgroundColor: vars["colors-other_color_1_1"],
          color: vars["colors-other-color_1"],
        },
      },
      {
        props: { color: "error" },
        style: {
          backgroundColor: vars["colors-primary_4"],
          color: vars["colors-error"],
        },
      },
    ],
  },
};

export default MuiChip;
