import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Divider, Grid } from "@mui/material";

import vars from "../../../styles/_variables.module.scss";
import "./index.scss";
import UserActions from './UserActions';

interface IProps {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

export default function Header(props: IProps) {
  const { drawerWidth, handleDrawerToggle } = props;

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',
      }}
    >
      <Toolbar className="toolbar">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" }, color: vars['colors-primary'] }}
        >
          <MenuIcon />
        </IconButton>
        <Grid  
        container 
        sx={{
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center'
        }}>
          <Grid item>
            {/* <TextField
              placeholder="Search..."
              sx={{ width: rem(154) }}
              InputProps={{
                style: { height: rem(40) },
                endAdornment: (
                  <InputAdornment position="end">
                    <img alt='search' src="/img/search.svg" width="20" />
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <UserActions />
        </Grid>
      </Toolbar>
      <Divider className="headerDivider"/>
    </AppBar>
  );
}
