import React from "react";

import IconButton from "@mui/material/IconButton";

interface IProps {
    onClick: (event: React.BaseSyntheticEvent) => void;
}

const EditButton = (props: IProps) => {
  const { onClick } = props;

  return (
    <IconButton
      size="small"
      edge="end"
      onClick={onClick}
    >
      <img alt='edit' src='/img/edit.svg' />
    </IconButton>
  );
};

export default EditButton;
