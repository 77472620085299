import Avatar from '@mui/material/Avatar';

import { ImageVariant } from '../entities/IAppComponents';

interface IProps {
    src: string;
    variant?: ImageVariant;
}

export default function AppImage(props: IProps) {
    const { src,  variant = ImageVariant.small } = props;
    const isSmall = variant === ImageVariant.small;

    return (
        <Avatar 
            alt='img' 
            variant="rounded" 
            src={src} 
            sx={{
                width: isSmall? 57 : 80,
                height: isSmall? 56 : 86,
                background: '#FFFFFF',
                borderRadius: '10px'
            }}
        />
    );
}