import { IDialogProps } from "../components/Dialog";
import { rem } from "./helpers";


const MuiDialog: { MuiDialog: object } = {
  MuiDialog: {
    styleOverrides: {
      root: (props: {ownerState: IDialogProps}) => {
        return {
          "& .MuiPaper-root": {
            borderRadius:  props.ownerState.fullScreen? 0 : rem(30)
          },
          '& .MuiDialogTitle-root': {
            paddingTop: rem(30),
            paddingLeft: rem(30),
            paddingRight: rem(30),
            paddingBottom: rem(40),
            fontWeight: 700,
            fontSize: rem(34),
            lineHeight: rem(40),
          },
          '& .MuiDialogContent-root': {
            paddingTop: rem(30),
            paddingLeft: rem(30),
            paddingRight: rem(30),
            // paddingBottom: rem(40),
          },
          '& .MuiDialogActions-root': {
            padding: rem(30)
          },
          '& .MuiDialogActions-root>:not(:first-of-type)': {
            marginLeft: '10px'
          }
        }
      }
    },
  },
};

export default MuiDialog;
