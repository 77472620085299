import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
    Typography,
    MenuItem,
    // Divider,
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// import vars from "../../../../styles/_variables.module.scss";
import { rem } from '../../../muiTheme/helpers';
import '../index.scss';

import { Filter, IAppTable, ISortDirection } from "../../../entities/IAppTable";
import AppCheckboxFilter from "./AppCheckboxFilter";

interface IProps {
    filter: IAppTable.IColumnFilter;
    sort: (event: React.SyntheticEvent, direction: ISortDirection) => void;
    doSearch: (event: React.SyntheticEvent, value: string, column: string) => void;
    options: IAppTable.ISortingOptions;
    column: string;
}

const AppTextFilter = (props: IProps) => {
    const { t } = useTranslation();
    
    const { column, filter, doSearch } = props;

    const [checkItem, setCheckItem] = useState<Array<string>>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        let newState = [];
        if(checkItem.includes(name)){
            newState = checkItem.filter((item: string) => item !== name);
        } else {
            newState = checkItem.concat([name]);
        }
        setCheckItem(newState);
    }

    const handleAllCheck  = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked) {
            const allIds = filter && filter.data ? filter.data.map(value => value.id.toString()) : [];
            setCheckItem(allIds);
        } else {
            setCheckItem([]);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }

    return (
        <div>
            {/* <MenuItem  
                className="menuItem" 
                onClick={(event: React.SyntheticEvent) => sort(event, ISortDirection.asc)}
                selected={options.stringAsc === column}
            >
                <Typography 
                    variant="body2" 
                    color={vars['colors-secondary_1']}
                >
                 {t('reports.sortASC')}
                </Typography>
                {options.stringAsc === column && <img src="/img/selected.svg" alt="selected" style={{marginLeft: rem(21)}}/>}
            </MenuItem>
            <MenuItem  
                className="menuItem" 
                onClick={(event: React.SyntheticEvent) => sort(event, ISortDirection.desc)}
                selected={options.stringDesc === column}
            >
                <Typography 
                    variant="body2" 
                    color={vars['colors-secondary_1']} 
                >
                {t('reports.sortDESC')}
                </Typography>
                {options.stringDesc === column && <img src="/img/selected.svg" alt="selected" style={{marginLeft: rem(21)}}/>}
            </MenuItem>
            <Divider className="menuDivider"/> */}
            <Typography 
                variant="subtitle2"
                sx={{
                    fontWeight: '700',
                    lineHeight: rem(20),
                    marginLeft: rem(10),
                    marginTop: rem(15),
                    textTransform: 'uppercase'
                }}
            >
                {t('reports.filtering')}
            </Typography>
            {filter.type === Filter.textSearch && <MenuItem className="menuItem hideHover" sx={{ marginTop: rem(5) }}>
                <TextField
                    placeholder="Search..."
                    className="menuTextfield"
                    value={ searchValue }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
                    onKeyPress={e => { e.which === 13 && doSearch(e, searchValue, column) }}
                    InputProps={{
                    style: { height: rem(40) },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                size="small"
                                style={{ marginRight: '-5px'}}
                                disabled={Boolean(!searchValue || searchValue && searchValue.trim() === "")}
                                onClick={(event: React.SyntheticEvent) => {
                                    if(searchValue && searchValue.trim() !== "") {
                                        setSearchValue("");
                                        doSearch(event, "" , column);
                                    }
                                }}
                            >
                               <CloseIcon />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={(event: React.SyntheticEvent) => doSearch(event, searchValue, column)}
                            >
                               <img alt='search' src="/img/search.svg" width="20" />
                            </IconButton>
                        </InputAdornment>
                    ),
                    }}
                />
            </MenuItem>}
            {filter && filter.type === Filter.textCheckbox && 
                <AppCheckboxFilter 
                    data={filter}
                    checkItem={checkItem} 
                    handleAllCheck={handleAllCheck} 
                    handleCheck={handleCheck} />
            }
        </div>
    )
}

export default AppTextFilter;