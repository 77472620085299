export async function getData() {
  // try {
    return {
      data: {
        totalCount: 2,
        data: [
          {
            name: "aaa",
            address: "bbbb",
          },
          {
            name: "aaa222",
            address: "bbbb222",
          },
        ],
      },
    };
  // } catch (e) {
  //   throw e;
  // }
}
