import { ReactElement, memo } from "react";

import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

import { rem } from "../muiTheme/helpers";
import vars from "../../styles/_variables.module.scss";
import SelectImage from "./SelectImage";

interface IProps extends StandardTextFieldProps {
  children: ReactElement | Array<ReactElement>;
  disabled?: boolean;
}

function AppSelect(props: IProps) {
  const { children, ...rest } = props;
  const { value, placeholder, label } = rest;

    return (
        <>
            <Typography
                variant="body2"
                sx={{
                    color: vars["colors-secondary"],
                    marginBottom: rem(8),
                }}
            >
                {label}
            </Typography>
            <FormControl fullWidth>
                {!value && <InputLabel>{placeholder}</InputLabel>}
                <TextField
                    select
                    {...rest}
                    label={placeholder}
                    SelectProps={{
                        style: {
                            height: rem(50),
                        },
                        IconComponent: (props) => (
                            <SelectImage {...props} />
                        ),
                    }}
                >
                    {children}
                </TextField>
            </FormControl>
        </>
    );
}

export default memo(AppSelect);