import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { LoadStatus } from './../../app/entities/IAppLoadStatus';
import { RootState } from "../../app/store";
import { EPaymentTermStatus, IPaymentItem, IPaymentTypeData, IRequestPaymentType } from './../../app/entities/IAppPaymentType';
import { addEditPaymentType, paymentTypesGet, paymentTypesGetAll, removePaymentType, upDateStatus } from "./paymentTypeAPI";
import { IAppTable } from "../../app/entities/IAppTable";
import { IAllResponseData } from "../../app/entities";

export interface PaymentTypeState {
    data?: Array<IPaymentItem>;
    status: LoadStatus;
    pageStatus: LoadStatus;
    deleteStatus: LoadStatus;
    addEditStatus: LoadStatus;
    changeStatus: LoadStatus;
    totalCount: number;
    totalPages: number;
    allPaymentTypes: Array<IAllResponseData>;
}

const initialState: PaymentTypeState = {
    status: LoadStatus.Idle,
    pageStatus: LoadStatus.Idle,
    deleteStatus: LoadStatus.Idle,
    addEditStatus: LoadStatus.Idle,
    changeStatus: LoadStatus.Idle,
    totalCount: 0,
    totalPages: 0,
    allPaymentTypes: []
};

export type IPaymentTypeErrors = {
    [key in keyof typeof IPaymentTypeData]?: string
};

export interface PaymentTypeResponse {
    payload: {
      data: Array<IPaymentItem>;
      totalPages: number;
      totalElements: number;
      page?: number;
      perPage?: number;
      status?: number;
    }
}

export const getPaymentTypesAsync = createAsyncThunk(
    'paymentTypes/get',
    async (requestData: IAppTable.IPaginationOptions) => {
        const response = await paymentTypesGet(requestData);
        return response?.data;
    }
);

export const getAllPaymentTypesAsync = createAsyncThunk(
    'paymentTypes/getAll',
    async () => {
        const response = await paymentTypesGetAll();
        return response?.data;
    }
);

export const removePaymentTypeAsync = createAsyncThunk(
    'paymentType/delete',
    async (id: string) => {
        const response = await removePaymentType(id);
        return response.data;
    }
);

export const upDateStatusAsync = createAsyncThunk(
    'paymentType/upDate',
    async (id: string) => {
        await upDateStatus(id);
        return id;
    }
);

export const addEditPaymentTypeAsync = createAsyncThunk(
    'paymentType/addEdit',
    async (data: IRequestPaymentType, { rejectWithValue }) => {
        const response = await addEditPaymentType(data, rejectWithValue);
        return response;
    }
);

export const paymentTypeSlice = createSlice({
    name: 'paymentTypes',
    initialState,
    reducers: {
        cleanUpPaymentTypes: () => {
            return initialState;
        },
        cleanUpStatuses: (state) => {
            state.addEditStatus = initialState.addEditStatus;
            state.changeStatus = initialState.changeStatus;
            state.deleteStatus = initialState.deleteStatus;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentTypesAsync.rejected, (state: PaymentTypeState) => {
                state.status = LoadStatus.Failed;
                state.pageStatus = LoadStatus.Failed;
              })
            .addCase(getPaymentTypesAsync.pending, (state: PaymentTypeState) => {
                if(state.data) {
                    state.status = LoadStatus.Loading;
                } else {
                    state.pageStatus = LoadStatus.Loading;
                }
            })
            .addCase(getPaymentTypesAsync.fulfilled, (state: PaymentTypeState, action: PaymentTypeResponse) => {
                const payload = action.payload;

                if(payload.totalElements === 0) {
                    state.pageStatus = LoadStatus.Success;
                } else {
                    state.status = LoadStatus.Success;
                    state.pageStatus = LoadStatus.Idle;
                }

                state.data = payload.data;
                state.totalPages = payload.totalPages;
                state.totalCount = payload.totalElements;
            })
            .addCase(removePaymentTypeAsync.rejected, (state: PaymentTypeState) => {
                state.deleteStatus = LoadStatus.Failed;
            })
            .addCase(removePaymentTypeAsync.pending, (state: PaymentTypeState) => {
                state.deleteStatus = LoadStatus.Loading;
            })
            .addCase(removePaymentTypeAsync.fulfilled, (state: PaymentTypeState) => {
                state.deleteStatus = LoadStatus.Success;
            })
            .addCase(upDateStatusAsync.rejected, (state: PaymentTypeState) => {
                state.changeStatus = LoadStatus.Failed;
              })
            .addCase(upDateStatusAsync.pending, (state: PaymentTypeState) => {
                state.changeStatus = LoadStatus.Loading;
            })
            .addCase(upDateStatusAsync.fulfilled, (state: PaymentTypeState, action: { payload: string }) => {
                state.changeStatus = LoadStatus.Success;

                if(state.data) {
                    const id = action.payload;
                    state.data = state.data.map((item) => {
                        if(item.id === id) {
                            item.status = item.status === EPaymentTermStatus.ACTIVE ? EPaymentTermStatus.INACTIVE : EPaymentTermStatus.ACTIVE;
                        }
                        return item;
                    })
                }
            })
            .addCase(addEditPaymentTypeAsync.rejected, (state: PaymentTypeState) => {
                state.addEditStatus = LoadStatus.Failed;
                // const newErrors = action.payload.errors as IError;
                // state.errors = {
                //   [newErrors.param]: newErrors.error
                // };
              })
            .addCase(addEditPaymentTypeAsync.pending, (state: PaymentTypeState) => {
                state.addEditStatus = LoadStatus.Loading;
                // state.errors = {};
            })
            .addCase(addEditPaymentTypeAsync.fulfilled, (state: PaymentTypeState) => {
                state.addEditStatus = LoadStatus.Success;
                state.pageStatus = LoadStatus.Idle;
            })
            .addCase(getAllPaymentTypesAsync.rejected, (state: PaymentTypeState) => {
                state.allPaymentTypes = [];
              })
            .addCase(getAllPaymentTypesAsync.pending, (state: PaymentTypeState) => {
                state.allPaymentTypes = [];

            })
            .addCase(getAllPaymentTypesAsync.fulfilled, (state: PaymentTypeState, action: { payload:  Array<IAllResponseData> }) => {
                state.allPaymentTypes = action.payload;
            });
    },
});

export const { cleanUpPaymentTypes, cleanUpStatuses } = paymentTypeSlice.actions;

export const paymentTypeData = (state: RootState) => state.paymentType.data;
export const paymentTypeStatus = (state: RootState) => state.paymentType.status;
export const paymentTypePageStatus = (state: RootState) => state.paymentType.pageStatus;

export const deletePaymentTypeStatus = (state: RootState) => state.paymentType.deleteStatus;
export const addEditPaymentTypeStatus = (state: RootState) => state.paymentType.addEditStatus;
export const statusChangeStatus = (state: RootState) => state.paymentType.changeStatus;

export const allPaymentTypesData = (state: RootState) => state.paymentType.allPaymentTypes;

export default paymentTypeSlice.reducer;
