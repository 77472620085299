import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import "./index.scss";

interface IProps {
    value: [Date | null, Date  | null];
    onChange: () => void;
}

export default function AppDateRangePicker(props: IProps) {
    const { value, onChange } = props;

  return (
     <DateRangePicker value={value as [Date, Date]} placeholder='' onChange={onChange} />
  );
}