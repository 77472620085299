import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import appTheme from './app/muiTheme/theme';

import './styles/index.scss';
import './app/i18n';

import { BrowserRouter } from 'react-router-dom';
import PageLoading from './app/components/PageLoading';

const container = document ? document.getElementById('root') : null;
const root = container && createRoot(container);

root?.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={appTheme}>
        {/* <CssBaseline /> */}
        <BrowserRouter>
          <Suspense fallback={<PageLoading />}>
            <App />
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
