import { Grid, Switch } from "@mui/material";

export default function SwitchsExp() {
  return (
    <Grid item xs={12}>
      Switch
      <Grid>
        <Switch defaultChecked />
      </Grid>
    </Grid>
  );
}
