import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AppRoutes from "../../routes";
import IAppRoute from "../../entities/IAppRoute";
import { Typography } from "@mui/material";

import vars from "../../../styles/_variables.module.scss";
import { rem } from '../../muiTheme/helpers';
import "./index.scss";

interface IProps {
    closeDrawer: () => void;
}

export default function LeftMenu(props: IProps) {
  const { closeDrawer } = props;
  const location = useLocation();
  const { t } = useTranslation();

  const appUrls = Object.keys(AppRoutes)
          .map((item: string) => (AppRoutes as {[key: string]: IAppRoute})[item])
          .filter((item: IAppRoute) => !item.isChild);

  return (
    <div>
      <img 
      src="/img/logo.png" 
      alt="logo"
      style={{
        marginTop: rem(33), 
        paddingLeft: rem(53), 
        marginBottom: rem(30)
      }}/>
      <List>
        {appUrls.map((route: IAppRoute, index: number) => {
          const isActive = location.pathname === route.path;
          
          return (
            <ListItem key={index + route.name} disablePadding>
              <Link to={route.path} style={{width: '100%', textDecoration: 'none'}}>
                <ListItemButton disabled={isActive} onClick={closeDrawer} className="listItem">
                  <ListItemIcon
                  className="listItemIcon">
                    <img  src={route.icon} alt="icon" className={isActive ? 'logoImg' : ''}/> 
                  </ListItemIcon>
                  <ListItemText>
                    <Typography  
                    variant="body2" 
                    sx={{
                      color: isActive ? vars['colors-primary'] : vars['colors-secondary']
                    }}>
                      {`${t(`leftMenu.${route.path.substring(1)}`)}`}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}