import { createTheme } from "@mui/material/styles";

import vars from '../../styles/_variables.module.scss';

import MuiAvatar from "./avatar";
import MuiButton from "./buttons";
import MuiChip from "./chips";
import AppPalette from "./palette";
import AppTypography from "./typography";
import MuiSwitch from "./switch";
import MuiCheckbox from "./checkbox";
import MuiFormControlLabel from "./formControl";
import MuiRadio from "./radiobuttons";
import MuiTextField from "./input";
import MuiFormControl from "./formControl";
import MuiDialog from "./dialog";
import MuiAvatarGroup from "./avatarGroup";

const appTheme = createTheme({
    palette: AppPalette,
    typography: AppTypography,
    breakpoints: {
        values: {
          xs: parseInt(vars['breakpoints-xs']),
          sm: parseInt(vars['breakpoints-sm']),
          md: parseInt(vars['breakpoints-md']),
          lg: parseInt(vars['breakpoints-lg']),
          xl: parseInt(vars['breakpoints-xl']),
        },
    },
    components: {
      ...MuiButton,
      ...MuiChip,
      ...MuiAvatar,
      ...MuiSwitch,
      ...MuiCheckbox,
      ...MuiRadio,
      ...MuiFormControlLabel,
      ...MuiFormControl,
      ...MuiTextField,
      ...MuiDialog,
      ...MuiAvatarGroup
  }
});

export default appTheme;