import { rem } from "./helpers";

const MuiAvatarGroup: {MuiAvatarGroup : object} = {
    MuiAvatarGroup: {
        styleOverrides: {
          root: {
            '& .MuiAvatar-root': {
              marginLeft: rem(-24)
            }
          },
        },
      },
};

export default MuiAvatarGroup;
