import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";

export interface ICannotDeleteDialogState {
    open: boolean;
    dataDialog: string;
}

const initialState: ICannotDeleteDialogState = {
    open: false,
    dataDialog: ''
};

export const cannotDeleteDialogSlice = createSlice({
    name: 'cannotDeleteDialog',
    initialState,
    reducers: {
        cleanUpCannotDeleteDialog: () => {
            return initialState;
        },
        doOpenCannotDeleteDialog: (state, action: { payload: string; }) => {
            state.dataDialog = action.payload;
            state.open = true;
        }
    },
});

export const { cleanUpCannotDeleteDialog, doOpenCannotDeleteDialog } = cannotDeleteDialogSlice.actions;

export const openCannotDeleteDialog = (state: RootState) => state.cannotDeleteDialog.open;
export const dataCannotDeleteDialog = (state: RootState) => state.cannotDeleteDialog.dataDialog;


export default cannotDeleteDialogSlice.reducer;