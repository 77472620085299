import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from "../../hooks";

import Box from "@mui/material/Box";

import { rem } from '../../muiTheme/helpers';
import { loginUserInfo } from "../../../features/Login/authSlice";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { IChangePasswordErrors, IChangePasswordProps } from "../../entities/IAppChangePassword";
import { changePasswordAsync, changePasswordErrors, changePasswordStatus, cleanUpStatus } from "./ChangePasswordSlice";
import { LoadStatus } from "../../entities/IAppLoadStatus";
import vars from "../../../styles/_variables.module.scss";
import DialogWrapper from "../../components/Dialog";

interface IProps {
    fromMain?: boolean;
    handleClose?: () => void;
    openDialog: boolean;
    onOpen: (value: boolean) => void;
}

const validationSchemaChange = yup.object({
  password: yup
    .string()
    .trim('errorMessages.oldPassRequried')
    .required('errorMessages.oldPassRequried')
    .min(6, 'errorMessages.passwordLength'),
  newPassword: yup
    .string()
    .trim('errorMessages.newPassRequried')
    .required('errorMessages.newPassRequried')
    .min(6, 'errorMessages.passwordLength'),
  confirmPassword: yup
    .string()
    .trim('errorMessages.confirmPassRequried')
    .required('errorMessages.confirmPassRequried')
    .oneOf([yup.ref('newPassword'), null], 'errorMessages.passwordMatch')
});

export default function ChangePassword(props: IProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { fromMain = false, openDialog, onOpen } = props;

  const formikChange = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    validateOnChange: false,
    validationSchema: validationSchemaChange,
    onSubmit: (values) => {
        const requestData: IChangePasswordProps = {
            oldPassword: values.password,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword
        }
        dispatch(changePasswordAsync(requestData));
    },
  });

  const statusChange = useAppSelector(changePasswordStatus);
  const errorsChangePassword = useAppSelector(changePasswordErrors);
  const userInfo = useAppSelector(loginUserInfo);
  const loadingChange = statusChange === LoadStatus.Loading;
  const successChange = statusChange === LoadStatus.Success;

  const [errorsChange, setErrorsChange] = useState<IChangePasswordErrors>({});

  const hasPasswordError = formikChange.touched.password && Boolean(formikChange.errors.password);
  const hasOldPasswordError = errorsChange && Boolean(errorsChange.oldPassword);
  const hasNewPasswordFormikError =  formikChange.touched.newPassword && Boolean(formikChange.errors.newPassword);
  const hasNewPasswordError = errorsChange && Boolean(errorsChange.newPassword);
  const hasConfirmPasswordFormikError = formikChange.touched.confirmPassword && Boolean(formikChange.errors.confirmPassword);

  useEffect(() => {
    setErrorsChange(errorsChangePassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsChangePassword]);

  useEffect(() => {
    if(fromMain) {
        if(userInfo?.customized) {
            onOpen(true);
        }
    }
    return () => {
      dispatch(cleanUpStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.customized, fromMain]);

  useEffect(() => {
    if (successChange) {
        formikChange.setValues(formikChange.initialValues);
        formikChange.setTouched({}, false);
        setErrorsChange({});
        onOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successChange]);

  const onCloseDialog = () => {
      formikChange.setTouched({}, false);
      setErrorsChange({});
      formikChange.setValues(formikChange.initialValues);
      onOpen(false);
  };


  const dialogContent =
    <Box component="form" noValidate>
        <Typography
            variant="caption"
            sx={{
                color: vars['colors-secondary'],
            }}>{ t('mainLayout.oldPassword') }</Typography>
        <TextField
            margin="normal"
            fullWidth
            placeholder={ t('placeholder.enterPassword') || ''}
            name="password"
            type="password"
            autoComplete="password"
            autoFocus
            value={formikChange.values.password}
            onChange={formikChange.handleChange}
            sx={{marginTop: rem(4), marginBottom: rem(16)}}
            onKeyPress={e => { e.which === 13 && e.preventDefault() }}
            error={hasPasswordError || hasOldPasswordError}
            helperText={(hasPasswordError && t(`${formikChange.errors.password}`)) ||
                        (hasOldPasswordError && t(`errorMessages.${errorsChange.oldPassword}`)) }
            inputProps={{
                style: {
                    height: rem(14)
                }
            }}
        />
        <Typography
            variant="caption"
            sx={{
                color: vars['colors-secondary'],
            }}>{ t('mainLayout.newPassword') }</Typography>
        <TextField
            margin="normal"
            fullWidth
            name="newPassword"
            placeholder={ t('placeholder.enterNewPassword') || ''}
            type="password"
            autoComplete="newPassword"
            value={formikChange.values.newPassword}
            onChange={formikChange.handleChange}
            sx={{marginTop: rem(4), marginBottom: rem(16)}}
            onKeyPress={e => { e.which === 13 && e.preventDefault() }}
            error={hasNewPasswordFormikError || hasNewPasswordError}
            helperText={(hasNewPasswordFormikError && t(`${formikChange.errors.newPassword}`)) ||
                        (hasNewPasswordError && t(`errorMessages.${errorsChange.newPassword}`))}
            inputProps={{
                style: {
                    height: rem(14)
                }
            }}
        />
        <Typography
            variant="caption"
            sx={{
                color: vars['colors-secondary'],
            }}>{ t('mainLayout.confirmPassword') }</Typography>
        <TextField
            margin="normal"
            fullWidth
            name="confirmPassword"
            placeholder={ t('placeholder.confirmPassword') || ''}
            type="password"
            autoComplete="confirmPassword"
            value={formikChange.values.confirmPassword}
            onChange={formikChange.handleChange}
            sx={{marginTop: rem(4), marginBottom: 0}}
            onKeyPress={e => { e.which === 13 && e.preventDefault() }}
            error={hasConfirmPasswordFormikError}
            helperText={hasConfirmPasswordFormikError && t(`${formikChange.errors.confirmPassword}`)}
            inputProps={{
                style: {
                    height: rem(14)
                }
            }}
        />
    </Box>;

    return (
        <DialogWrapper
        disableEscapeKeyDown={true}
        hideCloseButton={fromMain}
        open={openDialog}
        title={"Change Password"}
        content={dialogContent}
        loading={loadingChange && <CircularProgress sx={{color: vars['colors-light_color']}} size={15}/>}
        confirmName='actions.change'
        onConfirm={formikChange.handleSubmit}
        onCloseDialog={onCloseDialog}
        className="dialogContent"
    />
  );
}
