import vars from "../../styles/_variables.module.scss";
import { rem } from "./helpers";

const MuiTextField: {MuiOutlinedInput : object} = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: rem(10),
        input: {
          color: vars['colors-secondary'],
          paddingTop: rem(18),
          paddingBottom: rem(18),
          paddingLeft: rem(15),
          paddingRight: rem(15),
          fontWeight: 400,
          fontSize: rem(12),
          lineHeight: rem(14),
          background:  vars['colors-light_color'],
          borderRadius: rem(10),
          '::placeholder': {
            color: vars['colors-secondary_3'],
            opacity: 1,
            fontWeight: '400',
            lineHeight: rem(14),
            fontSize: rem(12)
          }
        },
        '& input:valid + fieldset': {
          borderColor: vars['colors-secondary_5'],
          borderWidth: 1,
          borderRadius: rem(10),
        },
        '& input: -internal-autofill-selected': {
          borderRadius: rem(10),
          backgroundColor: vars['colors-light_color'] + '!important',
          // "-webkit-text-fill-color": vars['colors-light_color'] + '!important',
        },
        '& input:-webkit-autofill': { 
          "WebkitBackgroundClip": "text",
        },
        '&: hover': {
          '& input:valid + fieldset':{
            borderColor:  vars['colors-secondary_5'],
          },
          '& input:valid + div + fieldset':{
            borderColor:  vars['colors-secondary_5'],
          }
        },
        '& .MuiOutlinedInput-notchedOutline':{
          borderColor:  vars['colors-secondary_5'],
        },
        '&.Mui-focused': {
          '.MuiOutlinedInput-notchedOutline':{
            borderColor:  vars['colors-secondary'],
          }
        },
        // '.MuiSelect-icon': {
        //   fill: 'url(/img/arrowDown.svg)'
        // }
      },
    }
  }
};

export default MuiTextField;
