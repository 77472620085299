import { Grid, Checkbox } from "@mui/material";

export default function CheckboxesExp() {
  return (
    <Grid item xs={12}>
      Checkbox
      <Grid>
        <Checkbox defaultChecked />
        <Checkbox checked disabled />
      </Grid>
    </Grid>
  );
}
