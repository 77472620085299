import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { changePasswordStatus, cleanUpStatus } from './ChangePasswordSlice';
import { LoadStatus } from '../../../app/entities/IAppLoadStatus';

import vars from "../../../styles/_variables.module.scss";
import { rem } from '../../muiTheme/helpers';
import "../index.scss";
import { logout } from '../../../features/Login/authSlice';
import AppSnackbar from '../../components/AppSnackbar';
import ChangePassword from './ChangePassword';
import Divider from '@mui/material/Divider';

export default function UserActions() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
 
    const status = useAppSelector(changePasswordStatus);
    const success = status === LoadStatus.Success;
    const company = useAppSelector((state) => state.auth.user);

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const open = Boolean(anchorEl);

    const onOpen = (value: boolean) => {
        setOpenDialog(value);
    }

    const handleClick = (event: React.BaseSyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLogout = () => {
        dispatch(logout());
    };

    const onChangePassword = () => {
        onOpen(true);
        handleClose();
    };

    return (
        <>
        <AppSnackbar
          open={success}
          message={"snackbarMessages.successChangePassword"}
          handleClose={() => dispatch(cleanUpStatus())}
        />

        <Grid item sx={{ width: rem(55), height: rem(55) }}>
            <Button sx={{ padding: 0, borderRadius: '50%' }} className="avatarButton" onClick={handleClick}>
                <Avatar alt="img" src="/_mock/avatar.png" />
            </Button>
            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 10px rgba(0,0,0,0.10))',
                    backgroundColor: '#FCFCFD',
                    mt: 1.5,
                    '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 25,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Typography sx={{m:1, ml:2}} variant ="subtitle1"  fontWeight="bold">{company?.name}</Typography>
                <Divider />
                <MenuItem onClick={onChangePassword}>
                <Typography
                    variant="body2"
                    color={vars['colors-secondary']}
                >
                    { t('mainLayout.changePassword') }</Typography>
                </MenuItem>
                <MenuItem onClick={onLogout}>
                <Typography
                    variant="body2"
                    color={vars['colors-secondary']}
                >
                    { t('mainLayout.logout') }</Typography>
                </MenuItem>
            </Menu>
        </Grid>
        <ChangePassword openDialog={openDialog} onOpen={onOpen} />
        </>
    );
}
