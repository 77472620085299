import { useState, useEffect, useImperativeHandle, forwardRef, ReactElement, ForwardedRef } from "react";
import clsx from 'clsx';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import { IAppTable, ISortDirection, ITableSize } from "../../entities/IAppTable";
import TableRowWrapper from "./RowWrapper";
import TableHeader from "./TableHeader";
import AppTablePagination from "./AppTablePagination";
import './index.scss';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { LoadStatus } from "../../entities/IAppLoadStatus";
import { changeTablesFilters, cleanUpTablesFilters } from "../../../features/TablesSaver/tableSaverSlice";
import { AppState, IAppGlobalState, StateKeys } from "../../store";

interface ITableProps {
    saveState?: boolean;
    name: string;
    loadData: (value: IAppTable.ILoadData) => void;

    header: IAppTable.IHeader;
    pagination?: IAppTable.IPagination;

    Component: <U extends IAppTable.IComponent<IAppTable.RowItems>>(props: U) => ReactElement | JSX.Element;
    ComponentProps?: IAppTable.IComponentProps;
    onAction?: (action: string, value: IAppTable.RowItems, event?: React.BaseSyntheticEvent) => void;

    className?: string;
    size?: ITableSize;
    empty: boolean;

    TotalComponent?: () => ReactElement | JSX.Element;
}

const AppTable = forwardRef((props: ITableProps, ref: ForwardedRef<unknown>) => {
    const {name, loadData, saveState = false} = props;
    const {className = ''} = props;
    const { pagination, header = false, empty, TotalComponent } = props;
    const { Component, ComponentProps = {}, onAction } = props;

    const PAGINATION_DEFAULT_ROWS_PER_PAGE = [10, 20, 30, 50];

    const [forceLoadData, setForceLoadData] = useState(false);

    const dispatch = useAppDispatch();

    const tableState = useAppSelector((state: AppState) => ((state as AppState)[name as StateKeys] as IAppGlobalState)?.status);
    const totalCount = useAppSelector((state: AppState) => ((state as AppState)[name as StateKeys] as IAppGlobalState)?.totalCount);
    const totalPages = useAppSelector((state: AppState) => ((state as AppState)[name as StateKeys] as IAppGlobalState)?.totalPages);
    const rows = useAppSelector((state: AppState) => ((state as AppState)[name as StateKeys] as IAppGlobalState)?.data);
    const errorMessage = useAppSelector((state: AppState) => ((state as AppState)[name as StateKeys] as IAppGlobalState)?.errors);

    const [options, setOptions] = useState(() => {
        const initialOptions = {pagination: {}, sorting: {}};

        const rowsPerPageOptions = pagination? pagination.rowsPerPageOptions : PAGINATION_DEFAULT_ROWS_PER_PAGE;
        initialOptions.pagination = {page: 0, perPage: (rowsPerPageOptions as Array<number>)[0] };
        
        if (header) {
            (initialOptions.sorting as IAppTable.IHeader).sortBy = header.sortBy || header.defaultSortBy || '';
            (initialOptions.sorting as IAppTable.IHeader).direction = header.direction || ISortDirection.asc;

            (initialOptions.sorting as IAppTable.IHeader).stringAsc = header.stringAsc || '';
            (initialOptions.sorting as IAppTable.IHeader).stringDesc = header.stringDesc || '';

            Object.keys(header.columns).forEach((columnName) => {
                const value = header.columns[columnName];
                if(value?.filter?.default) {
                    (initialOptions.sorting as IAppTable.ISortingOptions)[columnName] = value.filter.default;
                }
            });
        }

        dispatch(changeTablesFilters(Object.assign({}, {}, {[name]: initialOptions })));
        return initialOptions;
    });

    useImperativeHandle(ref, () => ({
        reload() {
            loadTableData(); //use after delete or add etc.
        }
    }));

    useEffect(() => {    
        loadTableData();

        return () => {
            if(saveState) dispatch(cleanUpTablesFilters());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {     
        if(saveState) {
            dispatch(changeTablesFilters(Object.assign({}, {}, {[name]: options })));
        }   
        if (forceLoadData || tableState === LoadStatus.Success) {
            setForceLoadData(false);
            loadTableData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const loadTableData = async () => {
        await loadData(options as IAppTable.ILoadData);
    };


    const doPaging = (paginationOptions: IAppTable.IPagination) => {
        const newOptions = {...options};
        newOptions.pagination = paginationOptions;
        setOptions(newOptions);
    };

    const doSorting = (sortingOptions: IAppTable.ISortingOptions) => {
        const newOptions = {...options};
        newOptions.sorting = sortingOptions;
        newOptions.pagination = {
            ...newOptions.pagination,
            page: 0
        }

        setOptions(newOptions);
    };

    const doFilter = (sortingOptions: IAppTable.IOptionFilter) => {
        const newOptions = {...options};
        newOptions.sorting = sortingOptions;
        newOptions.pagination = {
            ...newOptions.pagination,
            page: 0
        }

        setOptions(newOptions);
    };

    let paginationComponent;
    if (totalCount > 0) {
        const rowsPerPageOptions = pagination && Boolean(pagination.rowsPerPageOptions) ? pagination.rowsPerPageOptions : PAGINATION_DEFAULT_ROWS_PER_PAGE;

        paginationComponent = (
            <AppTablePagination 
                totalCount={totalCount}
                totalPages={totalPages}
                options={options.pagination} 
                rowsPerPageOptions={rowsPerPageOptions as Array<number>}
                doPaging={doPaging} />
        );
    }

    let headerColumnsCount = 0;
    let headerComponent;
    if (header && (tableState === LoadStatus.Loading || tableState === LoadStatus.Success)) {
        headerColumnsCount = Object.keys(header.columns).length;
        headerComponent = 
            <TableHeader 
                columns={header.columns} 
                options={options.sorting}
                doSorting={doSorting} 
                tableState={tableState} 
                doFilter={doFilter}
            />
    }
    if(empty) return null;

    return (
        <>
            <TableContainer>
                <Table 
                    className={clsx('appTable', className && className)}
                    size={props.size || "medium"}>
                    { headerComponent }
                    <TableBody className='appTableBody'>
                        <TableRowWrapper 
                            name={name}
                            items={rows}
                            Component={Component}
                            ComponentProps={ComponentProps}
                            state={tableState}
                            onAction={onAction}
                            error={errorMessage as string}
                            TotalComponent={TotalComponent}
                            columnsCount={headerColumnsCount} />
                    </TableBody>
                </Table>
            </TableContainer>
            { paginationComponent }
        </>
    );
});

export default AppTable;
