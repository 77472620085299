import { useState } from "react";

import Box from "@mui/material/Box";
import { Menu } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import { Filter, IAppTable, ISortDirection } from "../../entities/IAppTable";
import { LoadStatus } from "../../entities/IAppLoadStatus";
import AppTextFilter from "./AppTableFilter/AppTextFilter";

import { rem } from '../../muiTheme/helpers';
import AppNumberFilter from "./AppTableFilter/AppNumberFilter";
import AppDateFilter from "./AppTableFilter/AppDateFilter";

interface IProps {
    columns: IAppTable.IColumns;
    options: IAppTable.ISortingOptions;
    doSorting: (value: IAppTable.ISortingOptions) => void;
    doFilter: (value: IAppTable.IOptionFilter) => void;
    tableState: LoadStatus;
}

const TableHeader = (props: IProps) => {
    const { options, columns, doSorting, tableState, doFilter } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = (event: React.BaseSyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const sort = (event: React.BaseSyntheticEvent, column: string, direction: ISortDirection) => {
        const newOptions = { ...options };
        newOptions.direction = direction;

        const isASC = direction === ISortDirection.asc;
        const nameForNull = isASC ? "stringDesc" : "stringAsc";
        const keyName = isASC ? "stringAsc" : "stringDesc";
    
        newOptions[nameForNull] = "";

        if(newOptions[keyName] === column) {
            newOptions[keyName] = "";
        } else {
            newOptions[keyName] = column;
        }

        doFilter(newOptions);
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    }

    const doMinMaxFilter = (event: React.BaseSyntheticEvent, min: string, max: string, column: string) => {
        const newOptions = { ...options };
        newOptions[column] = {};
        if(newOptions[column].min || newOptions[column].max || !min || !max) {
            newOptions[column].min = '';
            newOptions[column].max = '';
        } else {
            newOptions[column].min = min;
            newOptions[column].max = max;
        }

        doFilter(newOptions);
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    }

    const doSearchFilter = (event: React.BaseSyntheticEvent, value: string, column: string) => {
        const newOptions = { ...options };
        newOptions[column] = {};
        if(newOptions[column].searchValue || !value) {
            newOptions[column].searchValue = "";
        } else {
            newOptions[column].searchValue = value;
        }

        doFilter(newOptions);
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    }

    const sortByColumn = (column: string) => {
        const newOptions = { ...options };
        if (column === newOptions.sortBy) {
            newOptions.direction = options.direction === ISortDirection.asc ? ISortDirection.desc : ISortDirection.asc;
        } else {
            newOptions.sortBy = column;
            newOptions.direction = ISortDirection.asc;
        }

        doSorting(newOptions);
    };

    const checkSortFilter = (event: React.BaseSyntheticEvent, index: number, column: string, sortable: boolean, filterType: IAppTable.IColumnFilter | undefined) => {
        if(sortable) {
            sortByColumn(column);
        }
        if(filterType) {
            setAnchorEl({ [index]: event.currentTarget } as never);
        }
    }

    const columnsList = Object.keys(columns).map((column, index) => {
        const sortable = typeof (columns[column].sortable) === 'undefined' ? false : columns[column].sortable;
        const filter = columns[column].filter;

        const hasFilter = () => {
            if (options[column]) {
                return Object
                    .values(options[column])
                    .filter(item => Boolean(item))
                    .length > 0;
            } else {
                return false;
            }
        }
        
        return (
            <TableCell key={index} align={columns[column].align || 'center'} className="appTableCell capitalize">
                <TableSortLabel
                    className='headerLabel'
                    disabled={tableState === LoadStatus.Loading || (!sortable && !filter)} 
                    direction={options.direction} 
                    active={options.sortBy === column}
                    onClick={(event) => checkSortFilter(event,index,column,sortable,filter)}
                >
                    <Box pl={index === 0 ? 2 : 0} pr={index === 6 ? 2 : 0}>{columns[column].label || ''}</Box>
                    {hasFilter() && <img src="/img/filtered.svg" alt="filtered" style={{marginLeft: rem(13) }} />}
                    {filter && <img src="/img/coloredArrow.svg" alt="filter" style={{marginLeft: rem(13) }} />}
                    {sortable && <img src="/img/filterDown.svg" alt="sort" style={{marginLeft: rem(13) }} />}
                    {filter &&
                        <Menu
                            anchorEl={anchorEl && anchorEl[index]}
                            keepMounted
                            open={Boolean(anchorEl && anchorEl[index])}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            transformOrigin={{ vertical: "top", horizontal: "left" }}
                            style={{marginTop: rem(10)}}
                            onClose={handleClose}
                            className="menu"
                        >
                            {(filter.type === Filter.textCheckbox || filter.type === Filter.textSearch) && 
                                <AppTextFilter 
                                    filter={filter} 
                                    sort={(event: React.SyntheticEvent, direction: ISortDirection) => sort(event, column, direction)}
                                    doSearch={doSearchFilter}
                                    options={options}
                                    column={column}
                                />
                            }

                            {filter.type === Filter.number && 
                                <AppNumberFilter
                                    sort={(event: React.SyntheticEvent, direction: ISortDirection) => sort(event, column, direction)}
                                    doMinMaxFilter={doMinMaxFilter}
                                    useGrouping={columns[column]?.filter?.useGrouping}
                                    options={options}
                                    column={column}
                                />
                            }

                            {filter.type === Filter.date && 
                                <AppDateFilter
                                    options={options}
                                    columnName={column}
                                    sort={(event: React.SyntheticEvent, direction: ISortDirection) => sort(event, column, direction)}
                                    doFilter={(_opt:  IAppTable.IOptionFilter, event: React.BaseSyntheticEvent) => {
                                        doFilter(_opt);
                                        event.stopPropagation();
                                        event.preventDefault();
                                        setAnchorEl(null);
                                    }}
                                />
                            }
                        </Menu>
                    }
                </TableSortLabel>
            </TableCell>
        );
    });

    return <TableHead className='appTableHeader'><TableRow>{columnsList}</TableRow></TableHead>;
};

export default TableHeader;