import { useTranslation } from "react-i18next";

import { Typography, Box } from "@mui/material";

import { rem } from '../../app/muiTheme/helpers';
import AppImage from "../../app/components/AppImage";
import { ImageVariant } from "../../app/entities/IAppComponents";

interface IProps {
    orderItems:  {[key: string]: string | number}[];
}

export default function OrderItemsWithAmount(props: IProps) {
    const { orderItems } = props;
    const { t } = useTranslation();

    return (
        <Box>
            <Typography 
            variant="h6" 
            sx={{
                marginBottom: rem(19)
            }}>
                {t('report.orderItems')}
            </Typography>
            {orderItems.map((item: {[key: string]: string | number}, index: number) => {
                return (
                    <Box key={index} sx={{marginBottom: rem(26), display: 'flex'}}>
                        <AppImage src="/_mock/imgMedium.png" variant={ImageVariant.medium} />
                        <Typography 
                        variant="subtitle1" 
                        sx={{
                            maxWidth: rem(200), 
                            wordBreak: 'break-word', 
                            marginLeft: rem(10), 
                            marginRight: rem(30)
                        }}>
                            {item.name}
                        </Typography>
                        <Box 
                        sx={{
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'space-between', 
                            alignItems: 'flex-end'
                        }}>
                            <Typography 
                            variant="subtitle2" 
                            align="right"
                            sx={{
                                wordBreak: 'break-word', 
                                maxWidth: rem(70),
                            }}>
                                {item.price} AMD
                            </Typography>
                            <Typography variant="subtitle1" align="right">{item.count}</Typography>
                        </Box>
                    </Box>
                )
            })}
            
        </Box>
    );
}