import {
  Grid,
  Typography,
} from "@mui/material";

export default function TypagraphyExp() {
  return (
    <Grid
        item
        xs
        container
        flexDirection="column"
        style={{ rowGap: "15px", columnGap: "15px" }}
      >
        <Typography variant="h1">H1</Typography>
        <Typography variant="h2">H2</Typography>
        <Typography variant="h3">H3</Typography>
        <Typography variant="h4">H4</Typography>
        <Typography variant="h5">H5</Typography>
        <Typography variant="h6">H6</Typography>
        <Typography variant="subtitle1">subtitle1</Typography>
        <Typography variant="subtitle2">subtitle2</Typography>
        <Typography variant="body1">body1</Typography>
        <Typography variant="body2">body2</Typography>
        <Typography variant="button">button</Typography>
        <Typography variant="caption">caption</Typography>
        <Typography variant="overline">overline</Typography>
      </Grid>
  );
}
