import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import AppTableRow from "../../../app/components/Table/AppTableRow";
import DeleteButton from "../../../app/components/Table/DeleteButton";
import EditButton from "../../../app/components/Table/EditButton";
import { IRuleItem } from "../../../app/entities/IAppRules";
import { IAppTable } from "../../../app/entities/IAppTable";

interface IProps extends IAppTable.IComponent<IAppTable.RowItems> {
    isLoading: boolean;
    onAction?: (action: string, value: IAppTable.RowItems, event?: React.BaseSyntheticEvent) => void;
  }


const RowExp = (props: IProps) => {
    const { _item, isLoading } = props;
    const item = _item as IRuleItem;

    return (
        <TableRow>
            <TableCell align="left">
                <Box pl={ 2 } className="d-flex align-items-center">
                    <AppTableRow isLoading={ isLoading }>
                        { item && <Box>{ item.name }</Box> }
                    </AppTableRow>
                </Box>
            </TableCell>
            <TableCell align="left">
                <AppTableRow isLoading={ isLoading }>
                    { item && <Box>{ item.addresses }</Box> }
                </AppTableRow>
            </TableCell>
            <TableCell align="right">
                <AppTableRow isLoading={ isLoading }>
                    {item && <Box className='tableActions'>
                        <DeleteButton onClick={ (event: React.BaseSyntheticEvent) => props.onAction && props.onAction('remove', item, event) } />
                        <EditButton onClick={ (event: React.BaseSyntheticEvent) => props.onAction && props.onAction('edit', item, event) } />
                    </Box>}
                </AppTableRow>
            </TableCell>
        </TableRow>
    );
};

export default RowExp;