import vars from "../../styles/_variables.module.scss";
import { rem } from "./helpers";

const MuiSwitch: { MuiSwitch: object } = {
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: rem(36),
        height: rem(20),
        padding: 0,
      },
      switchBase: {
        padding: rem(1),
        color: vars["colors-secondary_2"],
        "&.Mui-checked": {
          transform: `translateX(${rem(15)})`,
          color: vars["colors-primary"],
          "& + .MuiSwitch-track": {
            backgroundColor: vars["colors-primary_4"],
            opacity: 1,
            borderRadius: rem(60),
          },
          "&.Mui-disabled": {
            color: vars["colors-light_color"],
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.7,
          },
        },
      },
      track: {
        backgroundColor: vars["colors-secondary_4"],
        opacity: 1,
        borderRadius: rem(60),
      },
      thumb: {
        boxSizing: "border-box",
        width: 18,
        height: 18,
        boxShadow: "none",
      },
    },
  },
};

export default MuiSwitch;
