import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IAllResponseData } from "../../app/entities";

import { LoadStatus } from "../../app/entities/IAppLoadStatus";
import { ERulesStatus, IRule, IRuleItem } from "../../app/entities/IAppRules";
import { IAppTable } from "../../app/entities/IAppTable";
import { RootState } from "../../app/store";
import { addEditRule, getAllRules, getRules, removeRule, upDateRuleStatus } from "./ruleAPI";

export interface RuleState {
  data?: Array<IRuleItem>;
  status: LoadStatus;
  pageStatus: LoadStatus;
  deleteStatus: LoadStatus;
  addEditStatus: LoadStatus;
  changeStatus: LoadStatus;
  totalCount: number;
  totalPages: number;
  allRules: Array<IAllResponseData>;
  allRulesStatus: LoadStatus;
}

const initialState: RuleState = {
  status: LoadStatus.Idle,
  pageStatus: LoadStatus.Idle,
  deleteStatus: LoadStatus.Idle,
  addEditStatus: LoadStatus.Idle,
  changeStatus: LoadStatus.Idle,
  totalCount: 0,
  totalPages: 0,
  allRulesStatus: LoadStatus.Idle,
  allRules: []
};

export interface IResponse {
  payload: {
    data: Array<IRuleItem>;
    totalPages: number;
    totalElements: number;
    page?: number;
    perPage?: number;
    status?: number;
  };
}

export const getRulesAsync = createAsyncThunk(
  "rule/get",
  async (requestData: IAppTable.IPaginationOptions) => {
    const response = await getRules(requestData);
    return response?.data;
  }
);

export const removeRuleAsync = createAsyncThunk(
  "rule/delete",
  async (id: string) => {
    const response = await removeRule(id);
    return response.data;
  }
);

export const upDateRuleStatusAsync = createAsyncThunk(
  "rule/upDate",
  async (id: string) => {
    await upDateRuleStatus(id);
    return id;
  }
);

export const addEditRuleAsync = createAsyncThunk(
  "rule/addEdit",
  async (data: IRule, { rejectWithValue }) => {
    const response = await addEditRule(data, rejectWithValue);
    return response;
  }
);

export const getAllRulesAsync = createAsyncThunk(
  'rules/getAll',
  async () => {
      const response = await getAllRules();
      return response?.data;
  }
);

export const ruleSlice = createSlice({
  name: "rule",
  initialState,
  reducers: {
    cleanUpRules: () => {
      return initialState;
    },
    cleanUpStatuses: (state) => {
      state.addEditStatus = initialState.addEditStatus;
      state.changeStatus = initialState.changeStatus;
      state.deleteStatus = initialState.deleteStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRulesAsync.rejected, (state: RuleState) => {
        state.status = LoadStatus.Failed;
        state.pageStatus = LoadStatus.Failed;
      })
      .addCase(getRulesAsync.pending, (state: RuleState) => {
        if (state.data) {
          state.status = LoadStatus.Loading;
        } else {
          state.pageStatus = LoadStatus.Loading;
        }
      })
      .addCase(getRulesAsync.fulfilled, (state: RuleState, action: IResponse) => {
          const payload = action.payload;

          if (payload.totalElements === 0) {
            state.pageStatus = LoadStatus.Success;
          } else {
            state.status = LoadStatus.Success;
            state.pageStatus = LoadStatus.Idle;
          }

          state.data = payload.data;
          state.totalPages = payload.totalPages;
          state.totalCount = payload.totalElements;
        }
      )
      .addCase(removeRuleAsync.rejected, (state: RuleState) => {
        state.deleteStatus = LoadStatus.Failed;
      })
      .addCase(removeRuleAsync.pending, (state: RuleState) => {
        state.deleteStatus = LoadStatus.Loading;
      })
      .addCase(removeRuleAsync.fulfilled, (state: RuleState) => {
        state.deleteStatus = LoadStatus.Success;
      })
      .addCase(upDateRuleStatusAsync.rejected, (state: RuleState) => {
        state.changeStatus = LoadStatus.Failed;
      })
      .addCase(upDateRuleStatusAsync.pending, (state: RuleState) => {
        state.changeStatus = LoadStatus.Loading;
      })
      .addCase(upDateRuleStatusAsync.fulfilled, (state: RuleState, action: { payload: string }) => {
        state.changeStatus = LoadStatus.Success;

        if(state.data) {
          const id = action.payload;
          state.data = state.data.map((item) => {
              if(item.id === id) {
                  item.status = item.status === ERulesStatus.ACTIVE ? ERulesStatus.INACTIVE : ERulesStatus.ACTIVE;
              }
              return item;
          })
        }
      })
      .addCase(addEditRuleAsync.rejected, (state: RuleState) => {
        state.addEditStatus = LoadStatus.Failed;
        // const newErrors = action.payload.errors as IError;
        // state.errors = {
        //   [newErrors.param]: newErrors.error
        // };
      })
      .addCase(addEditRuleAsync.pending, (state: RuleState) => {
        state.addEditStatus = LoadStatus.Loading;
        // state.errors = {};
      })
      .addCase(addEditRuleAsync.fulfilled, (state: RuleState) => {
        state.addEditStatus = LoadStatus.Success;
        state.pageStatus = LoadStatus.Idle;
      })
      .addCase(getAllRulesAsync.rejected, (state: RuleState) => {
        state.allRulesStatus = LoadStatus.Failed;
        state.allRules = [];
      })
      .addCase(getAllRulesAsync.pending, (state: RuleState) => {
          state.allRulesStatus = LoadStatus.Loading;
          state.allRules =[];
      })
      .addCase(getAllRulesAsync.fulfilled, (state: RuleState, action: { payload: { data: Array<IAllResponseData> }}) => {
          state.allRulesStatus = LoadStatus.Success;
          state.allRules = action.payload.data;
      });
  },
});

export const { cleanUpRules, cleanUpStatuses } = ruleSlice.actions;

export const rulesData = (state: RootState) => state.rule.data;
export const rulesStatus = (state: RootState) => state.rule.status;
export const rulesPageStatus = (state: RootState) => state.rule.pageStatus;

export const deleteRuleStatus = (state: RootState) => state.rule.deleteStatus;
export const addEditRuleStatus = (state: RootState) => state.rule.addEditStatus;
export const statusChangeStatus = (state: RootState) => state.rule.changeStatus;

export const allRulesData = (state: RootState) => state.rule.allRules;
export const allRulesStatus = (state: RootState) => state.rule.allRulesStatus;

export default ruleSlice.reducer;