import IAppRoute from "./entities/IAppRoute";

const AppRoutes: {[key: string]: IAppRoute} = {
    OrderRule: {
        name: 'Order Rule',
        path: '/rules',
        icon: '/img/mealAllowance.svg',
        fullPath: '/rules',
    },
    Group: {
        name: 'Group',
        path: '/groups',
        icon: '/img/group.svg',
        fullPath: '/groups',
    },
    GroupMembers: {
        name: 'Members',
        path: '/groups/members/:id',
        icon: '/img/payment.svg',
        fullPath: '/groups/members',
        isChild: true,
    },
    Address: {
        name: 'Address',
        path: '/address',
        icon: '/img/address.svg',
        fullPath: '/address',
    },
    PaymentType: {
        name: 'Payment Type',
        path: '/paymentTypes',
        icon: '/img/payment.svg',
        fullPath: '/paymentTypes',
    },
    Partners: {
        name: 'Partners',
        path: '/restaurants',
        icon: '/img/customer.svg',
        fullPath: '/restaurants',
    },
    Reporting: {
        name: 'Reporting',
        path: '/reports',
        icon: '/img/reports.svg',
        fullPath: '/reports',
    },
    ReportingOrders: {
        name: 'Orders',
        path: ':id/orders',
        icon: '/img/reporting.svg',
        fullPath: '/reports/:id/orders',
        isChild: true,
    }
};

export default AppRoutes;
