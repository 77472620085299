import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { LoadStatus } from "../../../app/entities/IAppLoadStatus";
import { IChangePasswordErrors, IChangePasswordProps } from "../../entities/IAppChangePassword";
import { IError, IResponseDataErrors } from "../../entities/IAppError";
import { RootState } from "../../store";
import { passwordChange } from "./ChangePasswordAPI";

export interface ChangePasswordState {
    status: LoadStatus;
    errors: IChangePasswordErrors;
}

const initialState: ChangePasswordState = {
    status: LoadStatus.Idle,
    errors: {},
};

export interface ChangePasswordResponse {
    errors?: IError;
}

export const changePasswordAsync = createAsyncThunk(
    'changePassword/do',
    async (data: IChangePasswordProps, { rejectWithValue }) => {
      const response = await passwordChange(data, rejectWithValue);
      return response;
    }
);

export const changePassword= createSlice({
    name: 'changePassword',
    initialState,
    reducers: {
        cleanUpStatus: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(changePasswordAsync.rejected, (state: ChangePasswordState, { payload  }) => {
                state.status = LoadStatus.Failed;
                const newErrors = (payload as ChangePasswordResponse).errors as IError;
                if (!newErrors.errors) {
                    const responceErrors = (
                      payload as { errors: IResponseDataErrors }
                    ).errors
                    const field = responceErrors.message.includes("old")
                      ? "oldPassword"
                      : "newPassword"
                    newErrors.errors = [
                      {
                        field,
                        message: "Something went wrong:" + responceErrors.message ,
                      },
                    ]
                  }
                newErrors.errors.map((error: {[key: string]: string}) => {
                    return (
                        state.errors = {
                            [error.field]: error.message
                        }
                    )
                })
            })
            .addCase(changePasswordAsync.pending, (state: ChangePasswordState) => {
                state.status = LoadStatus.Loading;
                state.errors = {};
            })
            .addCase(changePasswordAsync.fulfilled, (state: ChangePasswordState) => {
                state.status = LoadStatus.Success;
        })
    },
});

export const { cleanUpStatus } = changePassword.actions;

export const changePasswordStatus = (state: RootState) => state.changePassword.status;
export const changePasswordErrors = (state: RootState) => state.changePassword.errors;

export default changePassword.reducer;