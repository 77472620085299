import vars from "../../styles/_variables.module.scss";
import { rem } from "./helpers";

const MuiAvatar: {MuiAvatar : object} = {
  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        width: rem(46), 
        height: rem(46),
        backgroundColor: vars['colors-primary_1'],
        color: vars['colors-light_color'],
        border: 'none',
        fontSize: rem(20),
        fontWeight: '900',
      },
      circular: {
        width: rem(55), 
        height: rem(55),
        border: `5px solid ${vars['colors-secondary_5']}`,
      },
      root: {
        ":first-of-type": {
          zIndex: "2",
        },
        ":last-child": {
          zIndex: "0",
          backgroundColor: vars['colors-primary_2'],
        },
        ":nth-of-type(2)": {
          backgroundColor: vars['colors-primary_3'],
          zIndex: "1",
        }
      }
    },
  },
};

export default MuiAvatar;
