import { Box, Grid } from "@mui/material";

import AppImage from "../../app/components/AppImage";
import { ImageVariant } from "../../app/entities/IAppComponents";
import AvatarsExp from "./Avatar";
import ButtonsExp from "./Buttons";
import CheckboxesExp from "./Checkbox";
import ChipsExp from "./Chips";
import DialogExp from "./Dialogs";
import IconsExp from "./Icons";
import InputsExp from "./Input";
import NumberFieldExp from "./NumberField";
import PhoneNumberInput from "./PhoneNumberInput";
import RadioButtonsExp from "./RadioButton";
import SwitchsExp from "./Switch";
import TypagraphyExp from "./Typagraphy";
import SelectedFileList from './SelectedFileList';
import CheckoutItem from "./CheckoutItem";
import RangeDatePickerExp from "./RangeDatePicker";
import TableExp from "./Table";
import EmptyTableWrapper from "./EmptyTable";
import OrderItemsWithAmountExp from "./OrderItemsWithAmount";
import DaysOfTheWeek from "./DaysOfTheWeek";
import OrderAmountInfoExp from "./OrderAmountInfo";
import OrderTitleExp from "./OrderTitle";
import ShopInfoExp from "./ShopInfo";
import OrderIntervalExp from "./OrderInterval";
import AppSnackbarExp from "./AppSnackbar";

export default function DesignComponents() {

  return (
    <Grid
      container
      style={{ rowGap: "15px", columnGap: "15px", padding: "0 20px" }}
    >
      <Grid item xs={12}>
        DesignComponents
      </Grid>
      
      <TypagraphyExp />
      
      <Grid item xs container>
        <Grid item>
          <AppImage src="/_mock/img.png" />
          <AppImage src="/_mock/imgMedium.png" variant={ImageVariant.medium} />
        </Grid>
      </Grid>

      <Grid item xs container>
        <ButtonsExp />
      </Grid>

      <Grid item xs container>
        <IconsExp />
      </Grid>

      <Grid item xs container>
        <ChipsExp />
      </Grid>

      <Grid item xs container>
        <AvatarsExp />
      </Grid>

      <Grid item xs container>
        <SwitchsExp />
      </Grid>

      <Grid item xs container>
        <CheckboxesExp />
      </Grid>
      
      <Grid item xs container>
        <RadioButtonsExp />
      </Grid>

      <Grid item xs container>
        <InputsExp />
      </Grid>

      <Grid container>
        <Grid item>
          <DialogExp />
        </Grid>
      </Grid>
      
      <Grid container>
        <Grid item>
          <NumberFieldExp />
        </Grid>
        <Grid item>
          <PhoneNumberInput />
        </Grid>
        <Grid item xs={3}>
          <SelectedFileList />
        </Grid>
        <Grid item xs={12} md={3}>
          <CheckoutItem />
          {/* <AppTableFilter /> */}
        </Grid>
        <Grid item>
          <RangeDatePickerExp />
        </Grid>
        <Grid item xs={12}>
          <EmptyTableWrapper />
        </Grid>
      </Grid>
      
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ my: 10}}>
            <TableExp />
          </Box>
        </Grid>
      </Grid>

      <OrderItemsWithAmountExp />
      <DaysOfTheWeek />
      <OrderAmountInfoExp />
      <Grid container>
        <Grid item xs={6}>
          <OrderTitleExp />
        </Grid>
      </Grid>
      <ShopInfoExp />
      <OrderIntervalExp />
      <AppSnackbarExp />
    </Grid>
  );
}
