import { Grid } from "@mui/material";

import CheckoutItem from "../../app/components/CheckoutItem";

export default function CheckoutItemExp() {
    const handleCheckout = () => {
        // do nothing.
    }

    return(
        <>
            <Grid item>
                <CheckoutItem 
                iconSrc="/img/mobile.svg" 
                title="By Phone Number" 
                checkout={handleCheckout}
            />
            </Grid>
            <Grid item>
                <CheckoutItem 
                iconSrc="/img/upload.svg" 
                title="Upload CSV file" 
                checkout={handleCheckout} 
                />
            </Grid>
        </>
    );
}