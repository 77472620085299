import { useTranslation } from "react-i18next";

import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { DialogType } from "../../entities/IAppComponents";
import AppDialogTitle from "./AppDialogTitle";
import AppDialogActions from "./AppDialogActions";
import { rem } from "../../muiTheme/helpers";

export interface IDialogProps extends DialogProps {
  variant?: DialogType;
  title: string;
  isSmall?: boolean;
  content: string | React.ReactElement | Array<React.ReactElement>;
  onCancel?: React.ReactElement;
  confirmName?: string;
  loading?: boolean | React.ReactElement;
  disabled?: boolean;
  subtitle?: string | React.ReactElement;
  onConfirm?: () => void;
  onCloseDialog: (refresh?: boolean) => void;
  hideCloseButton?: boolean;
}

export default function DialogWrapper(props: IDialogProps) {
  const {
    open,
    variant = DialogType.standart,
    title,
    content,
    isSmall = false,
    confirmName,
    loading,
    disabled,
    onConfirm,
    onCloseDialog,
    subtitle,
    hideCloseButton = false
  } = props;
  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isDelete = variant === DialogType.delete;
  const isNotIntermediate = variant !== DialogType.intermediate;

  const handleClose = (event: any, reason: string) => {
    if ( reason === "backdropClick" || reason === "escapeKeyDown") {
      return false;
    }

    onCloseDialog()
  }

  if(!open) return null;

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      fullWidth={true}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: isSmall ? "736px" : "574px"
          },
        },
      }}
      onClose={handleClose}
      TransitionComponent={Slide}
    >
      <AppDialogTitle variant={variant} onClose={onCloseDialog} hideCloseButton={hideCloseButton} isSmall={isSmall}>
        {title}
        {subtitle && <Typography variant="h6" sx={{marginTop: rem(10)}}>{subtitle}</Typography>}
      </AppDialogTitle>

      <DialogContent
        sx={{
          paddingBottom: isNotIntermediate ? 0 :  rem(40),
          paddingLeft: isSmall ? `${rem(20)} !important` : '' ,
          paddingRight: isSmall ? `${rem(20)} !important` : ''
        }}
      >
        {isDelete &&
          <Typography
            variant="subtitle1"
            className="space"
            sx={{display: 'inline-block'}}
          >
          {`${t("dialog.content")} `}
          <b>&nbsp;{title} &nbsp;</b>
          </Typography>}
        { content }
      </DialogContent>

      <AppDialogActions
        hideCloseButton={hideCloseButton}
        variant={variant}
        confirmName={confirmName}
        disabled={disabled}
        loading={loading}
        isSmall={isSmall}
        onConfirm={onConfirm}
        onClose={onCloseDialog}
      />
    </Dialog>
  );
}
