import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAllResponseData } from "../../app/entities";
import { IAddress, IAddressItem, IRequestAddressSuggest, IRequestGetAddressByLocation, IRequestOutOfZone } from "../../app/entities/IAddress";
import { LoadStatus } from "../../app/entities/IAppLoadStatus";
import { IAppTable } from "../../app/entities/IAppTable";
import { RootState } from "../../app/store";
import { addEditAddress, addressList, allAddress, checkOutOfZoneAddress, getAddressFromLocation, getSuggestAddressList, removeAddress } from "./addressAPI";

export interface AddressState {
    data?: Array<IAddressItem>;
    status: LoadStatus;
    pageStatus: LoadStatus;
    deleteStatus: LoadStatus;
    addEditStatus: LoadStatus;
    totalCount: number;
    suggestStatus: LoadStatus;
    allAddresses: Array<IAllResponseData>;
    totalPages: number;
}

const initialState: AddressState = {
    status: LoadStatus.Idle,
    pageStatus: LoadStatus.Idle,
    deleteStatus: LoadStatus.Idle,
    addEditStatus: LoadStatus.Idle,
    totalCount: 0,
    totalPages: 0,
    suggestStatus: LoadStatus.Idle,
    allAddresses: []
};

export interface IResponse {
    payload: {
      data: Array<IAddressItem>;
      totalPages: number;
      totalElements: number;
      page?: number;
      perPage?: number;
      status?: number;
    }
}

export const getAddressesAsync = createAsyncThunk(
    'address/get',
    async (requestData: IAppTable.IPaginationOptions) => {
        const response = await addressList(requestData);
        return response?.data;
    }
);

export const removeAddressAsync = createAsyncThunk(
    'address/delete',
    async (id: string) => {
        const response = await removeAddress(id);
        return response.data;
    }
);

export const addEditAddressAsync = createAsyncThunk(
    'address/addEdit',
    async (data: IAddress, { rejectWithValue }) => {
        const response = await addEditAddress(data, rejectWithValue);
        return response;
    }
);

//HELPERS

export const getAllAddressesAsync = createAsyncThunk(
    'address/getAll',
    async () => {
        const response = await allAddress();
        return response?.data;
    }
);

export const getAddressFromLocationAsync = createAsyncThunk(
    'address/getLocation',
    async (data: IRequestGetAddressByLocation) => {
        const response = await getAddressFromLocation(data);
        return response.data;
    }
);

export const getSuggestAddressListAsync = createAsyncThunk(
    'address/getSuggestAddressList',
    async (data: IRequestAddressSuggest) => {
        const response = await getSuggestAddressList(data);
        return response.data;
    }
);

export const checkOutOfZoneAddressAsync = createAsyncThunk(
    'address/checkOutOfZoneAddress',
    async (data: IRequestOutOfZone) => {
        const response = await checkOutOfZoneAddress(data);
        return response.data;
    }
);

export const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        cleanUpAddresses: () => {
            return initialState;
        },
        cleanUpStatuses: (state) => {
            state.addEditStatus = initialState.addEditStatus;
            state.deleteStatus = initialState.deleteStatus;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAddressesAsync.rejected, (state: AddressState) => {
                state.status = LoadStatus.Failed;
                state.pageStatus = LoadStatus.Failed;
              })
            .addCase(getAddressesAsync.pending, (state: AddressState) => {
                if(state.data) {
                    state.status = LoadStatus.Loading;
                } else {
                    state.pageStatus = LoadStatus.Loading;
                }
            })
            .addCase(getAddressesAsync.fulfilled, (state: AddressState, action: IResponse) => {
                const payload = action.payload;

                if(payload.totalElements === 0) {
                    state.pageStatus = LoadStatus.Success;
                } else {
                    state.status = LoadStatus.Success;
                    state.pageStatus = LoadStatus.Idle;
                }

                state.data = payload.data;
                state.totalPages = payload.totalPages;
                state.totalCount = payload.totalElements;
            })
            .addCase(removeAddressAsync.rejected, (state: AddressState) => {
                state.deleteStatus = LoadStatus.Failed;
            })
            .addCase(removeAddressAsync.pending, (state: AddressState) => {
                state.deleteStatus = LoadStatus.Loading;
            })
            .addCase(removeAddressAsync.fulfilled, (state: AddressState) => {
                state.deleteStatus = LoadStatus.Success;
            })
            .addCase(addEditAddressAsync.rejected, (state: AddressState) => {
                state.addEditStatus = LoadStatus.Failed;
                // const newErrors = action.payload.errors as IError;
                // state.errors = {
                //   [newErrors.param]: newErrors.error
                // };
              })
            .addCase(addEditAddressAsync.pending, (state: AddressState) => {
                state.addEditStatus = LoadStatus.Loading;
                // state.errors = {};
            })
            .addCase(addEditAddressAsync.fulfilled, (state: AddressState) => {
                state.addEditStatus = LoadStatus.Success;
                state.pageStatus = LoadStatus.Idle;
            })
            .addCase(getSuggestAddressListAsync.rejected, (state: AddressState) => {
                state.suggestStatus = LoadStatus.Failed;
              })
            .addCase(getSuggestAddressListAsync.pending, (state: AddressState) => {
                state.suggestStatus = LoadStatus.Loading;
            })
            .addCase(getSuggestAddressListAsync.fulfilled, (state: AddressState) => {
                state.suggestStatus = LoadStatus.Success;
            })
            .addCase(getAllAddressesAsync.rejected, (state: AddressState) => {
                state.allAddresses = [];
            })
            .addCase(getAllAddressesAsync.pending, (state: AddressState) => {
                state.allAddresses = [];
            })
            .addCase(getAllAddressesAsync.fulfilled, (state: AddressState, action: { payload:  Array<IAllResponseData> }) => {
                state.allAddresses = action.payload;
            });
    },
});

export const { cleanUpAddresses, cleanUpStatuses } = addressSlice.actions;

export const addressData = (state: RootState) => state.address.data;
export const addressStatus = (state: RootState) => state.address.status;
export const addressPageStatus = (state: RootState) => state.address.pageStatus;

export const deleteAddressStatus = (state: RootState) => state.address.deleteStatus;
export const addEditAddressStatus = (state: RootState) => state.address.addEditStatus;
export const suggestStatus= (state: RootState) => state.address.suggestStatus;

export const allAddressData= (state: RootState) => state.address.allAddresses;

export default addressSlice.reducer;
