import { Grid, Chip } from "@mui/material";

export default function ChipsExp() {
  return (
    <Grid item xs={12}>
      Chips
      <Grid style={{ marginBottom: "10px" }}>
        <Chip label="Hey I am chip" color="success" />
      </Grid>
      <Grid style={{ marginBottom: "10px" }}>
        <Chip label="Hey I am chip" />
      </Grid>
      <Grid style={{ marginBottom: "10px" }}>
        <Chip label="Hey I am chip" color="info" />
      </Grid>
      <Grid style={{ marginBottom: "10px" }}>
        <Chip label="Hey I am chip" color="error" />
      </Grid>
      <Grid>
        <Chip label="Hey I am chip" color="primary" />
      </Grid>
    </Grid>
  );
}
