import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
    MenuItem,
    // Divider,
    Typography,
} from "@mui/material";

import AppDateRangePicker from "../../DateRangePIcker";
import '../index.scss';
import { rem } from "../../../muiTheme/helpers";
import { requestTimeFormat } from "../../../date_format";
// import vars from "../../../../styles/_variables.module.scss";
import { IAppTable, ISortDirection } from "../../../entities/IAppTable";

interface IProps {
    columnName: string;
    options: IAppTable.IOptionFilter;
    doFilter: (value: IAppTable.IOptionFilter, event: React.BaseSyntheticEvent) => void;
    sort: (event: React.SyntheticEvent, direction: ISortDirection) => void;
}

const AppDateFilter = (props: IProps) => {
    const { t } = useTranslation();
    const { options, columnName, doFilter } = props;

    const values = options[columnName] as {[key: string]: string};

    const [value, setValue] = useState<[Date | null, Date  | null]>([null, null]);

    useEffect(() => {
        if (values && values?.from && values?.to) {
            setValue([moment(values?.from).toDate(), moment(values?.to).toDate()]);
        } else {
            setValue([null, null]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const onChange = (_data: [Date | null, Date  | null], e: React.BaseSyntheticEvent) => {
        const newOptions = { ...options };
        if(!newOptions[columnName]) newOptions[columnName] = {};

        newOptions[columnName] = {
            from: _data && _data[0] ? requestTimeFormat(_data[0]) : null,
            to: _data && _data[1] ? requestTimeFormat(_data[1], true) : null
        }

        doFilter(newOptions, e);
    }

    return (
        <>
            {/* <MenuItem  
                className="menuItem" 
                onClick={(event: React.SyntheticEvent) => sort(event, ISortDirection.asc)}
                selected={options.stringAsc === columnName}
            >
                <Typography 
                    variant="body2" 
                    color={vars['colors-secondary_1']}
                >
                 {t('reports.sortLatest')}
                </Typography>
                {options.stringAsc === columnName && <img src="/img/selected.svg" alt="selected" style={{marginLeft: rem(21)}}/>}
            </MenuItem>
            <MenuItem  
                className="menuItem"
                onClick={(event: React.SyntheticEvent) => sort(event, ISortDirection.desc)}
                selected={options.stringDesc === columnName}
            >
                <Typography 
                    variant="body2" 
                    color={vars['colors-secondary_1']} 
                >
                {t('reports.sortEarliest')}
                </Typography>
                {options.stringDesc === columnName && <img src="/img/selected.svg" alt="selected" style={{marginLeft: rem(21)}}/>}
            </MenuItem>
            <Divider className="menuDivider"/> */}
            <Typography 
                variant="subtitle2"
                sx={{
                    fontWeight: '700',
                    lineHeight: rem(20),
                    marginLeft: rem(10),
                    marginTop: rem(15),
                    textTransform: 'uppercase'
                }}
            >
                {t('reports.filtering')}
            </Typography>
            <MenuItem  className="menuItem hideHover">
                <AppDateRangePicker value={value} onChange={onChange as () => Date} />
            </MenuItem>
            {/* <Divider className="menuDivider"/> */}
        </>
    )
}

export default AppDateFilter;