import { useTranslation } from "react-i18next";

import { Typography, Box } from "@mui/material";

import { rem } from '../../app/muiTheme/helpers';
import vars from "../../styles/_variables.module.scss";

interface IProps {
    orderStart: string;
    orderEnd: string;
}

export default function OrderInterval(props: IProps) {
    const { orderStart, orderEnd  } = props;
    const { t } = useTranslation();

    return (
        <Box>
            <Typography 
            variant="h6" 
            sx={{
                marginBottom: rem(10)
            }}>
                {t('report.order')}
            </Typography>
            <Box sx={{display: 'flex'}}>
                <Box 
                sx={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    marginRight: rem(30) 
                }}>
                    <Typography 
                    variant="body2" 
                    sx={{
                        color: vars['colors-secondary_3'], 
                        marginBottom: rem(4)
                    }}>
                        {t('report.orderStart')}
                    </Typography>
                    <Typography variant="body1">{orderStart}</Typography>
                </Box>
                <Box 
                sx={{
                    display: 'flex', 
                    flexDirection: 'column'
                }}>
                    <Typography 
                    variant="body2" 
                    sx={{
                        color: vars['colors-secondary_3'], 
                        marginBottom: rem(4)
                    }}>
                        {t('report.orderEnd')}
                    </Typography>
                    <Typography variant="body1">{orderEnd}</Typography>
                </Box>
            </Box>
        </Box>
    );
}