import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import LeftMenu from "./LeftMenu";

import { rem } from '../../muiTheme/helpers';
import Header from "./Header";
import { logout, token } from "../../../features/Login/authSlice";
import { Grid } from "@mui/material";
import CannotDeleteDialog from "../../../features/CannotDeleteDialog";
import ChangePassword from "./ChangePassword";

const drawerWidth = 260;

interface IProps {
  window?: () => Window;
}

export default function MainLayout(props: IProps) {
  const accessToken = useAppSelector(token);
  const dispatch = useAppDispatch();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [show, setShow] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const onOpen = (value: boolean) => {
    setOpenDialog(value);
  }

  const handleDrawerToggle = () => {
    if(mobileOpen) setShow(false);
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    if (mobileOpen) setMobileOpen(false);
  };

  useEffect(() => {
    if(!accessToken) {
      dispatch(logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if(!mobileOpen) setShow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileOpen]);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
    <CannotDeleteDialog />

    <ChangePassword openDialog={openDialog} onOpen={onOpen} fromMain={true} />

    <Box sx={{ display: "flex" }}>
      <Header
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle} />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen && show}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <LeftMenu closeDrawer={handleDrawerToggle} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <LeftMenu closeDrawer={closeDrawer} />
        </Drawer>
      </Box>
      <Grid sx={{width: '100%', overflow: 'hidden'}}>
        <Box
          className="container"
          component="main"
          sx={{
            flexGrow: 1,
            paddingLeft: rem(25),
            paddingRight: rem(25),
            marginTop: rem(83),
            width: 'inherit',
            overflow: 'hidden'
          }}
        >
          {/* <Toolbar /> */}
          <Outlet />
        </Box>
      </Grid>
    </Box>
    </>
  );
}
