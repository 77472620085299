import { useState } from "react";
import { Grid, Box } from "@mui/material";

import NumberField from "../../app/components/NumberField";

export default function NumberFieldExp() {
  const [value, setValue] = useState<string>("");
  return (
    <Grid item>
      <Box m={5}>
        <NumberField
          value={value}
          label='Number Field'
          onChange={(_val: string) => {
            setValue(_val);
          }}
        />
      </Box>
      <Box m={5}>
        <NumberField
          value={value}
          label='Number Field (percent)'
          inputIcon='%'
          onChange={(_val: string) => {
            setValue(_val);
          }}
        />
      </Box>
    </Grid>
  );
}
