import { IResponseDataErrors, IResponseErrors } from "../../app/entities/IAppError";
import { IGroup } from "../../app/entities/IGroups";
import { checkCannotDeleteError, createInstance, handleUnauthorizedError } from "../../app/services";


const { REACT_APP_CORPORATE_BACK } =  process.env;

export async function groupList(){
    try {
      return await createInstance(REACT_APP_CORPORATE_BACK, true).get(`/group/list`);
    } catch(err) {
      const error = err as IResponseErrors;
      if (error.response) {
        handleUnauthorizedError(error);
      } else {
        throw err;
      }
    }
}

export async function getAllGroups(){
  try {
    return await createInstance(REACT_APP_CORPORATE_BACK, true).get(`/group`);
  } catch(err) {
    const error = err as IResponseErrors;
    if (error.response) {
      handleUnauthorizedError(error);
    } else {
      throw err;
    }
  }
}

export async function removeGroup(id: string){
  try {
    const response = await createInstance(REACT_APP_CORPORATE_BACK, true).delete(`/group/${id}`);
    return response.data;
  } catch(err) {
    const error = err as IResponseErrors;
    if (error.response) {
      checkCannotDeleteError(error, 'groups.cannotDelete');
      handleUnauthorizedError(error);
    } else {
      throw err;
    }
  }
}

export async function addEditGroup(data: IGroup, rejectWithValue: (value: { errors: IResponseDataErrors }) => void){
  const requestData = { ...data};
  try {
    if(data.id){
      const id = requestData.id;
      delete requestData.id;

      const response = await createInstance(REACT_APP_CORPORATE_BACK, true).put(`/group/${id}`, requestData);
      return response.data;
    } else {
      const response = await createInstance(REACT_APP_CORPORATE_BACK, true).post(`/group`, requestData);
      return response.data;
    }
  } catch(err) {
    const error = err as IResponseErrors;
    if (error.response) {
      handleUnauthorizedError(error);
      return rejectWithValue({errors: error.response.data});
    } else {
      throw err;
    }
  }
}
