import { IResponseErrors } from "../../app/entities/IAppError";
import { createInstance, handleUnauthorizedError } from "../../app/services";

const { REACT_APP_API_MENU, REACT_APP_API_MENU_USERNAME, REACT_APP_API_MENU_PASSWORD } =  process.env;

export async function doMenuLogin() {
    const requestData = {
        username: REACT_APP_API_MENU_USERNAME,
        password: REACT_APP_API_MENU_PASSWORD,
        rememberMe: true
    }
    try {
      const response = await createInstance(REACT_APP_API_MENU, false).post(`/reporting/login`, requestData);
      return response.data;
    } catch(err) {
      const error = err as IResponseErrors;
      if (error.response) {
        handleUnauthorizedError(error);
      } else {
        throw err;
      }
    }
}