import { ChangeEvent, KeyboardEvent, MouseEvent } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField, { BaseTextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

interface IProps extends BaseTextFieldProps {
  value: string;
  onChange: (value: string) => void;
  inputIcon?: string;
  noIcon?: boolean;
  useGrouping?: boolean;
}

export default function NumberField(props: IProps) {
  const { value = "", onChange, noIcon, inputIcon, useGrouping = true, ...rest } = props;

  const stopEvent = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onPress = (event: KeyboardEvent) => {
    event = event || window.event;
    const charCode = typeof event.which == "undefined" ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);

    const isNotValid =
      !charStr.match(/[0-9]|\./) ||
      (charStr === "." && Boolean(value) && value.toString().includes("."));

    if (isNotValid) event.preventDefault();
  };

  const changeData = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const _newValue = value.replace(/\s/g, '').replace(/,/g, '.');
    const _val = parseFloat(_newValue) < 0 ? "0" : _newValue;

    if(_val.length < 14)  onChange(_val);
  };

  const onUp = () => {
    const _val = value === '' ? "0" : (parseInt(value) + 1).toString();
    if(_val.length < 14) onChange(_val);
  };

  const onDown = () => {
    const _val = Number(value) === 0 ? "0" : (parseInt(value) - 1).toString();
    onChange(_val);
  };


  const numberFormatter = () => {
    if(value === '') {
        return '';
    } else {
        const floatIndex = value.indexOf('.');

        if(floatIndex > -1) {
            if (floatIndex === value.length - 1) {
                return new Intl.NumberFormat('ru-RU', { useGrouping }).format(value as unknown as number) + ',';
            } else if(value[floatIndex + 1] === '0' &&  value.length === floatIndex + 1){
                return new Intl.NumberFormat('ru-RU', { useGrouping }).format(parseInt(value)) + ',0';
            } else if(value[value.length - 1] === '0') {
              const float = value.substr(floatIndex + 1, value.length - 1);
              return new Intl.NumberFormat('ru-RU', { useGrouping }).format(parseInt(value)) + ',' + float;
            } else {
                return new Intl.NumberFormat('ru-RU', { useGrouping, maximumSignificantDigits: 13 }).format(parseFloat(value));
            }
        } else {
            return new Intl.NumberFormat('ru-RU', { useGrouping, maximumSignificantDigits: 13 }).format(parseFloat(value));
        }
    }
  };
  
  return (
    <TextField
      {...rest}
      value={numberFormatter() || ""}
      type="text" //number
      onClick={stopEvent}
      onKeyPress={onPress}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => changeData(e)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {inputIcon && <Box mx={1}>
                {inputIcon}
            </Box>}
            {!noIcon && 
              <Box className="d-flex flex-column">
                <IconButton size="small" sx={{ p: "3px" }} onClick={onUp}>
                  <img alt="arrowUp" src="/img/inputs/arrowUp.svg" width="10" />
                </IconButton>
                <IconButton size="small" sx={{ p: "3px" }}>
                  <img
                    alt="arrowDown"
                    src="/img/inputs/arrowDown.svg"
                    width="10"
                    onClick={onDown}
                  />
                </IconButton>
              </Box>
            }
          </InputAdornment>
        ),
      }}
    />
  );
}
