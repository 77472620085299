import { useTranslation } from "react-i18next";

import AppTable from "../../../app/components/Table";
import { Align } from "../../../app/entities/IAppTable";
import { getExampleDataAsync } from "./tableExampleSlice";

import RowExp from "./Row";

const TableExp = () => {
  const { t } = useTranslation();    

  const onAction = (action: string) => {
    switch (action) {
      case "remove": {
        break;
      }
      default: {
        break;
      }
    }
  };


  return (
    <AppTable
      name="tableExample"
      loadData={getExampleDataAsync}
      empty={false}
      Component={RowExp}
      onAction={onAction}
      header={{
        defaultSortBy: "name",
        columns: {
          name: { label: t(`name`), sortable: true, align: Align.left },
          address: { label: t(`address`), sortable: false, align: Align.left },
          action: { label: t(`action`), sortable: false, align: Align.right },
        },
      }}
    />
  );
};

export default TableExp;
