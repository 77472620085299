import { IResponseErrors } from "../../app/entities/IAppError";
import { IRequestReport } from "../../app/entities/IReports";
import { createInstance } from "../../app/services";

const { REACT_APP_CORPORATE_BACK } =  process.env;

const requestDataConvertor = (requestData: IRequestReport) => {
  const { 
    corporateSum,
    customerName,
    customerPhone,
    customerPaidTotal,
    deliveryFee,
    groupName,
    orderId,
    orderTotal,
    restaurantName,
    ruleName,
    startTime
  } = requestData.sorting;

  return {
    corporateSumFrom: corporateSum ? corporateSum.min : null,
    corporateSumTo: corporateSum ? corporateSum.max : null,
    customerName: customerName ? customerName.searchValue : '',
    customerPhone: customerPhone ? customerPhone.searchValue : '',
    customerPaidTotalFrom: customerPaidTotal ? customerPaidTotal.min : null,
    customerPaidTotalTo: customerPaidTotal ? customerPaidTotal.max : null,
    deliveryFeeFrom: deliveryFee ? deliveryFee.min : null,
    deliveryFeeTo: deliveryFee ? deliveryFee.max : null,
    groupName: groupName ? groupName.searchValue : '',
    orderIdFrom: orderId ? orderId.min : null,
    orderIdTo: orderId ? orderId.max : null,
    orderTotalFrom: orderTotal ? orderTotal.min : null,
    orderTotalTo: orderTotal ? orderTotal.max : null,
    restaurantName: restaurantName ? restaurantName.searchValue : '',
    ruleName: ruleName ? ruleName.searchValue : '',
    startTimeFrom: startTime ? startTime.from : null,
    startTimeTo:  startTime ? startTime.to : null
  }

}

export async function reportsGet(requestData: IRequestReport){
  const { corporateClientId, pagination } = requestData;
  const _request = requestDataConvertor(requestData);

  try {
    return await createInstance(REACT_APP_CORPORATE_BACK, true)
    .post(
      `/corporate/order/report/${corporateClientId}/list?page=${pagination.page}&size=${pagination.perPage}`, _request);
  } catch(err) {
    const error = err as IResponseErrors;
    throw error;
  }
}

export async function getAllReports(corporateClientId: number, requestData: IRequestReport){
  const _request = requestDataConvertor(requestData);

  try {
    return await createInstance(REACT_APP_CORPORATE_BACK, true)
    .post(
      `/corporate/order/report/${corporateClientId}/list?page=0&size=500000`, _request);
  } catch(err) {
    const error = err as IResponseErrors;
    throw error;
  }
}