import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { LoadStatus } from './../../app/entities/IAppLoadStatus';
import { RootState } from "../../app/store";
import { IReportItem, IRequestReport } from "../../app/entities/IReports";
import { menuLoginAsync } from "../MenuAuth/menuAuthSlice";
import { getAllReports, reportsGet } from "./reportAPI";

interface ITotals {
    deliveryFee?: number;
    customerPaidTotal?: number;
    corporateSum?: number;
    orderTotal?: number;
}

export interface ReportState {
    data: Array<IReportItem>;
    status: LoadStatus;
    pageStatus: LoadStatus;
    totalCount: number;
    jsonData?: Array<IReportItem>;
    statusLoadJsonData: LoadStatus;
    totals: ITotals;
    totalPages: number;
}

const initialState: ReportState = {
    data: [],
    status: LoadStatus.Idle,
    pageStatus: LoadStatus.Idle,
    totalCount: 0,
    totalPages: 0,
    statusLoadJsonData: LoadStatus.Idle,
    totals: {}
};

export interface ReportResponse {
    payload: {
      content: Array<IReportItem>;
      totalElements: number;
      totalPages: number;
    }
}

export const getReportsAsync = createAsyncThunk(
    'reports/get',
    async (requestData: IRequestReport,{ dispatch }) => {
        await dispatch(menuLoginAsync());
        const response = await reportsGet(requestData);
        return response?.data;
    }
);

export const getAllReportsAsync = createAsyncThunk(
    'reports/getAll',
    async (corporateClientId: number, { getState, dispatch }) => {
        const tablesFilters = (getState() as RootState).tablesFilters;
        
        let requestData = {};
        if(tablesFilters.filters.report) {
            requestData = tablesFilters.filters.report;
        }

        await dispatch(menuLoginAsync());
        const response = await getAllReports(corporateClientId, requestData as IRequestReport);
        return response?.data;
    }
);

export const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        cleanUpReports: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => { 
        builder
            .addCase(getReportsAsync.rejected, (state: ReportState) => {
                state.status = LoadStatus.Failed;
              })
            .addCase(getReportsAsync.pending, (state: ReportState) => {                
                state.status = LoadStatus.Loading;
                state.totals = {};
            })
            .addCase(getReportsAsync.fulfilled, (state: ReportState, action: ReportResponse) => {
                const payload = action.payload;
                state.status = LoadStatus.Success;
                
                state.data = payload.content;
                state.totalCount = payload.totalElements;

                const totalInitial = {
                    deliveryFee: 0, 
                    customerPaidTotal: 0, 
                    corporateSum: 0,
                    orderTotal: 0
                }

                if (payload.totalElements === 0) {
                    state.totals = { ...totalInitial };
                } else {
                    const totals = payload.content.reduce((previousValue, currentValue) => (
                        {
                            deliveryFee: previousValue.deliveryFee + currentValue.deliveryFee,
                            customerPaidTotal: previousValue.customerPaidTotal + currentValue.customerPaidTotal,
                            corporateSum: previousValue.corporateSum + currentValue.corporateSum,
                            orderTotal: previousValue.orderTotal + currentValue.orderTotal
                        }
                    ), { ...totalInitial });

                    state.totals = totals;
                    state.totalPages = payload.totalPages;
                    state.totalCount = payload.totalElements;
                }                
            })
            .addCase(getAllReportsAsync.rejected, (state: ReportState) => {
                state.statusLoadJsonData = LoadStatus.Failed;
              })
            .addCase(getAllReportsAsync.pending, (state: ReportState) => {                
                state.statusLoadJsonData = LoadStatus.Loading;
                delete state.jsonData;
            })
            .addCase(getAllReportsAsync.fulfilled, (state: ReportState, action: ReportResponse) => {
                const payload = action.payload;
                state.statusLoadJsonData = LoadStatus.Success;
                state.jsonData = payload.content;
            })
    },
});

export const { cleanUpReports } = reportSlice.actions;

export const reportsData = (state: RootState) => state.report.data;
export const reportStatus = (state: RootState) => state.report.status;
export const reportPageStatus = (state: RootState) => state.report.pageStatus;

export const reportsJsonData = (state: RootState) => state.report.jsonData;
export const statusLoadJsonData = (state: RootState) => state.report.statusLoadJsonData;
export const reportTotals = (state: RootState) => state.report.totals;

export default reportSlice.reducer;