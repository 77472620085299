import vars from "../../styles/_variables.module.scss";
import { rem } from "./helpers";

const MuiButton: {MuiButton : object} = {
  MuiButton: {
    styleOverrides: {
      root: {
        padding: `${rem(17)} ${rem(32)}`,
        boxShadow: "none !important",
        borderRadius: rem(10),
        textTransform: "capitalize"
      },
    },
    variants: [
      {
        props: { variant: "contained" },
        style: {
          backgroundColor: vars["colors-primary_1"],
          color: vars["colors-light_color"],
          "&:hover": {
            backgroundColor: vars["colors-primary"],
          },
          "&:disabled": {
            backgroundColor: vars["colors-primary_3"],
            color: vars["colors-light_color"],
          },
        },
      },
      {
        props: { variant: "outlined" },
        style: {
          padding: `${rem(16)} ${rem(32)}`,
          backgroundColor: vars["colors-light_color"],
          color: vars["colors-primary"],
          border: `1px solid ${vars["colors-primary"]}`,
          "&:hover": {
            backgroundColor: vars["colors-background_color"],
            color: vars["colors-primary_1"],
            border: `1px solid ${vars["colors-primary_1"]}`,
          },
          "&:disabled": {
            backgroundColor: vars["colors-background_color"],
            color: vars["colors-primary_4"],
            border: `1px solid ${vars["colors-primary_4"]}`,
          },
        },
      },
      {
        props: { variant: "text" },
        style: {
          backgroundColor: vars["colors-light_color"],
          color: vars["colors-secondary_2"],
          "&:hover": {
            backgroundColor: vars["colors-secondary_5"],
            color: vars["colors-secondary_2"],
          },
          "&:disabled": {
            backgroundColor: vars["colors-light_color"],
            color: vars["colors-secondary_4"],
          },
        },
      },
    ],
  },
};

export default MuiButton;
