import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import "./index.scss";
import vars from "../../styles/_variables.module.scss";

import Login from '../../features/Login';

import { useAppSelector } from '../hooks';
import { token } from '../../features/Login/authSlice';

export default function AuthLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const accessToken = useAppSelector(token);
  
  if(Boolean(accessToken) && location.pathname === '/') {
    return <Navigate to="/rules" replace />;
  } else {
     return (
      <Grid container component="main" sx={{ height: '100vh' ,backgroundColor: vars['colors-background_color']}}>
        <Grid
          item
          className="authLeft"
          sx={{
            backgroundImage: 'url(/img/authBackground.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid 
          item 
          className="authRight" 
          sx={{ 
            backgroundColor: vars['colors-background_color'], 
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center'
          }} 
          component={Paper} 
          elevation={6} 
          square
        >
          <Box
            className="formBox"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="h3" className="loginText" align="left">
              { t('auth.login') }
            </Typography>
            <Login />
          </Box>
        </Grid>
      </Grid>
  );
  }
}