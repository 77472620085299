import { ReactElement } from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useTranslation } from "react-i18next";

import Alert from "@mui/material/Alert";
import { LoadStatus } from "../../entities/IAppLoadStatus";
import { IAppTable } from "../../entities/IAppTable";

interface IProps {
  name: string;
  items: Array<IAppTable.RowItems>;
  state: LoadStatus;
  error: string;
  Component: <U extends IAppTable.IComponent<IAppTable.RowItems>>(props: U) => ReactElement | JSX.Element;
  onAction?: (action: string, value: IAppTable.RowItems, event?: React.BaseSyntheticEvent) => void;
  columnsCount: number;
  ComponentProps: IAppTable.IComponentProps; 
  TotalComponent?: () => ReactElement | JSX.Element;
}

function TableRowWrapper(props: IProps){
  const {
    items,
    Component,
    state,
    error,
    name,
    ComponentProps = {},
    columnsCount = 0,
    TotalComponent,
  } = props;
  const { t } = useTranslation();

  const onAction = (actionName: string, data: IAppTable.RowItems, event?: React.BaseSyntheticEvent ) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (props.onAction) props.onAction(actionName, data as unknown as IAppTable.Items);
  };

  let rows;
  switch (state) {
    case LoadStatus.Loading: {
      if (items && items.length > 0) {
          rows = items.map((item, index) => <Component item={item} key={`${name}-${index}`} isLoading />);
      } else {
          rows = <Component isLoading={true} />;
      }
      break;
    }
    case LoadStatus.Success: {
      if (items && items.length === 0) {
          rows = (
              <TableRow>
                  <TableCell colSpan={ columnsCount } sx={{paddingLeft: '0 !important', paddingRight: '0 !important'}}>
                      <Alert className="justify-content-center" severity="warning">{ t('messages.nothingFound') }</Alert>
                  </TableCell>
              </TableRow>
          );
      } else {
          rows = <>
            {items && items.map((item, index) => <Component item={item} {...ComponentProps} key={`${name}-${index}`} onAction={onAction} isLoading={false} />)}
            {TotalComponent && <TotalComponent key={`total-${name}`}/>}
          </>
      }
      break;
    }
    case LoadStatus.Failed: {
      rows = (
        <TableRow>
          <TableCell colSpan={columnsCount} sx={{paddingLeft: '0 !important', paddingRight: '0 !important'}}>
            <Alert className="justify-content-center" severity="error">
              { error }
            </Alert>
          </TableCell>
        </TableRow>
      );
      break;
    }
    default:
      rows = null;
  }

  return <>{rows}</>;
};

export default TableRowWrapper;
