import { IResponseDataErrors, IResponseErrors } from "../../app/entities/IAppError";
import { IPartner } from "../../app/entities/IAppPartner";
import { IAppTable } from "../../app/entities/IAppTable";
import { checkCannotDeleteError, createInstance, handleUnauthorizedError } from "../../app/services";


const { REACT_APP_CORPORATE_BACK, REACT_APP_API_MENU } =  process.env;

export async function getPartnersGet(requestData: IAppTable.IPaginationOptions){
    try {
      return await createInstance(REACT_APP_CORPORATE_BACK, true).post(`/partner/group/list`,requestData);
    } catch(err) {
      const error = err as IResponseErrors;
      if (error.response) {
        handleUnauthorizedError(error);
      } else {
        throw err;
      }
    }
}

export async function getAllPartners(){
  try {
    return await createInstance(REACT_APP_CORPORATE_BACK, true).get(`/partner/group`);
  } catch(err) {
    const error = err as IResponseErrors;
    if (error.response) {
      handleUnauthorizedError(error);
    } else {
      throw err;
    }
  }
}

export async function removePartner(id: string){
  try {
    const response = await createInstance(REACT_APP_CORPORATE_BACK, true).delete(`/partner/group/${id}`);
    return response.data;
  } catch(err) {
    const error = err as IResponseErrors;
    if (error.response) {
      checkCannotDeleteError(error, 'partner.cannotDelete');
      handleUnauthorizedError(error);
    } else {
      throw err;
    }
  }
}

export async function upDatePartnerStatus(id: string){
  try {
    const response = await createInstance(REACT_APP_CORPORATE_BACK, true).put(`/partner/group/${id}/status`);
    return response.data;
  } catch(err) {
    const error = err as IResponseErrors;
    if (error.response) {
      handleUnauthorizedError(error);
    } else {
      throw err;
    }
  }
}

export async function addEditParner(data: IPartner, rejectWithValue: (value: { errors: IResponseDataErrors }) => void){
  const requestData = { ...data};
  try {
    if(data.id){
      const id = requestData.id;
      delete requestData.id;

      const response = await createInstance(REACT_APP_CORPORATE_BACK, true).put(`/partner/group/${id}`, requestData);
      return response.data;
    } else {
      const response = await createInstance(REACT_APP_CORPORATE_BACK, true).post(`/partner/group`, requestData);
      return response.data;
    }
  } catch(err) {
    const error = err as IResponseErrors;
    if (error.response) {
      handleUnauthorizedError(error);
      return rejectWithValue({errors: error.response.data});
    } else {
      throw err;
    }
  }
}

export async function autosuggestParner(name: string){
  try {
    const response = await createInstance(REACT_APP_API_MENU, false).get(`/content-search/search/partner/base?namePart=${name}&showCorporate=true`);
    return response.data;
  } catch(err) {
    const error = err as IResponseErrors;
    if (error.response) {
      handleUnauthorizedError(error);
    } else {
      throw err;
    }
  }
}
