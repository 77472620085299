import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { LoadStatus } from "../../app/entities/IAppLoadStatus";
import { RootState } from "../../app/store";
import { doMenuLogin } from "./menuAuthAPI";

export const getMenuTokenInfo = () => {
    const sessionToken = sessionStorage.getItem("menuToken");

    if(sessionToken) {
        return sessionToken;
    } else {
        return null;
    }
}

export interface MenuAuthState {
    status: LoadStatus;
    menuToken: string | null;
}

const initialState: MenuAuthState = {
    status: LoadStatus.Idle,
    menuToken: getMenuTokenInfo(),
};

export interface MenuAuthResponse {
    payload: {
      response: string;
    };
}

export const menuLoginAsync = createAsyncThunk(
    'auth/menuLogin/do',
    async () => {
      const response = await doMenuLogin();
      return response;
    }
);

export const menuAuthSlice = createSlice({
    name: 'auth/menuLogin',
    initialState,
    reducers: {
        cleanUpStatus: (state) => {
            state.status = LoadStatus.Idle;
        },
    },
    extraReducers: (builder) => { 
        builder
            .addCase(menuLoginAsync.rejected, (state: MenuAuthState) => {
                state.status = LoadStatus.Failed;
            })
            .addCase(menuLoginAsync.pending, (state: MenuAuthState) => {
                state.status = LoadStatus.Loading;
            })
            .addCase(menuLoginAsync.fulfilled, (state: MenuAuthState, action: MenuAuthResponse) => {
                state.menuToken = action.payload.response;
                sessionStorage.setItem('menuToken', action.payload.response);
            })
    },
});

export const { cleanUpStatus } = menuAuthSlice.actions;

export const loginStatus = (state: RootState) => state.auth.status;
export const token = (state: RootState) => state.auth.token;
export const loginUserInfo = (state: RootState) => state.auth.user;
export const loginErrors = (state: RootState) => state.auth.errors;

export default menuAuthSlice.reducer;