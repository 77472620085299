export const hy = {
  translation: {
    more: "more",
    actions: {
      ok: "Ok",
      add: "Add",
      cancel: "Cancel",
      delete: "Delete",
      edit: "Edit",
      addOrderRule: "Add Order Rule",
      change: 'Change',
      confirm: 'Confirm',
    },
    auth: {
      login: 'Login',
      username: 'Username',
      password: 'Password',
      remember: 'Remember me'
    },
    dialog: {
      content: "Are you sure you want to delete",
      orderRule: "Order rule"
    },
    pagination: {
      label: {
        short: "Per page:",
        long: "Rows per page:"
      },
      of: "of"
    },
    messages: {
      nothingFound: 'Nothing Found'
    },
    mainLayout: {
      logout: 'Logout',
      changePassword: 'Change password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password'
    },
    cannotDelete: {
      title: 'You Cannot Delete a {{name}}',
      subTitle: 'This {{name}} is linked order rule. Please unlink the {{name}}.'
    },
    emptyTable: {
      title: "You don't have any {{name}} yet :)",
      orderRules: "Order Rules",
      paymentType: "Payment Types",
      setAmount: 'Set Amount',
      setPercentage: 'Set Percentage',
      address: "Address",
      groups: "Groups",
      partners: "Restaurants",
      rule: "Order Rules",
      members: "Members"
    },
    groupItem: {
      members: "Members"
    },
    daysOfWeek: {
      sun: "Կիր",
      mon: "Երկ",
      tue: "Երք",
      wed: "Չոր",
      thu: "Հնգ",
      fri: "Ուրբ",
      sat: "Շաբ"
    },
    report: {
      orderItems: "Order Items and amount",
      order: "Order",
      orderStart: "Order Start",
      orderEnd: "Order End",
      orderPrice: {
        price: "Price",
        deliveryFee: "Delivery Fee",
        subtotal: "Subtotal",
        castomerPaid: "Castomer Paid",
        total: "Total"
      }
    },
    paymentType: {
      title: 'Payment Type',
      add: 'Add Payment Type',
      edit: 'Edit Payment Type',
      setAmount: 'Set Amount',
      setPercentage: 'Set Percentage',
      emptyContent: 'Please click  button to add your first Payment Type',
      name: 'Name',
      tableColName: 'Name',
      amount: 'Amount',
      percentage: 'Percentage',
      amountLimit: 'Amount Limit',
      enterPaymentTypeName: 'Enter Payment Type Name',
      percentageAmount: 'Amount Limit',
      type: 'Type',
      limites: 'Limites',
      status: 'Status',
      actions: 'Actions',
      cannotDelete: {
        title: "a Payment Type",
        subTitle: "Payment Type"
      }
    },
    addresses: {
      title: 'Address',
      addressLabel: 'Delivery Address',
      name: 'Name',
      namePlaceholder: 'Name',
      add: 'Add Address',
      edit: 'Edit Address',
      detail: 'Address detail',
      apartment: 'Apartment',
      entrance: 'Entrance',
      floor: 'Floor',
      courier: 'Courier instructions',
      intercome: 'Intercome',
      linked: 'Linked',
      enter: 'Enter Your Address',
      select: 'Select your address on the map',
      addAddressBtn: 'Add Delivery Address',
      actions: 'Actions',
      emptyContent: 'Please click  button to add your first Address',
      cannotDelete: {
        title: "this Address",
        subTitle: "delivery address"
      }
    },
    groups: {
      title: "Group",
      employeeGroup: "Employee Group",
      name: 'Name',
      add: 'Add Group',
      edit: 'Edit Group',
      description: 'Description',
      typeName: 'Type Group Name',
      addDescr: 'Please add Group Description',
      symbol: 'Up to 50 symbol /',
      emptyContent: 'Please click  button to add your first group',
      cannotDelete: {
        title: "a Group",
        subTitle: "Group"
      }
    },
    members: {
      name: "Employee Name",
      add: "Add Members",
      addMember: "Add Member",
      phone: "Phone Number",
      status: "Status",
      actions: "Actions",
      byPhone: "By Phone Number",
      uploadCSV: "Upload CSV file",
      mobile: "Mobile Number",
      attached: "Attached File",
      csv: "Upload CSV",
      format: "Format should be “full phone numbers separated by comma, i.e 37491222222,..“",
      upload: "Upload",
      number: "number",
      emptyContent: "Please click  button to add Members",
      accepted: "Accepted",
      pending: "Pending...",
      rejected: "Rejected",
      resend: {
        tooltip: 'Resend for pending invites',
        title: 'Resend'
      }
    },
    partner: {
      title: 'Restaurants',
      add: 'Add Restaurant',
      edit: 'Edit Restaurant',
      emptyContent: 'Please click  button to add your first Restaurant',
      name: 'Name',
      groupName: 'Partner Group Name',
      groupNamePlaceholder: 'Type Partner Group Name',
      restaurant: 'Restaurant',
      status: 'Status',
      actions: 'Actions',
      allRestaurants: 'All Restaurants',
      selectRestaurants: 'Select Restaurants',
      selectPlaceholder: 'Type Restaurant Name',
      cannotDelete: {
        title: "a Restaurant",
        subTitle: "Restaurant"
      }
    },
    reports: {
      title: "Reporting",
      download: "Download",
      downloadFull: "Download full info",
      company: "Company",
      allowance: "Allowance",
      date: "Date",
      coveredAmount: "Covered Amount",
      customerAmount: "Customer Amount",
      customers: "Customers",
      phone: "Phone Number",
      orderId: "Order ID",
      customerPaid: "Customer Paid",
      companyPaid: "Company Paid",
      fullAmount: "Order Full Amount",
      total: "Total:",
      sortingDate: "Sorting By Date",
      sortingTime: "Sorting By Time",
      sortASC: "Sort A - Z",
      sortDESC: "Sort Z - A",
      sortLatest: "Latest first",
      sortEarliest: "Earliest first",
      low: "Lowest - Highest",
      high: "Highest - Lowest",
      group: "Group",
      phoneNumber: "Phone number",
      restaurant: "Restaurant",
      orderNumber: "Order Number",
      orderPrice: "Order Price",
      deliveryPrice: "Delivery Fee",
      filtering: "Filtering",
      all: "All",
      customerName: "Customer Name"
    },
    rule: {
      title: 'Order Rule',
      mealAllowance: 'Meal Allowance',
      add: 'Add Order Rule',
      edit: 'Edit Order Rule',
      emptyContent: 'Please click  button to add your first Order Rule',
      name: 'Name',
      mealAllowancesName: 'Meal allowances Name',
      mealNamePlaceholder: 'Enter Your Allowances name',
      groupName: 'Group Name',
      restaurant: 'Restaurant',
      deliveryPartner: 'Delivery Partner',
      restaurantPlaceholder: 'Select Delivery Partners',
      status: 'Status',
      actions: 'Actions',
      address: 'Address',
      addressPlaceholder: 'Select Delivery Address',
      timeSlot: 'Time Slot',
      timeSlotPlaceholder: 'Select Delivery Slot',
      group: 'Group',
      groupNamePlaceholder: 'Select Employee Group',
      payment: 'Payment',
      budgetType: 'Budget Type',
      paymentType: 'Payment Type',
      paymentTypePlaceholder: 'Select Budget Type',
      daysOfWeek: 'Week Day'
    },
    leftMenu: {
      rules: 'Order Rule',
      groups: 'Group',
      address: 'Address',
      paymentTypes: 'Payment Type',
      restaurants: 'Restaurants',
      reports: 'Reporting'
    },
    placeholder: {
      enterPassword: 'Enter your password',
      enterNewPassword: 'Enter new password',
      confirmPassword: 'Confirm new password',
      enterUsername: 'Enter Your Username',
      setAmountLimit: 'Set Amount Limit',
      min: "Min",
      max: "Max"
    },
    snackbarMessages: {
      successChangePassword: 'Your password has been successfully changed',
      successResendNotifications: 'Notifications were send successfully',
      failedResendNotifications: 'Something went wrong',
    },
    errorMessages: {
      common: 'Field is required',
      LESS_THAN_100: 'Field value must be from 1 to 100',
      MORE_THAN_0: 'Field value must be more 0',
      EQUAL_OR_MORE_THAN_0: 'Field value must be equal or more than 0',
      integer: 'Field value must be integer',
      usernameRequired: 'Username is required',
      validEmail: 'Enter a valid email',
      passowordRequried: 'Password is required',
      passwordLength: 'Password should be of minimum 6 characters length',
      oldPassRequried: 'Old password is required',
      newPassRequried: 'New password is required',
      confirmPassRequried: 'Confirm password is required',
      passwordMatch: 'Passwords must match',
      NOT_EXISTS: 'User does not exist',
      NOT_VALID: 'The password is incorrect',
      OLD_AND_NEW_PASSWORDS_MATCH: 'New password cannot be the same as your old password',
      OUT_OF_ZONE: 'You are out of the courier service range',
      notFullAddress: 'Selected not full address',
      acceptType: 'You can upload only CSV files',
      invalidPhone: 'Invalid phone number',
      ALREADY_EXISTS: 'already exist',
    },
  },
};
