import { Grid, Typography, Chip } from "@mui/material";

import { rem } from '../../app/muiTheme/helpers';
import vars from "../../styles/_variables.module.scss";

interface IProps {
    orderId: string;
    description: string;
    orderType: string;
    variant?: "default" | "error" | "primary" | "secondary" | "info" | "success" | "warning" | undefined;
    orderDate: string;
}

export default function OrderTitle(props: IProps) {
    const { orderId, description, orderType, variant = 'success', orderDate } = props;

    return (
        <Grid 
        container 
        sx={{
            display: 'flex', 
            justifyContent: 'space-between',
        }}>
            <Grid item>
                <Typography 
                variant="h5" 
                sx={{
                    marginBottom: rem(10), 
                    wordBreak: 'break-word', 
                    maxWidth: rem(454)
                }}>
                    {orderId}
                </Typography>
                <Typography 
                variant="body2" 
                sx={{
                    color: vars['colors-secondary_2'], 
                    maxWidth: rem(333), 
                    wordBreak: 'break-word'
                }}>
                    {description}
                </Typography>
            </Grid>
            <Grid item>
                <Chip 
                label={orderType}
                color={variant}
                sx={{
                    marginLeft: rem(11), 
                    marginBottom: rem(10)
                }}/>
                <Typography 
                variant="body2" 
                sx={{
                    color: vars['colors-secondary_2']
                }}>
                    {orderDate}
                </Typography>
            </Grid>
        </Grid>
    );
}