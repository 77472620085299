import { useState } from 'react';

import DaysOfTheWeek from '../Rule/AddEdit/DaysOfTheWeek';

export default function DaysOfTheWeekExp() {
    const [daysOfWeek, setDaysOfWeek] = useState<Array<string>>([]);

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;

        let newState = []
        if(daysOfWeek.includes(name)){
            newState = daysOfWeek.filter((item: string) => item !== name);
        } else {
            newState = daysOfWeek.concat([name]);
        }
        setDaysOfWeek(newState);
    }

    return(
        <DaysOfTheWeek daysOfWeek={daysOfWeek} handleCheck={handleCheck} />
    );
}