import {useTranslation} from "react-i18next";

import TablePagination from '@mui/material/TablePagination';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IAppTable } from "../../entities/IAppTable";

interface IProps {
    totalCount: number;
    totalPages?: number;
    doPaging: (value: IAppTable.IPagination) => void;
    options: {[key: string]: number};
    rowsPerPageOptions: Array<number>;
}

const AppTablePagination = (props: IProps) => {
    const {totalCount, totalPages, rowsPerPageOptions, options} = props;
    const {t} = useTranslation();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const labelName = isXs? "short" : "long";

    if (!totalCount) return null;

    const changePage = (page: number) => {
        const newOptions = {...options};
        newOptions.page = page;

        props.doPaging(newOptions);
    };

    const changeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newOptions = {...options};
        newOptions.perPage = parseInt(event.target.value);
        newOptions.page = 0;

        props.doPaging(newOptions);
    };

    return (
        <TablePagination
            labelRowsPerPage={t(`pagination.label.${labelName}`)}
            labelDisplayedRows={({from, to, count, page}) => `${page + 1} ${t("pagination.of")} ${totalPages}`}
            component="div"
            rowsPerPageOptions={rowsPerPageOptions}
            count={totalCount}
            rowsPerPage={options.perPage}
            page={options.page}
            onPageChange={(_, page: number) => changePage(page)}
            onRowsPerPageChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => changeRowsPerPage(event)} 
        />
    );
};

export default AppTablePagination;
