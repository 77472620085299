import vars from '../../styles/_variables.module.scss';

const AppPalette = {
    primary: {
        main: vars['colors-primary_1']
    },
    secondary: {
        main: vars['colors-secondary'],
        light: vars['colors-secondary_4']
    },
    error: {
        main: vars['colors-error']
    }
};
export default AppPalette;