import PageHeader from "../../app/components/PageHeader";
import EmptyPageView from "../../app/components/EmptyPageView";

export default function EmptyTableWrapper() {
    const handleClick = () => { 
        // do nothing.
    };
    
    return(
        <>
            <PageHeader 
                title='dialog.orderRule' 
                actionBtn="actions.addOrderRule"
                handleClick={handleClick}
            />
            <EmptyPageView 
                show={true}
                name='emptyTable.orderRules'
                content="Please ensure that you have added at least one Group with invited members, 
                        Address, Time Slot and Payment Type before adding the “Order Rule”"
            />
        </>
    );
}