import { rem } from './helpers';

const AppTypography = {
    h1: {
        fontWeight: 300,
        fontSize: rem(96),
        lineHeight: rem(112),
        letterSpacing: 'normal'
    },
    h2: {
        fontWeight: 300,
        fontSize:  rem(60),
        lineHeight:  rem(70),
        letterSpacing: 'normal'
    },
    h3: {
        fontWeight: 400,
        fontSize: rem(48),
        lineHeight: rem(56),
        letterSpacing: 'normal'
    },
    h4: {
        fontWeight: 700,
        fontSize: rem(34),
        lineHeight: rem(40),
        letterSpacing: 'normal'
    },
    h5: {
        fontWeight: 400,
        fontSize: rem(24),
        lineHeight: rem(28),
        letterSpacing: 'normal'
    },
    h6: {
        fontWeight: 500,
        fontSize: rem(20),
        lineHeight: rem(23),
        letterSpacing: 'normal'
    },
    subtitle1: {
        fontWeight: 400,
        fontSize: rem(16),
        lineHeight: rem(25),
        letterSpacing: 'normal'
    },
    subtitle2: {
        fontWeight: 500,
        fontSize: rem(14),
        lineHeight: rem(16),
        letterSpacing: 'normal'
    },
    body1: {
        fontWeight: 400,
        fontSize: rem(16),
        lineHeight: rem(19),
        letterSpacing: 'normal'
    },
    body2: {
        fontWeight: 400,
        fontSize: rem(14),
        lineHeight: rem(20),
        letterSpacing: 'normal'
    },
    button: {
        fontWeight: 500,
        fontSize: rem(14),
        lineHeight: rem(16),
        letterSpacing: 'normal'
    },
    caption: {
        fontWeight: 400,
        fontSize: rem(12),
        lineHeight: rem(14),
        letterSpacing: 'normal'
    },
    overline: {
        fontWeight: 400,
        fontSize: rem(10),
        lineHeight: rem(15),
        letterSpacing: 'normal'
    }
  };
export default AppTypography;