import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LoadStatus } from "../../app/entities/IAppLoadStatus";
import { RootState } from "../../app/store";
import { addEditGroup, getAllGroups, groupList, removeGroup } from './groupAPI';
import { IGroupItem, IGroup } from './../../app/entities/IGroups';
import { IAllResponseData } from "../../app/entities";

export interface GroupState {
    data?: Array<IGroupItem>;
    status: LoadStatus;
    pageStatus: LoadStatus;
    deleteStatus: LoadStatus;
    addEditStatus: LoadStatus;
    allGroups: Array<IAllResponseData>;
    allGroupsStatus: LoadStatus;
}

const initialState: GroupState = {
    status: LoadStatus.Idle,
    pageStatus: LoadStatus.Idle,
    deleteStatus: LoadStatus.Idle,
    addEditStatus: LoadStatus.Idle,
    allGroupsStatus: LoadStatus.Idle,
    allGroups: []
};

export interface IResponse {
    payload: Array<IGroupItem>;
}

export const getGroupAsync = createAsyncThunk(
    'group/get',
    async () => {
        const response = await groupList();
        return response?.data;
    }
);

export const getAllGroupsAsync = createAsyncThunk(
    'group/getAll',
    async () => {
        const response = await getAllGroups();
        return response?.data;
    }
);

export const removeGroupAsync = createAsyncThunk(
    'group/delete',
    async (id: string) => {
        const response = await removeGroup(id);
        return response.data;
    }
);

export const addEditGroupAsync = createAsyncThunk(
    'group/addEdit',
    async (data: IGroup, { rejectWithValue }) => {
        const response = await addEditGroup(data, rejectWithValue);
        return response;
    }
);

export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        cleanUpGroups: () => {
            return initialState;
        },
        cleanUpStatuses: (state) => {
            state.addEditStatus = initialState.addEditStatus;
            state.deleteStatus = initialState.deleteStatus;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGroupAsync.rejected, (state: GroupState) => {
                state.status = LoadStatus.Failed;
                state.pageStatus = LoadStatus.Failed;
              })
            .addCase(getGroupAsync.pending, (state: GroupState) => {
                if(state.data) {
                    state.status = LoadStatus.Loading;
                } else {
                    state.pageStatus = LoadStatus.Loading;
                }
            })
            .addCase(getGroupAsync.fulfilled, (state: GroupState, action: IResponse) => {
                const payload = action.payload;

                if(payload?.length === 0) {
                    state.pageStatus = LoadStatus.Success;
                } else {
                    state.status = LoadStatus.Success;
                    state.pageStatus = LoadStatus.Idle;
                }

                state.data = payload;
            })
            .addCase(removeGroupAsync.rejected, (state: GroupState) => {
                state.deleteStatus = LoadStatus.Failed;
            })
            .addCase(removeGroupAsync.pending, (state: GroupState) => {
                state.deleteStatus = LoadStatus.Loading;
            })
            .addCase(removeGroupAsync.fulfilled, (state: GroupState) => {
                state.deleteStatus = LoadStatus.Success;
                state.pageStatus = LoadStatus.Idle;
            })
            .addCase(addEditGroupAsync.rejected, (state: GroupState) => {
                state.addEditStatus = LoadStatus.Failed;
                // const newErrors = action.payload.errors as IError;
                // state.errors = {
                //   [newErrors.param]: newErrors.error
                // };
              })
            .addCase(addEditGroupAsync.pending, (state: GroupState) => {
                state.addEditStatus = LoadStatus.Loading;
                // state.errors = {};
            })
            .addCase(addEditGroupAsync.fulfilled, (state: GroupState) => {
                state.addEditStatus = LoadStatus.Success;
                state.pageStatus = LoadStatus.Idle;
            })
            .addCase(getAllGroupsAsync.rejected, (state: GroupState) => {
                state.allGroupsStatus = LoadStatus.Failed;
                state.allGroups = [];
              })
            .addCase(getAllGroupsAsync.pending, (state: GroupState) => {
                state.allGroupsStatus = LoadStatus.Loading;
                state.allGroups =[];
            })
            .addCase(getAllGroupsAsync.fulfilled, (state: GroupState, action: { payload:  Array<IAllResponseData> }) => {
                state.allGroupsStatus = LoadStatus.Success;
                state.allGroups = action.payload;
            })
    },
});

export const { cleanUpGroups, cleanUpStatuses } = groupSlice.actions;

export const groupData = (state: RootState) => state.group.data;
export const groupStatus = (state: RootState) => state.group.status;
export const groupPageStatus = (state: RootState) => state.group.pageStatus;

export const deleteGroupStatus = (state: RootState) => state.group.deleteStatus;
export const addEditGroupStatus = (state: RootState) => state.group.addEditStatus;

export const allGroupsData = (state: RootState) => state.group.allGroups;
export const allGroupsStatus = (state: RootState) => state.group.allGroupsStatus;

export default groupSlice.reducer;
