import { IAddressItem } from "./IAddress";
import { IPartnerItem } from "./IAppPartner";
import { IPaymentItem } from "./IAppPaymentType";
import { IRuleItem } from "./IAppRules";
import { IGroupItem } from "./IGroups";
import { IMemberItem } from "./IMembers";
import { IReportItem, IRequestReport } from "./IReports";

export enum ITableSize {
  small = "small",
  medium = "medium",
}

export enum ISortDirection {
  asc = "asc",
  desc = "desc",
}

export enum Align {
  left = "left",
  right = "right",
  center = "center",
}

export enum Filter {
  date = "date",
  textCheckbox = "textCheckbox",
  textSearch = "textSearch",
  number = "number",
}

export interface IColumnsOptions {
  [key: string]: {
    min?: string;
    max?: string;
    searchValue?: string;
    from?: string;
    to?: string;
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace IAppTable {
  export interface ITableRef {
    reload: () => void;
  }
  export interface IFilter {
    searchLabel: string;
    hasSearch: boolean;
    loadFilterItems: () => void;
  }
  export type ILoadData = IOptions & IRequestReport
  export interface IHeader {
    columns: IColumns;
    sortBy?: string;
    direction?: ISortDirection;
    stringAsc?: string;
    stringDesc?: string; 
    low?: string;
    high?: string;
    defaultSortBy?: string;
  }
  export interface IPagination {
    rowsPerPageOptions?: Array<number>;
  }
  export interface IColumns {
    [key: string]: {
      align: Align;
      label: string;
      sortable: boolean;
      filter?: IColumnFilter;
    }
  }
  export interface IOptions {
    pagination: IPaginationOptions;
    sorting?: ISortingOptions;
  }

  export interface IPaginationOptions {
    page: number;
    perPage: number;
  }

  export type ISortingOptions = {
    sortBy?: string;
    direction?: ISortDirection;
    stringAsc?: string; 
    stringDesc?: string;
    low?: string;
    high?: string;
    default?: unknown;
    useGrouping?: boolean;
  } & IColumnsOptions

  export interface IColumnFilter {
    type: Filter;
    default?: unknown;
    data?: Array<{[key: string]: string}>;
    loading?: boolean;
    useGrouping?: boolean;
  }
  export interface IOptionFilter {
    [key:string]: string | number | {[key:string]: string | number | null}
  }
  
  export type Items = IAddressItem & IGroupItem & IMemberItem & IPartnerItem & IPaymentItem & IReportItem & IRuleItem;
  export type RowItems = IRuleItem | IAddressItem | IGroupItem | IMemberItem | IPartnerItem | IPaymentItem | IReportItem;

  export interface IComponent<T> {
    _item?: T;
    isLoading: boolean;
    key?: string;
    onAction?: (action: string, value: T, event?: React.BaseSyntheticEvent) => void;
  }
  
  export interface IComponentProps {
    className?: string;
  }
}