import OrderTitle from "../Report/OrderTitle";

export default function OrderTitleExp() {
    return(
        <OrderTitle
            orderId="154-567890"
            description="There are many variations of passages of Lorem Ipsum majority have suffered alteration in some form"
            orderType="Delivery"
            orderDate="18:06:2022  15:56"
        />
    );
}