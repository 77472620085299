import * as React from "react";
import { useTranslation } from "react-i18next";

import { Theme } from "@mui/system";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

import { rem } from "../../muiTheme/helpers";
import vars from "../../../styles/_variables.module.scss";
import { DialogType } from "../../entities/IAppComponents";

interface ITitleProps {
  variant?: DialogType;
  children?: React.ReactNode | string;
  isSmall?: boolean;
  onClose?: () => void;
  hideCloseButton?: boolean;
}

const AppDialogTitle = (props: ITitleProps) => {
  const { children, variant = DialogType.standart, isSmall, onClose, hideCloseButton = false, ...other } = props;
  const { t } = useTranslation();

  const isSelected = variant === DialogType.delete || variant === DialogType.confirm;
  const isDelete = variant === DialogType.delete;

  return (
    <DialogTitle
      {...other}
      sx={{ padding: isSmall ? `${rem(20)} !important` : '' }}
      >
        {!hideCloseButton && <div className="d-flex justify-content-end">
            {onClose ? (
                <IconButton
                    onClick={onClose}
                    sx={{
                        padding: rem(13.21),
                        width: rem(40),
                        height: rem(40),
                        backgroundColor: vars["colors-background_color"],
                        color: (theme: Theme) => theme.palette.secondary.light,
                    }}
                >
                    <CloseIcon sx={{fontSize: "1.9rem"}}/>
                </IconButton>
            ) : null}
        </div>}

      <Box className='word-break' sx={{ color: isSelected ? "error.main" : "inherit" }}>
        { isDelete && `${t("actions.delete")}` }
        { children }
      </Box>
    </DialogTitle>
  );
};

export default AppDialogTitle;
