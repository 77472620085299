import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
    Typography,
    MenuItem,
    Divider,
    Grid,
    IconButton,
} from "@mui/material";

// import vars from "../../../../styles/_variables.module.scss";
import { rem } from '../../../muiTheme/helpers';
import '../index.scss';

import { IAppTable, ISortDirection } from "../../../entities/IAppTable";
import NumberField from "../../NumberField";

interface IProps {
    sort: (event: React.SyntheticEvent, direction: ISortDirection) => void;
    doMinMaxFilter: (event: React.SyntheticEvent, min: string, max: string, column: string) => void;
    options: IAppTable.ISortingOptions;
    column: string;
    useGrouping?: boolean;
}

const AppNumberFilter = (props: IProps) => {
    const { t } = useTranslation();
    const { column, doMinMaxFilter, useGrouping = true } = props;

    // const sortKeyLow = column + 'low';
    // const sortKeyHigh = column + 'high';

    const [min, setMin] = useState<string>("");
    const [max, setMax] = useState<string>("");
    const [errors, setErrors] = useState<{[key:string]:string}>({});

    const isValid = () => {
        const newErrors: {[key:string]: string} = {};
        if (min.trim() === "" && max.trim() !== "") newErrors.min = t("errorMessages.common");
        if (min.trim() !== "" && max.trim() === "") newErrors.max = t("errorMessages.common");
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const handleClick = (event: React.SyntheticEvent) => {
        if(isValid()) {
            doMinMaxFilter(event,min,max,column);
        }
    }

    return (
        <>
            {/* <MenuItem  
                className="menuItem" 
                onClick={(event: React.SyntheticEvent) => sort(event, ISortDirection.asc)}
                selected={options.low === sortKeyLow}
            >
                <Typography 
                    variant="body2" 
                    color={vars['colors-secondary_1']}
                >
                 {t('reports.low')}
                </Typography>
                {options.low === sortKeyLow && <img src="/img/selected.svg" alt="selected" style={{marginLeft: rem(21)}}/>}
            </MenuItem>
            <MenuItem  
                className="menuItem" 
                onClick={(event: React.SyntheticEvent) => sort(event, ISortDirection.desc)}
                selected={options.high === sortKeyHigh}
            >
                <Typography 
                    variant="body2" 
                    color={vars['colors-secondary_1']} 
                >
                {t('reports.high')}
                </Typography>
                {options.high === sortKeyHigh && <img src="/img/selected.svg" alt="selected" style={{marginLeft: rem(21)}} />}
            </MenuItem>
            <Divider className="menuDivider"/> */}
            <Typography 
                variant="subtitle2"
                sx={{
                    fontWeight: '700',
                    lineHeight: rem(20),
                    marginLeft: rem(10),
                    marginTop: rem(15),
                    textTransform: 'uppercase'
                }}
            >
                {t('reports.filtering')}
            </Typography>
            <MenuItem className="menuItem hideHover">    
                <Grid container alignItems="center" direction="row" sx={{ marginBottom: rem(-13) }}>
                    <Grid item>
                        <NumberField
                            useGrouping={useGrouping}
                            sx={{
                                width: rem(65)
                            }}
                            value={min}
                            placeholder={ t('placeholder.min') || '' }
                            onChange={(_val: string) => {
                                setMin(_val);
                            }}
                            noIcon={true}
                            className="menuTextfield"
                            error={Boolean(errors?.min)}
                        />
                    </Grid>
                    <Grid item>
                        <Divider className="dividerMinMax" />
                    </Grid>
                    <Grid item>
                        <NumberField
                            useGrouping={useGrouping}
                            sx={{
                                width: rem(65)
                            }}
                            value={max}
                            placeholder={ t('placeholder.min') || '' }
                            onChange={(_val: string) => {
                                setMax(_val);
                            }}
                            noIcon={true}
                            className="menuTextfield"
                            error={Boolean(errors?.max)}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton onClick={(event: React.SyntheticEvent) => handleClick(event)} sx={{ marginTop: rem(5), padding: rem(5), marginRight: rem(-5) }}>
                            <img alt="filter" src="/img/doFilter.svg" />
                        </IconButton>
                    </Grid>
                </Grid>
            </MenuItem>
        </>
    )
}

export default AppNumberFilter;