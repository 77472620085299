import { Typography, Box } from "@mui/material";

import { rem } from '../../app/muiTheme/helpers';

interface IProps {
    src: string;
    shopName: string;
}

export default function ShopInfo(props: IProps) {
    const { src, shopName } = props;

    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <img
            src={src}
            alt="shopImg"
            style={{
                width: rem(69),
                height: rem(42),
                borderRadius: rem(5),
                marginRight: rem(9)
            }}/>
            <Typography variant="h6">{shopName}</Typography>
        </Box>
    )

}