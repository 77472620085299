export enum ImageVariant  {
   small = 'small',
   medium = 'medium',
}

export enum DialogType  {
   standart = 'standart',
   delete = 'delete',
   confirm = 'confirm',
   intermediate = 'intermediate',
}