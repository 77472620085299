import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { Grid, Typography, Button, CircularProgress } from "@mui/material";

import { rem } from "../muiTheme/helpers";
import vars from "../../styles/_variables.module.scss";

interface IProps {
    title: string;
    subtitle?: ReactElement | Array<ReactElement>;
    actionBtn: string;
    btnVariant?: string;
    isDynamic?: boolean;
    mainBtnLoading?: boolean;
    disabled?: boolean;
    otherPageActions?: ReactElement | Array<ReactElement>;
    handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function PageHeader(props: IProps) {
    const { title, actionBtn, handleClick, btnVariant = 'contained', subtitle, isDynamic = false, otherPageActions, mainBtnLoading = false, disabled = false } = props;

    const { t } = useTranslation();

    return (
        <>
            <Grid 
            container 
            sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: rem(17)
            }}>
                <Grid item>
                    <Typography variant="h4" sx={{ textTransform: 'capitalize' }} className="pageHeader">{ isDynamic ? title : t(title) }</Typography>
                </Grid>
                <Grid item>
                    <Grid 
                        container
                        sx={{
                            columnGap: rem(15),
                        }}
                    >
                        { otherPageActions }
                        <Button 
                            startIcon={mainBtnLoading && <CircularProgress sx={{color: vars['colors-light_color']}} size={20} />}
                            disabled={mainBtnLoading || disabled}
                            variant={btnVariant as MuiButtonProps["variant"]} 
                            onClick={handleClick}
                        >
                            { t(actionBtn) }
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {subtitle && <>{subtitle}</>}
        </>
    );
}