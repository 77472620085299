import { MouseEventHandler, useEffect, useRef } from 'react';
import { useNavigate, NavigateFunction } from "react-router";
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { cleanUpStatus, loginAsync, loginErrors, loginStatus } from './authSlice';
import { LoadStatus } from '../../app/entities/IAppLoadStatus';

import { Button, Checkbox, CircularProgress, FormControlLabel, TextField, Typography, Box } from "@mui/material";

import { rem } from "../../app/muiTheme/helpers";
import vars from "../../styles/_variables.module.scss";
import { IAuthProps } from '../../app/entities/IAppAuth';

const validationSchema = yup.object({
    username: yup
      .string()
      .trim('errorMessages.usernameRequired')
      .required('errorMessages.usernameRequired'),
    password: yup
      .string()
      .trim('errorMessages.passowordRequried')
      .required('errorMessages.passowordRequried'),
});

export default function Login() {
    const navigate: NavigateFunction = useNavigate();
    const dispatch = useAppDispatch();
    const passwordField = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
          username: '',
          password: '',
          rememberMe: false,
        },
        validateOnChange: false,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const requestData: IAuthProps = {
                username: values.username.trim(),
                password: values.password,
                rememberMe: values.rememberMe
            }
            dispatch(loginAsync(requestData));
        },
    });

    const status = useAppSelector(loginStatus);
    const errors = useAppSelector(loginErrors);
    const loading = status === LoadStatus.Loading;
    const isSuccess = status === LoadStatus.Success;
    const isFailed = status === LoadStatus.Failed;

    const hasEmailFormikErrors = formik.touched.username && Boolean(formik.errors.username);
    const hasEmailErrors = errors && Boolean(errors.username);
    const hasPasswordFormikErrors = formik.touched.password && Boolean(formik.errors.password);
    const hasPasswordErrors = errors && Boolean(errors.password);


    useEffect(() => {
        return () => {
          dispatch(cleanUpStatus());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSuccess) {
            navigate('/rules');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const handleKeyUp = (field: string) => (event: React.KeyboardEvent) => {
        if (event.keyCode === 13) {
            switch (field) {
              case "username":
                passwordField?.current?.focus();
                break;

              case "password":
                formik.handleSubmit();
                break;

              default:
                break;
            }
        }
    }

    return (
        <Box component="form" noValidate sx={{ maxWidth: rem(427) }}>
            <Typography
                variant="body2"
                sx={{
                    color: vars['colors-secondary'],
                    marginTop: rem(40)
                }}>{ t('auth.username') }</Typography>
            <TextField
                margin="normal"
                fullWidth
                placeholder={t('placeholder.enterUsername') || ''}
                name="username"
                autoComplete="username"
                autoFocus
                value={formik.values.username}
                onChange={formik.handleChange}
                onKeyUp={handleKeyUp("username")}
                onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                error={hasEmailFormikErrors || hasEmailErrors || isFailed}
                helperText={(hasEmailFormikErrors && t(`${formik.errors.username}`)) ||
                            (hasEmailErrors && t(`errorMessages.${errors.username}`))}
                inputProps={{
                    style: {
                        height: rem(14)
                    }
                }}
            />
            <Typography
                variant="body2"
                sx={{
                    color: vars['colors-secondary'],
                    marginTop: rem(12)
                }}>{ t('auth.password') }</Typography>
            <TextField
                margin="normal"
                fullWidth
                name="password"
                placeholder={t('placeholder.enterPassword') || ''}
                type="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onKeyUp={handleKeyUp("password")}
                onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                error={hasPasswordFormikErrors || hasPasswordErrors || isFailed}
                helperText={(hasPasswordFormikErrors && t(`${formik.errors.password}`)) ||
                            (hasPasswordErrors && t(`errorMessages.${errors.password}`))}
                inputRef={passwordField}
                inputProps={{
                    style: {
                        height: rem(14)
                    }
                }}
            />
            <FormControlLabel
                control={<Checkbox
                    value="rememberMe"
                    name="rememberMe"
                    checked={formik.values.rememberMe}
                    onChange={formik.handleChange}
                />}
                label={<Typography variant="body2" sx={{color: vars['colors-secondary']}}>{ t('auth.remember') }</Typography>}
            />
            <Button
                type="submit"
                fullWidth
                disabled={loading}
                startIcon={loading && <CircularProgress sx={{color: vars['colors-light_color']}} size={20}/>}
                onClick={formik.handleSubmit as unknown as MouseEventHandler<HTMLButtonElement> | undefined}
                variant="contained"
                sx={{ marginTop: rem(26) }}
            >
                { t('auth.login') }
            </Button>
        </Box>
    );
}
