import { useTranslation } from "react-i18next";

import DialogWrapper from "../../app/components/Dialog";
import { DialogType } from "../../app/entities/IAppComponents";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  openCannotDeleteDialog,
  dataCannotDeleteDialog,
  cleanUpCannotDeleteDialog,
} from "./cannotDeleteSlice";

export default function CannotDeleteDialog() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const open = useAppSelector(openCannotDeleteDialog);
  const data = useAppSelector(dataCannotDeleteDialog);

  const onConfirm  = () => {
    dispatch(cleanUpCannotDeleteDialog());
  };

  if (!open) return null;

  return (
    <DialogWrapper
      open={open}
      isSmall
      variant={DialogType.confirm}
      title={t('cannotDelete.title', { name: `${t(`${data}.title`)}` })}
      content={t('cannotDelete.subTitle', { name: `${t(`${data}.subTitle`)}` }) || ''}
      onConfirm={onConfirm}
      onCloseDialog={onConfirm}
    />
  );
}
